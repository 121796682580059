<template>
    <div class="dialog">
        <!--  整改处置-->
        <el-dialog title="" :visible.sync="dialogVisible" width="1600px" top="5vh" @close="closeYjcz"
            :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogVisible = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    {{ ["预警处置", "自主整改", "风险处置", "联动处置"][tanType] }}
                </div>
                <div class="biao">
                    <div class="yjcz" v-if="tanType == 0">
                        <!--                        <div class="flex">
                            <div
                                :class="{ zgbtn1: yjczBtn == 1, ztbtn2: yjczBtn != 1 }"
                                class="hand"
                                @click="changeYjcz(1)"
                            >
                                自主整改({{ zzzgData.total }})
                            </div>
                            <div
                                :class="{ zgbtn1: yjczBtn == 2, ztbtn2: yjczBtn != 2 }"
                                class="hand"
                                @click="changeYjcz(2)"
                            >
                                部门处置({{ bmczCount1 + bmczCount2 }})
                            </div>
                            <div
                                :class="{ zgbtn1: yjczBtn == 3, ztbtn2: yjczBtn != 3 }"
                                class="hand"
                                @click="changeYjcz(3)"
                            >
                                联动处置(2)
                            </div>
                        </div>-->
                    </div>
                    <div id="zzzg" v-if="tanType == 1 || (yjczBtn == 1 && tanType == 0)">
                        <div class="flex" v-if="tanType == 1">
                            <div :class="{ zgbtn1: zgzt == 1, ztbtn2: zgzt == 2 }" class="hand" @click="search(1)">
                                已整改({{ yzgData.total }})
                            </div>
                            <div :class="{ zgbtn1: zgzt == 2, ztbtn2: zgzt == 1 }" class="hand" @click="search(2)">
                                未整改({{ wzgData.total }})
                            </div>
                        </div>
                        <table class="listtable">
                            <tr class="listTitle">
                                <td style="width: 220px">药店名称</td>
                                <td>标题</td>
                                <td>整改内容</td>
                                <td style="width: 130px">通知时间</td>
                                <td>处理人</td>
                                <td>提交内容</td>
                                <td>图片</td>
                                <td style="width: 130px">处理时间</td>
                                <td>状态</td>
                                <td style="width: 100px">审核人</td>
                                <td style="width: 130px">审核时间</td>
                            </tr>
                            <tr class="listData dabiaozi" v-for="x in zzzgData.list">
                                <td ref="td1" :class="{ tdView: x.isView }">
                                    <div class="boxcontent" v-if="x.isView">33</div>
                                    <div>{{ x.storeName }}</div>
                                </td>
                                <td style="height: 120px">{{ x.title }}</td>
                                <td>
                                    <div style="width: 150px" class="overflow" :title="x.content">
                                        {{ x.content }}
                                    </div>
                                </td>
                                <td>
                                    <div class="time">{{ x.createDate }}</div>
                                </td>
                                <td>{{ x.handleName ? x.handleName.split(":")[1] : "" }}</td>
                                <td>
                                    <div style="margin: 0 auto;width: 100px" class="overflow" :title="x.repayContent">
                                        {{ x.repayContent }}
                                    </div>
                                </td>
                                <td>
                                    <el-image style="width: 50px; height:50px" :src="`${x.repayPic ? x.repayPic[0] : ''
                                        }?x-oss-process=image/resize,m_fill,h_100,w_100`
                                        " :preview-src-list="x.repayPic">
                                        <div slot="placeholder" style="line-height: 50px">
                                            加载中
                                        </div>
                                    </el-image>
                                </td>
                                <td>
                                    <div class="time">{{ x.handleTime }}</div>
                                </td>
                                <td>
                                    <span v-if="x.status === 10" type="info">待整改</span>
                                    <span v-if="x.status === 20" type="warning">整改中</span>
                                    <span v-if="x.status === 30" type="primary">已整改</span>
                                    <span v-if="x.status === 40" type="success">审核完成</span>
                                </td>
                                <td>{{ x.completeName }}</td>
                                <td>
                                    <div class="time">{{ x.completeTime }}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div id="bmjc" v-if="tanType == 2 || yjczBtn == 2">
                        <div class="flex" v-if="tanType == 2">
                            {{ liandong }}
                            <div :class="{ zgbtn1: bmczZt == 1 && liandong == 0, ztbtn2: bmczZt == 0 || liandong == 1 }" class="hand"
                                @click="search2(1)">已处置({{ bmczCount }})</div>
                            <div :class="{ zgbtn1: bmczZt == 0 && liandong == 0, ztbtn2: bmczZt == 1 || liandong == 1 }" class="hand"
                                @click="search2(0)">未处置(0)</div>
                            <div :class="{ zgbtn1: liandong == 1, ztbtn2: liandong == 0 }" class="hand" @click="ldcz">联动处置(1)
                            </div>
                        </div>
                        <!-- <div class="dialogBlock" v-if="tanType == 2" style="margin-bottom: 10px">
                            <div class="item hand" @click="search2(1)">
                                <div class="item1">已处置</div>
                                <div class="item2" style="line-height: 80px">
                                    {{ bmczCount }}
                                    &lt;!&ndash;                  {{bmczCount1+bmczCount2}}&ndash;&gt;
                                </div>
                            </div>
                            <div class="item hand" @click="search2(0)">
                                <div class="item1">未处置</div>
                                <div class="item2" style="line-height: 80px">0</div>
                                &lt;!&ndash;            <div class="item3" style="line-height: 80px">15%</div>&ndash;&gt;
                            </div>
                            <div class="item" style="opacity: 0"></div>
                        </div> -->
                        <div v-if="liandong == 1">
                            <table class="listtable">
                                <tr class="listTitle">
                                    <td style="">序号</td>
                                    <td style="width: 107px">预警时间</td>
                                    <td>药店名称</td>
                                    <td>类型</td>
                                    <td>内容</td>
                                    <td>初审意见</td>
                                    <td>初审时间</td>
                                    <td>检查部门</td>
                                    <td>检查人员</td>
                                    <td>检查时间</td>
                                    <td>当前状态</td>
                                    <td>操作</td>
                                </tr>
                                <tr class="listData" v-for="(x, i) in JubaoData2" v-if="i == 0">
                                    <td style="height: 120px">{{ i + 1 }}
                                    </td>
                                    <td>2022/05/14
                                    </td>
                                    <td>{{ x.a2 }}
                                    </td>
                                    <td>{{ x.a3 }}</td>
                                    <td>阿奇霉素混悬液进销存不一致
                                    </td>
                                    <td>本条预警可能涉及药品质量、非法渠道购进药品、违规销售检测药品，建议市场监管局和医保局现场检查。
                                    </td>
                                    <td>{{ x.a6 }}
                                    </td>
                                    <td>{{ x.a7 }}
                                    </td>
                                    <td>{{ x.a8 }} </td>
                                    <td>{{ x.a9 }} </td>
                                    <td>{{ x.a10 }}</td>
                                    <td><span @click="ldczDialog = true" class="btn nowrap"
                                            style="color: #ffc038;border-color:#ffc038">查看</span> </td>
                                </tr>

                            </table>
                        </div>
                        <div v-if="liandong == 0">
                            <table class="listtable" id="bmcz">
                                <tr class="listTitle">
                                    <td>预警编号</td>
                                    <td>预警药店名称</td>
                                    <td class="tx-c" style="width: 130px">药店负责人</td>
                                    <td>电话</td>
                                    <td>执法类型</td>
                                    <td class="tx-c" style="width: 130px">处置部门</td>
                                    <td>备注说明</td>
                                    <td style="width: 103px">创建时间</td>
                                    <td>状态</td>
                                    <td>操作</td>
                                </tr>
                                <tr class="listData dabiaozi" v-for="x in bmczData.list">
                                    <td style="height: 120px" ref="td1" :class="{ tdView: x.isView }">
                                        <!--        <div class="boxcontent" v-if="x.isView">33</div>-->
                                        <div>{{ x.code }}</div>
                                    </td>
                                    <td>{{ x.storeName }}</td>
                                    <td class="tx-c">{{ x.contract }}</td>
                                    <td>{{ mbl(x.phone) }}</td>
                                    <td>{{ zflx(x.type) }}</td>
                                    <td class="tx-c">{{ x.regulatoryName }}</td>
                                    <td>{{ x.remark }}</td>
                                    <td>
                                        <div class="time">{{ x.createDate }}</div>
                                    </td>
                                    <td>
                                        <div class="tag">
                                            <el-tag type="success">{{ status(30) }}</el-tag>
                                        </div>
                                    </td>
                                    <td><span class="btn nowrap" @click="openLct(x)">查看</span></td>
                                </tr>
                            </table>
                            <div style="color: white;line-height: 200px;text-align: center" v-if="bmczZt == 0">
                                暂无数据
                            </div>
                        </div>

                    </div>

                    <table class="listtable" id="ldcz" v-if="tanType == 3 || (yjczBtn == 3 && tanType == 0)">
                        <tr class="listTitle">
                            <td style="width:258px">药店名称</td>
                            <td>姓名</td>
                            <td>身份证</td>
                            <td>手机号码</td>
                            <td style="width: 70px" class="tx-c">体温</td>
                            <td class="tx-c">健康码</td>
                            <td>行程记录</td>
                            <td class="tx-c">销售记录</td>
                            <td>上报指挥部</td>
                            <td style="width:130px" class="tx-c">时间</td>
                            <td style="width:130px" class="tx-c">处置反馈</td>
                            <td class="tx-c">操作</td>
                        </tr>
                        <tr v-for="x in ldczData" class="dabiaozi"
                            :class="{ listData: !x.isView, listData2: x.isView }">
                            <td>
                                <div style="position: relative" :class="{ chakan: x.isView }">
                                    <div class="tdContent" style="text-align: left">{{ x.a1 }}</div>
                                    <div class="viewContent" v-if="x.isView">
                                        <table width="100%" cellspacing="0" cellpadding="0">
                                            <tr class="listTitle2" style="height: 35px">
                                                <td>反馈时间</td>
                                                <td>反馈单位</td>
                                                <td>联系人</td>
                                                <td>电话</td>
                                                <td>内容</td>
                                            </tr>
                                            <tr class="biao2" v-if="x.a2 == '楼润高'">
                                                <td>2022-4-19 20:15:47</td>
                                                <td>县指挥部</td>
                                                <td>吴望</td>
                                                <td>{{ mbl("18266921576") }}</td>
                                                <td>已指令浦阳街道片管员跟踪处理</td>
                                            </tr>
                                            <tr class="biao2" v-if="x.a2 == '朱华兴'">
                                                <td>2022-4-23 19:10:45</td>
                                                <td>县指挥部</td>
                                                <td>吴望</td>
                                                <td>{{ mbl("18266921576") }}</td>
                                                <td>已指令浦阳街道片管员跟踪处理</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent nowrap">
                                    {{ x.a2 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent">
                                    {{ x.a3 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent nowrap">
                                    {{ x.a4 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent shuzi">
                                    {{ x.a5 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a6 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a7 }}
                                </div>
                            </td>
                            <td class="tx-c">
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a8 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a9 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a10 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ chakan: x.isView }" class="tdContent tx-c">
                                    {{ x.a11 }}
                                </div>
                            </td>
                            <td>
                                <div :class="{ tdBtn: x.isView }" class="tx-c">
                                    <div @click="shou(x)" class="btnDetail" v-if="!x.isView">
                                        查看详情
                                    </div>
                                    <div @click="shou(x)" class="btnDetail2" v-if="x.isView">
                                        收起详情
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div v-if="liandong == 0">
                        <!--        自主整改翻页-->
                        <div class="pager flex" v-if="tanType == 1 || (yjczBtn == 1 && tanType == 0)">
                            <!--          <div class="page" @click="changePage('prev')"><</div>-->
                            <!--          <div style="margin-left: 10px;margin-right: 10px">{{post.page}}/{{pageCount}}</div>-->
                            <!--          <div class="page" @click="changePage('next')">  > </div>-->
                            <pager :page="post.page" :total="zzzgData.total" :page-size="pageCount"
                                @pagination="changePage" />
                        </div>
                        <!--        部门处置翻页-->
                        <div class="pager flex" v-if="tanType == 2 || (yjczBtn == 2 && tanType == 0)">
                            <pager :page="post.page" :total="bmczData.total" :page-size="5" @pagination="changePage2" />
                            <!--          <div class="page" @click="changePage2('prev')"><</div>-->
                            <!--          <div style="margin-left: 10px;margin-right: 10px">{{post2.page}}/{{pageCount2}}</div>-->
                            <!--          <div class="page" @click="changePage2('next')">  > </div>-->
                        </div>
                    </div>

                </div>
            </div>
        </el-dialog>
        <!--中埂社区智慧药房-->
        <el-dialog :visible.sync="dialogZhonggeng" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">

                <div class="tanTitle">
                    <div v-if="isWangxiufang" class="home" @click="open('/')">
                        <img src="../assets/yun/home/home.png" alt="" />
                        <span>返回驾驶舱</span>
                    </div>
                    <div @click="dialogZhonggeng = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    {{ zgTitle }}
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>医院名称</td>
                            <td class="tx-c">医生姓名</td>
                            <td class="tx-c">患者姓名</td>
                            <td class="tx-c">性别</td>
                            <td class="tx-c">年龄</td>
                            <td>联系电话</td>
                            <td class="tx-c">审核药师</td>
                            <td style="width: 105px">销售日期</td>
                            <td v-if="!isWangxiufang">商品信息</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in prescriptionData.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 114px"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.hospital
                                    }}</span></td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.doctor }}</span></td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.patientName }}</span>
                            </td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.patientSex == 1 ? "男" :
                                    "女" }}</span></td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.patientAge }}</span>
                            </td>
                            <td><span class="wzname" :class="{ wxf: isWang(x) }">{{ mbl(x.patientPhone) }}</span></td>
                            <td class="tx-c">{{ x.pharmacist }}</td>
                            <td>{{ x.createDate }}</td>
                            <td v-if="!isWangxiufang"><span class="btn" @click="openGoods(x)">查看</span></td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postPrescription.page" :total="zgNum" :page-size="postPrescription.count"
                            @pagination="getPrescription" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--处方列表-->
        <el-dialog :visible.sync="dialogPrescription" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">

                <div class="tanTitle">
                    <div v-if="isWangxiufang" class="home" @click="open('/')">
                        <img src="../assets/yun/home/home.png" alt="" />
                        <span>返回驾驶舱</span>
                    </div>
                    <div @click="dialogPrescription = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    {{ prescriptionTitle }}
                </div>
                <div class="biao">
                    <div class="flex saleSearch" v-if="prescriptionSearchShow">
                        <div class="label">药品名称</div>
                        <div class="item">
                            <el-input v-model="postPrescription.goodsName" clearable></el-input>
                        </div>
                        <div class="label">药店名称</div>
                        <div class="item">
                            <!-- <el-select filterable style="width: 220px" v-model="postPrescription.storeIds"
                                placeholder="请选择">
                                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select> -->
                            <xs-select v-model="postPrescription.storeIds" style="width: 220px" />
                        </div>
                        <div class="label">日期</div>
                        <div class="item">
                            <el-date-picker v-model="prescriptionTime" type="date" value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </div>
                        <div class="item flex" style="align-items: center">
                            <div @click="searchPrescription" class="btnSearch">
                                <img src="../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>医院名称</td>
                            <td class="tx-c">医生姓名</td>
                            <td class="tx-c">患者姓名</td>
                            <td class="tx-c">性别</td>
                            <td class="tx-c">年龄</td>
                            <td>联系电话</td>
                            <td class="tx-c">审核药师</td>
                            <td style="width: 105px">销售日期</td>
                            <td class="tx-c">处方图片</td>
                            <td class="tx-c" v-if="prescriptionTitle != '处方流转'">审方图片</td>
                            <td v-if="!isWangxiufang">商品信息</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in prescriptionData.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 114px"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.hospital
                                    }}</span></td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.doctor }}</span></td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.patientName }}</span>
                            </td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.patientSex == 1 ? "男" :
                                    "女" }}</span></td>
                            <td class="tx-c"><span class="wzname" :class="{ wxf: isWang(x) }">{{ x.patientAge }}</span>
                            </td>
                            <td><span class="wzname" :class="{ wxf: isWang(x) }">{{ mbl(x.patientPhone) }}</span></td>
                            <td class="tx-c">{{ x.pharmacist }}</td>
                            <td>{{ x.createDate }}</td>
                            <td class="tx-c">
                                <el-image style="width: 50px; height:50px" :src="`${$getImageUrl(x.prescriptionImage)}?x-oss-process=image/resize,m_fill,h_100,w_100`
                                    " :preview-src-list="[$getImageUrl(x.prescriptionImage)]">
                                    <div slot="placeholder" style="line-height: 50px">
                                        加载中
                                    </div>
                                </el-image>
                            </td>
                            <td class="tx-c" v-if="prescriptionTitle != '处方流转'">
                                <el-image v-if="x.patientName != '汪秀芳'" style="width: 50px; height:50px" :src="`${$getImageUrl(x.scenePhoto)
                                    }?x-oss-process=image/resize,l_1000/watermark,m_fill,h_100,w_100,text_${recode(
                                        x.pharmacist
                                    )},color_fc011a,size_50,g_south,t_30,y_100/watermark,text_${recodeBase(
                                        x.storeName.replace('有限公司', '')
                                    )},color_fc011a,size_50,g_south,y_300,t_30`
                                    " :preview-src-list="[
                                        `${$getImageUrl(x.scenePhoto)
                                        }?x-oss-process=image/resize,l_1000/watermark,m_fill,text_${recode(
                                            x.pharmacist
                                        )},color_fc011a,size_50,g_south,t_30,y_80/watermark,text_${recodeBase(
                                            x.storeName
                                                .replace('连锁有限公司', '')
                                                .replace('有限公司', '').replace('浙江', '').replace('金华市', '').replace('金华', '').replace('东阳市', '')
                                        )},color_fc011a,size_50,g_south,y_150,t_30`,
                                    ]">
                                    <div slot="placeholder" style="line-height: 50px">
                                        加载中
                                    </div>
                                </el-image>
                                <el-image v-else style="width: 50px; height:50px" :src="x.scenePhoto"
                                    :preview-src-list="[x.scenePhoto]">
                                    <div slot="placeholder" style="line-height: 50px">
                                        加载中
                                    </div>
                                </el-image>
                            </td>
                            <td v-if="!isWangxiufang"><span class="btn" @click="openGoods(x)">查看</span></td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postPrescription.page" :total="prescriptionType == 2 ? 170 : prescriptionData.total"
                            :page-size="postPrescription.count" @pagination="getPrescription" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--处方结果分析-->
        <el-dialog :visible.sync="dialogCzjg" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogCzjg = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>{{ czjg }}</span>
                </div>
                <div class="biao">
                    <table class="listtable dazi" v-if="czjg == '取消医保' || czjg == '取消好商风贷'">
                        <tr class="listTitle">
                            <td>
                                <div class="singleField">药店名称</div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江黄宅紫金家园店
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江东山路店
                                </div>
                            </td>
                        </tr>
                    </table>

                    <table class="listtable dazi" v-if="czjg == '暂停医保' || czjg == '暂停云药房'">
                        <tr class="listTitle">
                            <td>
                                <div class="singleField">药店名称</div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江黄宅钟村分店
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江七里药房
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">浦江五和大药房有限公司</div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">
                                    金华市老百姓医药连锁有限公司浦江郑家坞店
                                </div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">浦江丰安药品销售有限公司</div>
                            </td>
                        </tr>
                        <tr class="listData">
                            <td>
                                <div class="singleField">金华市太和堂医药浦江岩头连锁店</div>
                            </td>
                        </tr>
                    </table>

                    <table class="listtable dazi" v-if="czjg == '信用降级'">
                        <tr class="listTitle">
                            <td>
                                <div class="singleField">药店名称</div>
                            </td>
                        </tr>
                        <tr class="listData" v-for="x in xyjjData">
                            <td style="height: 130px;">
                                <div class="singleField">{{ x.name }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager" v-if="czjg == '信用降级'">
                        <pager :page="postXyjj.page" :total="58" :page-size="5" @pagination="getXyjj" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--动态评级-->
        <el-dialog :visible.sync="dialogDtpj" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogDtpj = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span v-if="dtpj == 'D级'">暂停/取消医保</span>
                    <span v-if="dtpj == 'C级'">暂停云药房</span>
                </div>
                <div class="biao">
                    <!--                    <div class="huizong">-->
                    <!--                        <div class="btnHui flex_vc">汇总数据：{{ dtpjData.total }}</div>-->
                    <!--                        <div class="btnHui flex_vc">占比：{{ perHui }}%</div>-->
                    <!--                    </div>-->
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 50%">药店名称</td>
                            <td class="tx-c">上年度信用等级</td>
                            <td class="tx-c">当前信用得分</td>
                            <td class="tx-c">当前监管风险等级</td>
                        </tr>
                        <tr class="listData" v-for="x in dtpjData.list">
                            <td style="height: 116px">
                                <div v-if="dtpj == 'D级'" @click="selectStore(x.id, x.storeName)">{{ x.storeName }}</div>
                                <div v-if="dtpj == 'C级'">{{ x.storeName }}</div>
                            </td>
                            <td>
                                <div class="tx-c score">{{ x.lastYearScore }}</div>
                            </td>
                            <td>
                                <div class="tx-c score" @click="openKoufen(x.storeName)">{{ x.score }}</div>
                            </td>
                            <td>
                                <div class="tx-c levelD" style="color: #F90606" v-if="x.level == 'D'">{{ x.level }}</div>
                                <div class="tx-c levelC" style="color: #FEE610" v-if="x.level == 'C'">{{ x.level }}</div>
                            </td>
                        </tr>
                    </table>

                    <div style="color: white;line-height: 300px;text-align: center" v-if="dtpjData.total == 0">
                        暂无数据
                    </div>
                    <div class="pager" v-if="dtpjData.total > 0">
                        <pager :page="postDtpj.page" :total="dtpjData.total" :page-size="postDtpj.count"
                            @pagination="getDtpj" />
                    </div>
                </div>
                <div class="khpj2" v-if="dtpj == 'D级'"><img src="../assets/yun/dialog/qxyb1.png" />重点监管<img
                        src="../assets/yun/dialog/qxyb2.png" />暂停医保<img src="../assets/yun/dialog/qxyb3.png" />下线云药房<img
                        src="../assets/yun/dialog/sfd1-2.png" />暂停好商风贷</div>
            </div>
        </el-dialog>
        <!--药事服务响应率-->
        <el-dialog :visible.sync="dialogYsfwxyl" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogYsfwxyl = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>药事服务响应率</span>
                </div>
                <div class="biao">
                    <div class="huizong">
                        <div class="btnHui flex_vc" style="width:200px">签约医生数量：58</div>
                        <div class="btnHui flex_vc" style="width:200px">平局响应率：93%</div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 50px">序号</td>
                            <td>签约医生姓名</td>
                            <td>响应率</td>
                            <td>当月响应天数</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in ysfwxylData2">
                            <td>{{ i + 1 }}</td>
                            <td>{{ x.name }}</td>
                            <td>{{ x.per }}%</td>
                            <td>{{ x.num }}</td>
                        </tr>
                    </table>

                    <div style="color: white;line-height: 300px;text-align: center" v-if="ysfwxylData2.length == 0">
                        暂无数据
                    </div>
                    <div class="pager" v-if="ysfwxylData2.length > 0">
                        <pager :page="postYsfwxyl.page" :total="58" :page-size="10" @pagination="getYsfwxyl" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--销售管控-->
        <el-dialog :visible.sync="dialogControl" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogControl = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    一键锁定
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>名称</td>
                            <td>控制力度</td>
                            <td>时间范围</td>
                            <td>添加时间</td>
                            <td>区域</td>
                            <td>操作</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in controlData.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 120px">{{ x.name }}</td>
                            <td>
                                <div class="tag">
                                    <span v-if="x.way === 1" class="tagBtn" style="background-color: rgb(254 64 24) "
                                        @click="openControlSay('禁售')">禁售</span>
                                    <span v-if="x.way === 2" class="tagBtn" style="background-color:  rgb(244 127 3) "
                                        @click="openControlSay('限售')">限售</span>
                                    <span v-if="x.way === 3" class="tagBtn" style="background-color:rgb(2 119 250)"
                                        @click="openControlSay('提示')">提示</span>
                                </div>
                            </td>
                            <td>
                                {{ formatTime(x.startTime, "{y}-{m}-{d}") }} 至
                                {{ formatTime(x.endTime, "{y}-{m}-{d}") }}
                            </td>
                            <td>
                                <div class="time">{{ x.createDate }}</div>
                            </td>
                            <td>{{ x.regionName }}</td>
                            <td><span class="btn" @click="openControl2(x)">查看</span></td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postControl.page" :total="controlData.total" :page-size="postControl.count"
                            @pagination="getControl" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogControlSay" width="1150px" top="15vh" @close="closed" :destroy-on-close="true">
            <div class="tanWinTishi">
                <div class="tanTitle">
                    <div @click="dialogControlSay = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>{{ ControlSayTitle }}</span>
                </div>
                <div class="biao ControlSayContent" style="color: #00D3F9">
                    <div v-if="ControlSayTitle == '提示'" style="text-align: center;margin-top: 50px">
                        <div>销售时系统自动语音提醒</div>
                        <div><img style="mix-blend-mode: lighten;" @click="play" src="../assets/yun/dialog/start.jpg" />
                        </div>
                        <audio controls id="audio" style="display: none">
                            <source :src="require('@/assets/yun/dialog/xingfenji.mp3')" type="audio/mpeg">
                        </audio>
                    </div>
                    <div v-if="ControlSayTitle == '禁售'" style="text-align: center">

                        <div>
                            <el-image style="width: 319px;" :src="require('@/assets/yun/dialog/js.jpg')"
                                :preview-src-list="[require('@/assets/yun/dialog/js.jpg')]">
                            </el-image>
                        </div>
                        <div>锁定药店ERP系统对指定药品实行一键禁售</div>
                    </div>
                    <div v-if="ControlSayTitle == '限售'" style="text-align: center">
                        <div>
                            <el-image style="width: 319px;" :src="require('@/assets/yun/dialog/js.jpg')"
                                :preview-src-list="[require('@/assets/yun/dialog/xs.jpg')]">
                            </el-image>
                        </div>
                        <div @click="gayjxDialog = true">麻黄碱类实名登记，每人每天限购两盒</div>
                    </div>
                </div>


            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogControl2" width="1382px" top="112px" :destroy-on-close="true">
            <div class="tanWin2">
                <div class="tanTitle2">
                    <div @click="dialogControl2 = false" class="btnBack2 hand">
                        <img :src="closeImg" />
                    </div>
                    <span>销控查看</span>
                </div>
                <div class="tanContent" style="width: 90%" v-if="contorl.length > 0">
                    <div class="flex tymBox scrbar">
                        <div class="tym" v-for="x in contorl">
                            {{ x }}
                        </div>
                    </div>
                </div>
                <div v-if="contorl2.length > 0" class="scrbar" style="padding-top: 26px;height: 210px;overflow: auto">
                    <table id="contorl2" width="100%" cellspacing="0" cellpadding="0">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>商品名</td>
                            <td>规格</td>
                            <td class="tx-c">单位</td>
                            <td>生产企业</td>
                            <td>批号范围</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in contorl2">
                            <td>{{ i + 1 }}</td>
                            <td>{{ x.name }}</td>
                            <td>{{ x.spec }}</td>
                            <td class="tx-c">{{ x.unit }}</td>
                            <td>{{ x.company }}</td>
                            <td>
                                <div class="flex">
                                    <span class="pici" v-for="y in x.batch">{{ y }}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--  群众满意度-->
        <el-dialog :visible.sync="dialogSatisfaction" width="1382px" top="112px" @close="closed"
            :destroy-on-close="true">
            <div class="tanWin2">
                <div class="tanTitle2">
                    <div @click="dialogSatisfaction = false" class="btnBack2 hand">
                        <img :src="closeImg" />
                    </div>
                    <span v-if="tan == 'discoveryAnalysis'">三级找药分析</span>
                    <span v-if="tan == 'discoveryRate'">三级找药找到率</span>
                    <span v-if="tan == 'response'">药店响应率</span>
                    <span v-if="tan == 'ask'">问诊响应</span>
                    <span v-if="tan == 'satisfaction'">综合满意度</span>
                    <span v-if="tan == 'insurance'">医保支付</span>
                    <span v-if="tan == 'prescriptionAnalysis'">处方占比分析</span>
                    <span v-if="tan == 'znyxtx'">智能药箱提醒</span>
                    <span v-if="tan == 'znyxhyd'">智能药箱活跃度</span>
                    <span v-if="tan == 'ysfwxyl'">药事服务响应率</span>
                    <span v-if="tan == 'znyxtxfx'">智能药箱服务</span>
                    <span v-if="tan == 'threeLevel'">三级找药分析</span>
                    <span v-if="tan == 'responsivity'">药店响应率</span>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'discoveryAnalysis'">
                    <div class="item">
                        <div class="item1">385</div>
                        <div class="item2">三公里</div>
                    </div>
                    <div class="item">
                        <div class="item1">271</div>
                        <div class="item2">县域内</div>
                    </div>
                    <div class="item">
                        <div class="item1">135</div>
                        <div class="item2">县域外</div>
                    </div>
                    <div class="item">
                        <div class="item1">15</div>
                        <div class="item2">未找到</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'discoveryRate'">
                    <div class="item">
                        <div class="item1">3543</div>
                        <div class="item2">三级找药</div>
                    </div>
                    <div class="item">
                        <div class="item1">3533</div>
                        <div class="item2">找到数</div>
                    </div>
                    <div class="item">
                        <div class="item1">99.72%</div>
                        <div class="item2">找到率</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'response'">
                    <div class="item">
                        <div class="item1">806</div>
                        <div class="item2">三级找药</div>
                    </div>
                    <div class="item">
                        <div class="item1">785</div>
                        <div class="item2">药店响应</div>
                    </div>
                    <div class="item">
                        <div class="item1">97.5%</div>
                        <div class="item2">药店响应率</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'ask'">
                    <div class="item">
                        <div class="item1">3586</div>
                        <div class="item2">在线问诊总量</div>
                    </div>
                    <div class="item">
                        <div class="item1">179</div>
                        <div class="item2">三大医院问诊量</div>
                    </div>
                    <div class="item">
                        <div class="item1">2691</div>
                        <div class="item2">互联网医院问诊量</div>
                    </div>
                    <div class="item">
                        <div class="item1">716</div>
                        <div class="item2">签约医生问诊量</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'satisfaction'">
                    <div class="item">
                        <div class="item1">{{ zhmyd.xt }}</div>
                        <div class="item2">系统</div>
                    </div>
                    <div class="item">
                        <div class="item1">{{ zhmyd.yd }}</div>
                        <div class="item2">药店</div>
                    </div>
                    <div class="item">
                        <div class="item1">{{ zhmyd.ysfw }}</div>
                        <div class="item2">药事服务</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'insurance'">
                    <div class="item">
                        <div class="item1">37867680</div>
                        <div class="item2">自费</div>
                    </div>
                    <div class="item">
                        <div class="item1">420752</div>
                        <div class="item2">医保</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 1000px" v-if="tan == 'prescriptionAnalysis'">
                    <div class="item">
                        <div class="item1">1009999</div>
                        <div class="item2">互联网医院</div>
                    </div>
                    <div class="item">
                        <div class="item1">189374</div>
                        <div class="item2">签约医生</div>
                    </div>
                    <div class="item">
                        <div class="item1">37874</div>
                        <div class="item2">处方流转</div>
                    </div>
                    <div class="item">
                        <div class="item1">25249</div>
                        <div class="item2">其他</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 95%" v-if="tan == 'znyxtx'">
                    <div class="item">
                        <div class="item1">1625</div>
                        <div class="item2">智能药箱提醒总数</div>
                    </div>
                    <div class="item">
                        <div class="item1">1219</div>
                        <div class="item2">服务提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">163</div>
                        <div class="item2">复购提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">114</div>
                        <div class="item2">近效提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">81</div>
                        <div class="item2">回收提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">48</div>
                        <div class="item2">过期提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">16</div>
                        <div class="item2">禁忌提醒数</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 95%" v-if="tan == 'znyxtxfx'">
                    <div class="item">
                        <div class="item1">1219(75%)</div>
                        <div class="item2">服务提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">163(10%)</div>
                        <div class="item2">复购提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">114(7%)</div>
                        <div class="item2">近效提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">81(4%)</div>
                        <div class="item2">回收提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">48(3%)</div>
                        <div class="item2">过期提醒数</div>
                    </div>
                    <div class="item">
                        <div class="item1">16(1%)</div>
                        <div class="item2">禁忌提醒数</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 95%" v-if="tan == 'znyxhyd'">
                    <div class="item">
                        <div class="item1">1625</div>
                        <div class="item2">智能药箱总数</div>
                    </div>
                    <div class="item">
                        <div class="item1">1430</div>
                        <div class="item2">近7天活跃</div>
                    </div>
                    <div class="item">
                        <div class="item1">88%</div>
                        <div class="item2">活跃度</div>
                    </div>
                </div>
                <div class="tanContent" style="width: 50%" v-if="tan == 'ysfwxyl'">
                    <div class="item">
                        <div class="item1">58</div>
                        <div class="item2">签约医生数量</div>
                    </div>
                    <div class="item">
                        <div class="item1">93%</div>
                        <div class="item2">平局响应率</div>
                    </div>
                </div>

                <div class="tanContent" style="width: 1000px" v-if="tan == 'threeLevel'">
                    <div class="item">
                        <div class="item1">3543</div>
                        <div class="item2">三级找药</div>
                    </div>
                    <div class="item">
                        <div class="item1">3533</div>
                        <div class="item2">找到数</div>
                    </div>
                    <div class="item">
                        <div class="item1">99.72%</div>
                        <div class="item2">找到率</div>
                    </div>
                </div>

                <div class="tanContent" style="width: 1000px" v-if="tan == 'responsivity'">
                    <div class="item">
                        <div class="item1">3543</div>
                        <div class="item2">三级找药</div>
                    </div>
                    <div class="item">
                        <div class="item1">3522</div>
                        <div class="item2">药店响应</div>
                    </div>
                    <div class="item">
                        <div class="item1">99.85%</div>
                        <div class="item2">药店响应率</div>
                    </div>
                </div>

            </div>
        </el-dialog>
        <!--今日预警-->
        <el-dialog :visible.sync="dialogMessage" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogMessage = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    今日预警
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 80px;text-align: center">序号</td>
                            <td>药店名称</td>
                            <td style="width: 156px;text-align: center">预警类型</td>
                            <td>预警信息</td>
                            <td>预警时间</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in listData.list">
                            <td style="height: 120px" class="tx-c">
                                <div class="cell">{{ i + 1 }}</div>
                            </td>
                            <td>
                                <div class="cell">{{ x.storeName }}</div>
                            </td>
                            <td>
                                <div class="cell tx-c">{{ x.typeName }}</div>
                            </td>
                            <td>
                                <div class="cell" style="width: 510px">
                                    {{ x.content.replace(x.storeName, "") }}
                                </div>
                            </td>
                            <td>
                                <div style="width: 113px">{{ x.createDate }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postMessage.page" :total="listData.total" :page-size="postMessage.count"
                            @pagination="getMessagePage" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--责令整改，现场检查-->
        <el-dialog :visible.sync="dialogZlzg" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogZlzg = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span v-if="tanType == 10">责令整改</span>
                    <span v-if="tanType == 11">现场检查</span>
                </div>
                <div class="biao">
                    <div style="line-height: 200px;text-align: center;color: white">暂无数据</div>
                </div>
            </div>
        </el-dialog>
        <!--进销存动态分析-->
        <el-dialog :visible.sync="dialogJxc" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJxc = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>进销存动态分析</span>
                </div>
                <div class="biao">
                    <table width="100%" cellspacing="0" cellpadding="0" class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>药店名称</td>
                            <td>药品类别</td>
                            <td>当前库存</td>
                            <td>分析预警</td>
                            <td>提示状态</td>
                        </tr>
                        <tr class="listData jxc">
                            <td style="height: 120px">1</td>
                            <td>浦江县恒生药房连锁有限公司亚太大道店</td>
                            <td>药品</td>
                            <td>24323</td>
                            <td>溢货</td>
                            <td>已提示</td>
                        </tr>
                        <tr class="listData jxc">
                            <td style="height: 120px">2</td>
                            <td>浙江诚心大药房连锁有限公司浦江白马店</td>
                            <td>药品</td>
                            <td>12563</td>
                            <td>溢货</td>
                            <td>已提示</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--销售记录列表-->
        <el-dialog :visible.sync="dialogSale" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogSale = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    今日销售
                </div>
                <div class="biao">
                    <div v-if="showSaleSearch" class="flex saleSearch">
                        <div class="label">订单号</div>
                        <div class="item">
                            <el-input v-model="postSale.orderSn" clearable></el-input>
                        </div>
                        <div class="label">商品通用名</div>
                        <div class="item">
                            <el-input v-model="postSale.goodsName" clearable></el-input>
                        </div>
                        <div class="label">药店名称</div>
                        <div class="item">
                            <!-- <el-select v-model="postSale.storeIds" filterable placeholder="请选择" clearable>
                                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select> -->
                            <xs-select v-model="postSale.storeIds" placeholder="请选择" />
                        </div>
                        <div class="label">日期</div>
                        <div class="item">
                            <el-date-picker v-model="saleTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                        <div class="item flex" style="align-items: center">
                            <div @click="searchSale" class="btnSearch">
                                <img src="../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 120px">订单号</td>
                            <td style="">通用名称</td>
                            <td style="width:130px">规格</td>
                            <td style="width: 50px" class="tx-c">数量</td>
                            <td style="width: 50px" class="tx-c">单位</td>
                            <td style="width: 206px">生产企业</td>
                            <td style="width: 122px">批准文号</td>
                            <td style="width: 80px">批号</td>
                            <td style="width: 109px">有效期至</td>
                            <td style="width: 109px">销售日期</td>
                            <td style="">药店名称</td>
                        </tr>
                        <tr class="listData tdSale" v-for="x in saleData.list">
                            <td style="height: 107px">{{ x.orderSn }}</td>
                            <td>{{ x.commonName }}</td>
                            <td>
                                <div class="overtwo" style="width: 110px">{{ x.spec }}</div>
                            </td>
                            <td>
                                <div class="shuzi tx-c">{{ x.quantity }}</div>
                            </td>
                            <td class="tx-c">{{ x.unit }}</td>
                            <td style="text-align: left;">
                                <div class="overtwo">{{ x.company }}</div>
                            </td>
                            <td>{{ x.approval }}</td>
                            <td>
                                <div class="">{{ x.lot }}</div>
                            </td>
                            <td>
                                <div class="nowrap">{{ x.validDate }}</div>
                            </td>
                            <td>
                                <div class="nowrap">{{ x.createDate.split(" ")[0] }}</div>
                            </td>
                            <td style="text-align: left">
                                <div class="overtwo">{{ x.storeName }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postSale.page" :total="saleData.total" :page-size="postSale.count"
                            @pagination="getSale" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--购进记录列表-->
        <el-dialog :visible.sync="dialogBuy" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogBuy = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    今日购进
                </div>
                <div class="biao">
                    <div class="flex saleSearch">

                        <div class="label">药品名称</div>
                        <div class="item">
                            <el-input v-model="postBuy.goodsName" clearable></el-input>
                        </div>
                        <div class="label">药店名称</div>
                        <div class="item">
                            <!-- <el-select v-model="postBuy.storeIds" filterable placeholder="请选择" clearable>
                                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select> -->
                            <xs-select v-model="postBuy.storeIds" placeholder="请选择" />
                        </div>
                        <div class="label">生产厂家</div>
                        <div class="item">
                            <el-input v-model="postBuy.company" clearable></el-input>
                        </div>
                        <div class="label">日期</div>
                        <div class="item">
                            <el-date-picker v-model="buyTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                            </el-date-picker>
                        </div>
                        <div class="item flex" style="align-items: center">
                            <div @click="searchBuy" class="btnSearch">
                                <img src="../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="">商品名称</td>
                            <td style="">通用名称</td>
                            <td style="width:130px">规格</td>
                            <td style="width: 50px" class="tx-c">单位</td>
                            <td style="width: 206px">生产企业</td>
                            <td style="width: 122px">批准文号</td>
                            <td style="width: 80px">批号</td>
                            <td style="width: 109px">有效期至</td>
                            <td style="width: 109px">到货数量</td>
                            <td style="width: 109px">到货日期</td>
                            <td style="">药店名称</td>
                        </tr>
                        <tr class="listData tdSale" v-for="x in purchaseData.list">
                            <td style="height: 113px">{{ x.goodsName }}</td>
                            <td>{{ x.defaultName }}</td>
                            <td>
                                <div class="overtwo" style="width: 110px">{{ x.spec }}</div>
                            </td>
                            <td>
                                <div class="tx-c">{{ x.unit }}</div>
                            </td>
                            <td>
                                <div class="overtwo">{{ x.company }}</div>
                            </td>
                            <td>
                                {{ x.approval }}
                            </td>
                            <td>{{ x.lot }}</td>
                            <td>
                                <div class="">{{ x.validDate }}</div>
                            </td>
                            <td>
                                <div class="txt-c shuzi">{{ x.quantity }}</div>
                            </td>
                            <td>
                                <div class="nowrap">{{ x.createDate.split(" ")[0] }}</div>
                            </td>
                            <td style="text-align: left">
                                <div class="overtwo">{{ x.storeName }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postBuy.page" :total="purchaseData.total" :page-size="postBuy.count"
                            @pagination="getBuy" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--库存记录列表-->
        <el-dialog :visible.sync="dialogStock" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogStock = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    库存记录
                </div>
                <div class="biao">
                    <div class="flex saleSearch">

                        <div class="label">药品名称</div>
                        <div class="item">
                            <el-input v-model="postStock.goodsName" clearable></el-input>
                        </div>
                        <div class="label">药店名称</div>
                        <div class="item">
                            <!-- <el-select v-model="postStock.storeIds" filterable placeholder="请选择" clearable>
                                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select> -->
                            <xs-select v-model="postStock.storeIds" placeholder="请选择" />
                        </div>
                        <div class="label">药品分类</div>
                        <div>
                            <el-select clearable v-model="postStock.property" placeholder="药品分类">
                                <el-option label="四大类监测品种" value="四大类监测品种" />
                                <el-option label="含兴奋剂药品" value="含兴奋剂药品" />
                                <el-option label="含特复方制剂" value="含特复方制剂" />
                                <el-option label="处方药" value="处方药" />
                                <el-option label="甲类非处方药" value="甲类非处方药" />
                                <el-option label="乙类非处方药" value="乙类非处方药" />
                                <el-option label="其他" value="其他" />
                            </el-select>
                        </div>

                        <div class="item flex" style="align-items: center">
                            <div @click="searchStock" class="btnSearch">
                                <img src="../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 220px">商品编码</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td style="width: 50px">单位</td>
                            <td>生产企业</td>
                            <td>批准文号</td>
                            <td style="width: 150px">批号</td>
                            <td style="width: 150px">有效期至</td>
                            <td style="width: 191px">药店名称</td>
                            <td style="width: 50px">库存</td>

                        </tr>
                        <tr class="listData" v-for="x in stockData.list">
                            <td style="height:113px">{{ x.goodsId }}</td>
                            <td>{{ x.goodsName }}</td>
                            <td>{{ x.defaultName }}</td>
                            <td>{{ x.spec }}</td>
                            <td>{{ x.unit }}</td>
                            <td>{{ x.company }}</td>
                            <td>{{ x.approval }}</td>
                            <td>{{ x.lot }}</td>
                            <td>{{ x.validDate }}</td>
                            <td>{{ x.storeName }}</td>
                            <td>{{ x.quantity }}</td>

                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postStock.page" :total="stockData.total" :page-size="postStock.count"
                            @pagination="getStock" />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--执业药师-->
        <el-dialog :visible.sync="dialogPharmacist" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogPharmacist = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    本地药师
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>药店</td>
                            <td class="tx-c">姓名</td>
                            <td>手机号码</td>
                            <td class="tx-c">性别</td>
                            <td class="tx-c">入职时间</td>
                            <td>专业类别</td>

                            <td class="tx-c">资格证书</td>
                            <td class="tx-c">照片</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in pharmacistData.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 128px">{{ x.storeName }}</td>
                            <td class="tx-c">{{ x.name }}</td>
                            <td>{{ mbl(x.phone) }}</td>
                            <td class="tx-c">
                                {{ JSON.parse(x.attr).sex == "man" ? "男" : "女" }}
                            </td>
                            <td class="tx-c">{{ x.entryTime }}</td>
                            <td>
                                {{ x.technical }}
                            </td>
                            <td class="tx-c">
                                <el-image style="width: 50px; height: 50px" :src="x.photo"
                                    :preview-src-list="[x.photo]">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline" />
                                    </div>
                                </el-image>
                            </td>
                            <td>
                                <el-image style="width: 50px; height: 50px" :src="x.entryPhoto"
                                    :preview-src-list="[x.entryPhoto]">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline" />
                                    </div>
                                </el-image>
                            </td>
                        </tr>
                    </table>
                    <div style="font-size: 22px;position: absolute;bottom:32px;color: #fff">♂男：<span
                            style="color: #2d70cc;margin-right: 30px">45(18.14%)</span>
                        ♀女：<span style="color:#d12e7c">203(81.86%)</span></div>
                    <div class="pager">
                        <pager :page="postPharmacist.page" :total="pharmacistData.total"
                            :page-size="postPharmacist.count" @pagination="getPharmacist" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--药店列表-->
        <el-dialog :visible.sync="dialogStore" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogStore = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    本地药店
                </div>
                <div class="biao">
                    <div class="flex storeSearch">
                        <div class="label">药店名称</div>
                        <div class="item" style="margin-right: 10px">
                            <el-input v-model="storeName" clearable style="width: 250px"></el-input>
                        </div>
                        <div class="item flex" style="align-items: center">
                            <div @click="searchStore" class="btnSearch">
                                <img src="../assets/smart/home/s.png" />
                            </div>
                        </div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td style="width: 220px">药店名称</td>
                            <td>门头照</td>
                            <td>药店代码</td>
                            <td>联系人</td>
                            <td>联系电话</td>
                            <td style="width: 200px">药店地址</td>
                            <td>省药监编码</td>
                            <td>状态</td>
                            <td>最近更新时间</td>
                        </tr>
                        <tr class="listData dabiaozi" :class="{ ban: i % 2 == 1 }" v-for="(x, i) in storeData.list"
                            :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 116px" @click="handleSelect(x)">{{ x.name }}</td>
                            <td> <el-image style="width: 50px; height: 50px" :src="x.avatar"
                                    :preview-src-list="[x.avatar]">
                                    <div slot="error" class="image-slot" style="line-height: 50px;text-align: center">
                                        无
                                    </div>
                                </el-image></td>
                            <td>{{ x.code }}</td>
                            <td>{{ x.contact }}</td>
                            <td>{{ mbl(x.phone) }}</td>
                            <td>
                                <div class="overthree">{{ x.address }}</div>
                            </td>
                            <td>{{ x.manageCode }}</td>
                            <td>{{ x.easyCode }}</td>
                            <td>
                                <div class="time">{{ x.updateDate }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postStore.page" :total="storeData.total" :page-size="postStore.count"
                            @pagination="getStore" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--今日找药发布数-->
        <el-dialog :visible.sync="dialogFabu" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogFabu = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span v-if="fabuType == 1">今日找药发布</span>
                    <span v-if="fabuType == 2">找到药品</span>
                </div>
                <div class="biao">
                    <table class="listtable" v-if="fabuType == 1">
                        <tr class="listTitle">
                            <td style="width: 80px" class="tx-c">序号</td>
                            <td style="width: 220px" class="tx-c">找药人姓名</td>
                            <td style="width: 220px" class="tx-c">联系电话</td>
                            <td>药品名称</td>
                            <td style="width:200px">发布时间</td>
                        </tr>
                        <tr class="listData dabiaozi" v-for="(x, i) in FabuData.list">
                            <td style="height: 120px" class="tx-c">{{ i + 1 }}</td>
                            <td class="tx-c">{{ x.patientName }}</td>
                            <td class="tx-c">{{ mbl(x.patientPhone) }}</td>
                            <td>{{ x.goodsName }}</td>
                            <td>{{ x.createDate }}</td>
                        </tr>
                    </table>

                    <table class="listtable" v-if="fabuType == 2">
                        <tr class="listTitle">
                            <td>找药人姓名</td>
                            <td>联系电话</td>
                            <td>药品名称</td>
                            <td>规格型号</td>
                            <td>单位</td>
                            <td>数量</td>
                            <td>接单药店名称</td>
                            <td>药店属地</td>
                            <td>配送方式</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in FabuData.list">
                            <td style="height: 120px">{{ x.patientName }}</td>
                            <td>{{ mbl(x.patientPhone) }}</td>
                            <td>{{ x.commonName }}</td>
                            <td>{{ x.spec }}</td>
                            <td>{{ x.unit }}</td>
                            <td>{{ x.quantity }}</td>
                            <td>{{ x.storeName }}</td>
                            <td>浦江</td>
                            <td>
                                <span v-if="x.quantity >= 3" style="color:rgb(251, 201, 2)">快递</span>
                                <span v-else style="color: #22f906">自提</span>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postFabu.page" :total="FabuData.total" :page-count="postFabu.count"
                            @pagination="getPrescriptionFabu" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--网订店送-->
        <el-dialog :visible.sync="dialogStoreCloud" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogStoreCloud = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    网订店送
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>药店名称</td>

                            <td>门头照</td>
                            <td style="width: 220px">联系电话</td>
                            <td style="width:450px">药店地址</td>
                            <!--                            <td>省药监编码</td>-->
                            <!--                            <td>状态</td>-->
                        </tr>
                        <tr class="listData dabiaozi" v-for="(x, i) in yunStore.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 120px">{{ x.name }}</td>

                            <td> <el-image style="width: 50px; height: 50px" :src="x.photo"
                                    :preview-src-list="[x.photo]">
                                    <div slot="error" class="image-slot" style="line-height: 50px;text-align: center">
                                        无
                                    </div>
                                </el-image></td>
                            <td style="width: 220px">{{ mbl(x.phone) }}</td>
                            <td>{{ x.address }}</td>
                            <!--                            <td>{{ x.manageCode }}</td>-->
                            <!--                            <td>{{ x.easyCode }}</td>-->
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postYunStore.pageNum" :total="yunStore.total" :page-count="postYunStore.pageSize"
                            @pagination="getYunStore" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--商品信息-->
        <el-dialog :visible.sync="dialogGoods" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogGoods = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    商品信息
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>订单号</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td style="">数量</td>
                            <td>单位</td>
                            <td>批号</td>
                            <td>批准文号</td>
                            <td>生产企业</td>
                            <td>药店名称</td>
                            <td>有效期</td>
                        </tr>
                        <tr class="listData dabiaozi" v-for="x in goodsData">
                            <td style="height: 120px">{{ x.orderSn }}</td>
                            <td>{{ x.goodsName }}</td>
                            <td>{{ x.commonName }}</td>
                            <td>{{ x.spec }}</td>
                            <td>
                                <span class="shuzi">{{ x.quantity }}</span>
                            </td>
                            <td>{{ x.unit }}</td>
                            <td>{{ x.lot }}</td>
                            <td>{{ x.approval }}</td>
                            <td>
                                <div class="overtwo">{{ x.company }}</div>
                            </td>
                            <td>
                                <div class="overtwo">{{ x.storeName }}</div>
                            </td>
                            <td class="nowrap">{{ x.validDate }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--药师未正常履职-->
        <el-dialog :visible.sync="dialogLvzhi" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogLvzhi = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    药师未正常履职
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">药师未正常履职</div>
                        <div class="item2" style="line-height: 80px">1</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>药店名称</td>
                            <td class="txt-c">药师姓名</td>
                            <td>联系电话</td>
                            <td>备注</td>
                        </tr>
                        <tr class="listData">
                            <td>浦江县恒生药房连锁有限公司新华路店</td>
                            <td class="txt-c">季夏嫄</td>
                            <td>152****9810</td>
                            <td>请产假</td>
                        </tr>
                    </table>

                    <!--          <div class="pager">-->
                    <!--            <pager :page="postYizhan.page" :total="68" :page-size="postYizhan.count" @pagination="getYizhan"/>-->
                    <!--          </div>-->
                </div>
            </div>
        </el-dialog>
        <!--药师驿站服务量-->
        <el-dialog :visible.sync="dialogYizhan" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogYizhan = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    药事驿站服务
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">药事驿站服务量</div>
                        <div class="item2" style="line-height: 80px">{{ yizhanCi }}</div>
                    </div>
                </div>


                <div class="server">


                    <div class="server-content" v-if="yzType == 2">
                        <div class="server-commont" v-for="(item, index) in yizhan2" :key="index">
                            <span class="server-commont-title">{{ item.a2 }}</span>
                            <div class="server-time" style="margin-top:25px">
                                <span>服务时间：</span>
                                <span>{{ item.a3 }}</span>
                            </div>
                            <div class="server-time">
                                <span style="margin-right:10px">接受预约:</span>
                                <span>{{ item.a4 }}</span>
                            </div>
                            <div class="server-time">
                                <span>本年服务量：</span>
                                <span style="font-size:30px;color:#ceec02">{{ item.a5 }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="yzType == 1">
                        <table class="listtable">
                            <tr class="listTitle">
                                <td>药店名称</td>
                                <td>服务名称</td>
                                <td>服务时间</td>
                                <td>接受预约</td>
                                <td>本年服务量</td>
                            </tr>
                            <tr class="listData" v-for="(x, i) in yizhanData2">
                                <td style="height: 110px">{{ x.a1 }}</td>
                                <td>{{ x.a2 }}</td>
                                <td>{{ x.a3 }}</td>
                                <td>{{ x.a4 }}</td>
                                <td>{{ x.a5 }}</td>
                            </tr>
                        </table>

                        <div class="pager">
                            <pager :page="postYizhan.page" :total="yizhan.length" :page-size="postYizhan.count"
                                @pagination="getYizhan" />
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--流程图-->
        <el-dialog :visible.sync="dialogLct" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogLct = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>
                <div class="lctContent">
                    <div class="lctTitle">
                        <div class="lctTitle1">
                            <span class="item1">预警编号：</span><span class="item2">{{ lctData.code }}</span>
                        </div>
                        <div class="lctTitle2">
                            <span class="item1">预警药店名称：</span><span class="item2">{{ lctData.storeName }}</span>
                        </div>
                    </div>
                    <div class="lctBlock">
                        <div class="odd flex">
                            <div class="item">
                                <div class="item1">
                                    现场核查
                                </div>
                                <div class="item2">

                                </div>
                                <div class="item3"></div>
                                <div class="item4">
                                    现场检查中...
                                </div>
                            </div>
                            <div class="flex_vc"><img src="../assets/yun/dialog/lct1.png" /></div>
                            <div class="item">
                                <div class="item1">
                                    检查反馈
                                </div>
                                <div class="item2">
                                </div>
                                <div class="item3"></div>
                                <div class="item4 flex_vc">
                                    <div>
                                        <span v-if="lctZt == 2">因系统原因未上传处方，建议不予扣分</span>
                                        <span v-if="lctZt == 1">系阴凉区温湿度采集设备故障现已整改到位</span>
                                    </div>
                                    <div>
                                        <el-image
                                            v-if="lctZt == 1"
                                            fit="contain"
                                            style="width: 100px; height: 100px"
                                            src="http://pic.yaojian.xusos.com/yaojian/images/2022-07-04/2022_7_4_1653669502.jpeg"
                                            :preview-src-list="['http://pic.yaojian.xusos.com/yaojian/images/2022-07-04/2022_7_4_1653669502.jpeg']">
                                        </el-image>
                                        <el-image 
                                            v-if="lctZt == 2"
                                            fit="contain"
                                            style="width: 100px; height: 100px"
                                            src="http://pic.yaojian.xusos.com/chufang/images/2022-07-23/2022_7_23_190082301.jpg"
                                            :preview-src-list="['http://pic.yaojian.xusos.com/chufang/images/2022-07-23/2022_7_23_190082301.jpg']">
                                        </el-image>
                                    </div>
                                </div>
                            </div>
                            <div class="flex_vc"><img src="../assets/yun/dialog/lct1.png" /></div>
                            <div class="item active">
                                <div class="item1">
                                    结果
                                </div>
                                <div class="item2">

                                </div>
                                <div class="item3"></div>
                                <div class="item4">
                                    不予信用扣分
                                </div>
                            </div>
                        </div>
                        <div class="even"></div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--承诺限塑-->
        <el-dialog :visible.sync="dialogXiansu" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogXiansu = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    承诺限塑
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">承诺限塑</div>
                        <div class="item2" style="line-height: 80px">91</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>药店名称</td>
                            <td>药店地址</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in xiansuData2">
                            <td style="height: 110px">{{ x.a1 }}</td>
                            <td>{{ x.a2 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postXiansu.page" :total="xiansu.length" :page-size="postXiansu.count"
                            @pagination="getXiansu" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--部门评价-->
        <el-dialog :visible.sync="dialogBmpj" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogBmpj = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    部门评价
                </div>

                <div style="height: 30px"></div>
                <div class="biao flex" style="justify-content: space-between">
                    <div style="width: 45%">
                        <table class="listtable">
                            <tr class="listTitle">
                                <td class="txt-c">联动单位</td>
                                <td class="txt-c">处置任务数</td>
                                <td class="txt-c">处置完成数</td>
                                <td class="txt-c">按时完成率</td>
                                <td class="txt-c">评价</td>
                            </tr>
                            <tr class="listData" v-for="x in excellent.slice(0, 4)" v-if="bmpjType == 1">
                                <td class="txt-c" style="height:150px">{{ x.a1 }}</td>
                                <td class="txt-c">{{ x.a2 }}</td>
                                <td class="txt-c">{{ x.a3 }}</td>
                                <td class="txt-c">{{ x.ratio }}</td>
                                <td class="txt-c">{{ x.a4 }}</td>
                            </tr>
                            <tr class="listData" v-for="x in goodList" v-if="bmpjType == 2">
                                <td class="txt-c" style="height:150px">{{ x.a1 }}</td>
                                <td class="txt-c">{{ x.a2 }}</td>
                                <td class="txt-c">{{ x.a3 }}</td>
                                <td class="txt-c">{{ x.ratio }}</td>
                                <td class="txt-c">{{ x.a4 }}</td>
                            </tr>
                            <tr class="listData" v-for="x in qualified" v-if="bmpjType == 3">
                                <td class="txt-c" style="height:150px">{{ x.a1 }}</td>
                                <td class="txt-c">{{ x.a2 }}</td>
                                <td class="txt-c">{{ x.a3 }}</td>
                                <td class="txt-c">{{ x.ratio }}</td>
                                <td class="txt-c">{{ x.a4 }}</td>
                            </tr>
                        </table>
                    </div>
                    <div style="width: 45%">
                        <table class="listtable">
                            <tr class="listTitle">
                                <td class="txt-c">联动单位</td>
                                <td class="txt-c">处置任务数</td>
                                <td class="txt-c">处置完成数</td>
                                <td class="txt-c">按时完成率</td>
                                <td class="txt-c">评价</td>
                            </tr>
                            <tr class="listData" v-for="x in excellent.slice(4)" v-if="bmpjType == 1">
                                <td class="txt-c" style="height:150px">{{ x.a1 }}</td>
                                <td class="txt-c">{{ x.a2 }}</td>
                                <td class="txt-c">{{ x.a3 }}</td>
                                <td class="txt-c">{{ x.ratio }}</td>
                                <td class="txt-c">{{ x.a4 }}</td>
                            </tr>
                        </table>
                    </div>

                    <!--            <div class="pager">-->
                    <!--              <pager-->
                    <!--                  :page="postZhiyuan.page"-->
                    <!--                  :total="196"-->
                    <!--                  :page-size="postZhiyuan.count"-->
                    <!--                  @pagination="getZhiyuan"-->
                    <!--              />-->
                    <!--            </div>-->
                </div>
            </div>
        </el-dialog>
        <!--扣分明细-->
        <el-dialog :visible.sync="dialogKoufen" width="1600px" top="5vh">
            <div v-if="end" style="position: fixed;width: 100%;height: 100%;z-index: 99999999999;top:0;left:0"><img
                    style="width: 100%;height: 100%" src="../assets/yun/dialog/end.jpg" /> </div>
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogKoufen = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span @click="end = true">积分变动明细</span>
                </div>
                <!--          <div class="ThreeGradeTop">-->
                <!--            <div class="item ">-->
                <!--              <div class="item1">扣分明细</div>-->
                <!--              <div class="item2" style="line-height: 80px">196</div>-->
                <!--            </div>-->
                <!--          </div>-->
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td style="width: 400px">药店名称</td>
                            <td>分值项目</td>
                            <td>具体说明</td>
                            <td class="txt-c ">部门动态赋分</td>
                            <td class="txt-c">得分</td>
                            <td class="txt-c">时间</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in jifen2" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 110px">{{ x.name }}</td>
                            <td>{{ x.remark }}</td>
                            <td>{{ x.other }}</td>
                            <td class="txt-c ">
                                <div class="jianfen" :class="x.score > 0 ? 'green' : 'red'">{{ x.score }}</div>
                            </td>
                            <td class="txt-c">{{ x.endScore }}</td>
                            <td class="txt-c">{{ x.createDate }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="page" :total="total" :page-size="size" @pagination="changePageKoufen" />
                    </div>
                </div>
                <!--              <div class="khpj1" v-if="jifen2[0].type=='A'"><img src="../assets/yun/dialog/yyf.png"/> 上线云药房<img src="../assets/yun/dialog/sfd1.png"/>“好商风”贷款<img src="../assets/yun/dialog/sfd2.png"/>监管无事不扰</div>-->
                <div class="khpj2" v-if="level == 'D'"><img src="../assets/yun/dialog/qxyb1.png" />重点监管<img
                        src="../assets/yun/dialog/qxyb2.png" />暂停医保<img src="../assets/yun/dialog/qxyb3.png" />下线云药房<img
                        src="../assets/yun/dialog/sfd1-2.png" />暂停好商风贷</div>
            </div>
        </el-dialog>
        <!--志愿服务-->
        <el-dialog :visible.sync="dialogZhiyuan" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogZhiyuan = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    特色服务
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">特色服务</div>
                        <div class="item2" style="line-height: 80px">208</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td class="txt-c">姓名</td>
                            <td class="txt-c">药店名称</td>
                            <td class="txt-c">服务名称</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in zhiyuanData2" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td class="txt-c" style="height: 110px">{{ x.a1 }}</td>
                            <td class="txt-c">{{ x.a2 }}</td>
                            <td class="txt-c">{{ x.a3 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postZhiyuan.page" :total="208" :page-size="postZhiyuan.count"
                            @pagination="getZhiyuan" />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--      药店证照-->
        <el-dialog :visible.sync="dialogZhengzhao" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogZhengzhao = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    药店证照
                </div>
                <div class="biao">
                    <div class="picContent">
                        <div class="item">
                            <div class="item1">药店经营执照</div>
                            <div class="item2">
                                <div class="item2-1" v-for="x in licence">
                                    <el-image style="width: 150px; height: 150px" :src="x" :preview-src-list="[x]"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="item3">正常</div>
                        </div>
                        <div class="item">
                            <div class="item1">
                                <div>药师证/</div>
                                <div>执业药师证/</div>
                                <div>执业药师注册证</div>
                            </div>
                            <div class="item2">
                                <div class="item2-1" v-for="x in zyys">
                                    <el-image style="width: 150px; height: 150px" :src="x" :preview-src-list="[x]"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="item3">正常</div>
                        </div>
                        <div class="item">
                            <div class="item1">从业人员健康证</div>
                            <div class="item2">
                                <div class="item2-1" v-for="x in ygzz">
                                    <el-image style="width: 150px; height: 150px" :src="x" :preview-src-list="[x]"
                                        fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="item3">正常</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--今日风险处置-->
        <el-dialog :visible.sync="dialogJrfxcz" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJrfxcz = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    今日风险处置
                </div>
                <div class="" style="margin-top: 40px">
                    <div class="jrfxcz">
                        <div class="itemLeft">
                            <div>
                                <div class="item" :class="{ active: czzt == 1 }" @click="changeCzzt(1)">
                                    <div class="item1">今日风险处置</div>
                                    <div class="item2">
                                        <!-- {{fxczList.total}} -->
                                        1
                                        <span>▶</span>
                                    </div>
                                </div>
                                <div class="item " :class="{ active: czzt == 2 }" @click="changeCzzt(2)">
                                    <div class="item1">联动处置数</div>
                                    <div class="item2">0<span>▶</span></div>
                                </div>
                                <div style="text-align: center">
                                    <img src="../assets/dialog/1.png" />
                                </div>
                                <div class="item " :class="{ active: czzt == 3 }" @click="changeCzzt(3)">
                                    <div class="item1">处置完成</div>
                                    <div class="item2">{{ fxczOkList.total }}<span>▶</span></div>
                                </div>
                                <div class="item " :class="{ active: czzt == 4 }" @click="changeCzzt(4)">
                                    <div class="item1" style="color: #E82A02">今日未处置</div>
                                    <div class="item2">0<span>▶</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="itemRight">
                            <div class="biao" style="padding: 30px;">
                                <div style="background-color: #0b2043">
                                    <!--                                今日风险处置-->
                                    <table v-if="czzt == 1" class="listtable"
                                        style="background-color: rgba(7, 21, 45, .5);">
                                        <tr class="listTitle">
                                            <td style="">序号</td>
                                            <td>药店名称</td>
                                            <td style="width: 110px">发现时间</td>
                                            <td>风险名称</td>
                                            <td>发现方式</td>
                                            <td>当前环节</td>
                                            <td style="width: 110px">处置时间</td>
                                            <td>处置结果</td>
                                            <td>图片</td>
                                            <td>操作</td>
                                        </tr>
                                        <tr class="listData" v-for="item in tabalList" :key="item.id">
                                            <td style="height: 116px">{{ item.id }}</td>
                                            <td style="width:140px">{{ item.name }}</td>
                                            <td style="width:80px">{{ fxsj }}</td>
                                            <td>{{ item.warringName }}</td>
                                            <td>{{ item.fundType }}</td>
                                            <td>{{ item.present }}</td>
                                            <td style="width: 83px">{{ czsj }}</td>
                                            <td>{{ item.disposeResult }}</td>
                                            <td>
                                                <el-image :src="wenduImg" style="width:100px;height:100px"
                                                    :preview-src-list="[wenduImg]">
                                                    <div slot="placeholder" style="line-height: 100px">
                                                        加载中
                                                    </div>
                                                </el-image>
                                            </td>
                                            <td>
                                                <span class="btn" @click="showImg(item)">查看</span>
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="pager-post" v-if="czzt == 1">
                                        <pager :page="postFxcz.page" :total="1" :page-size="postFxcz.count"
                                            @pagination="todayChuzhi" />
                                    </div>
                                    <!--  处置完成-->
                                    <table v-if="czzt == 3" class="listtable"
                                        style="background-color: rgba(7, 21, 45, .5);">
                                        <tr class="listTitle">
                                            <td>药店名称</td>
                                            <td style="width: 240px">预警类别</td>
                                            <td>检查所</td>
                                            <td>本次积分</td>
                                            <td>累计积分</td>
                                        </tr>
                                        <tr class="listData" v-for="(x, i) in fxczOkList.list">
                                            <td style="height: 116px">{{ x.storeName }}</td>
                                            <td style="width: 240px">{{ x.remark }}</td>
                                            <td>{{ x.regulatoryName }}</td>
                                            <td>{{ x.score }}</td>
                                            <td>
                                                {{ x.totalScore }}
                                            </td>
                                        </tr>
                                    </table>
                                    <div v-if="czzt == 3 && fxczOkList.total > 0">
                                        <pager :page="fxczOkList.page" :total="fxczOkList.total"
                                            :page-size="fxczOkList.count" @pagination="todayChuzhi" />
                                    </div>
                                    <table v-if="czzt == 2 || czzt == 4" class="listtable"
                                        style="background-color: rgba(7, 21, 45, .5);">
                                        <tr class="listTitle" style="background-color: rgba(7, 21, 45, 0.5)">
                                            <td style="">序号</td>
                                            <td>企业名称</td>
                                            <td style="width: 110px">发现时间</td>
                                            <td>违规内容</td>
                                            <td>当前状态</td>
                                        </tr>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--签约医生-->
        <el-dialog :visible.sync="dialogDoctor" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogDoctor = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    签约医生
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td style="width: 150px">姓名</td>
                            <td>联系电话</td>
                            <td>执业资质</td>
                            <td>执业范围</td>
                            <td>服务村</td>
                            <td class="tx-c">服务人口数</td>
                            <td>单位</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in doctorData2">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 120px">{{ x.name }}</td>
                            <td>{{ mbl(x.tel) }}</td>
                            <td>{{ x.zc }}</td>
                            <td>{{ x.zyfw }}</td>
                            <td>{{ x.cun }}</td>
                            <td>
                                <div class="shuzi">{{ x.ren }}</div>
                            </td>
                            <td>{{ x.uint }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postDoctor.page" :total="doctorData.total" :page-size="postDoctor.count"
                            @pagination="getDoctor" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--获得好商风贷-->
        <el-dialog :visible.sync="dialogShangfengdai" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogShangfengdai = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    获得好商风贷
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 500px">药店名称</td>
                            <td>药店地址</td>
                            <td class="txt-c">药店等级</td>
                            <td>授信额度</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in ShangfengdaiData2">
                            <td style="height: 120px">{{ x.a1 }}</td>
                            <td>{{ x.a2 }}</td>
                            <td class="txt-c levelA">A</td>
                            <td>{{ x.a3 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postShangfengdai.page" :total="shangfengdai.length"
                            :page-size="postShangfengdai.count" @pagination="getShangfengdai" />
                    </div>
                </div>
                <div class="khpj1"><img src="../assets/yun/dialog/yyf.png" /> 上线云药房<img
                        src="../assets/yun/dialog/sfd1.png" />“好商风”贷款<img src="../assets/yun/dialog/sfd2.png" />监管无事不扰
                </div>
            </div>
        </el-dialog>
        <!--预警数-->
        <el-dialog :visible.sync="dialogWarning" width="1600px" top="5vh" @close="closed" destroy-on-close>
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogWarning = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    {{ yjsZt == 1 ? "今日预警" : "预警列表" }}
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 80px" class="tx-c">序号</td>
                            <td>药店名称</td>
                            <td style="width: 156px">预警类型</td>
                            <td>预警信息</td>
                            <td style="width:200px">状态</td>
                            <td>预警时间</td>
                            <td>操作</td>
                        </tr>
                        <tr class="listData tdSale" v-for="(x, i) in warningData.list">
                            <td style="height:120px" class="tx-c">
                                <div class="cell">{{ i + 1 }}</div>
                            </td>
                            <td>
                                <div class="">{{ x.storeName }}</div>
                            </td>
                            <td>
                                <div class="">{{ x.typeName }}</div>
                            </td>
                            <td>
                                <div class="overthree" style="width: 450px" v-html="x.content"></div>
                            </td>
                            <td style="width:200px">
                                <div class="nowrap" v-if="intervalTime(x.createDate)" style="color:#38f633"> 已自主整改</div>
                                <div class="nowrap" v-else>自主整改中</div>
                            </td>
                            <td>
                                <div style="width: 109px">{{ x.createDate }}</div>
                            </td>
                            <td>
                                <div style="width: 45px" class="btn" @click="openYjDetail(x)">查看</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postWarning.page" :total="warningData.total" :page-size="postWarning.count"
                            @pagination="getWarning" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--预警类型-->
        <el-dialog :visible.sync="dialogWarningType" width="1600px" top="5vh" @close="closed" destroy-on-close>
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogWarningType = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>{{ WarningType }}</span>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 80px" class="tx-c">序号</td>
                            <td>药店名称</td>
                            <td class="tx-c">门头照</td>
                            <td>预警信息</td>
                            <td>预警时间</td>
                        </tr>
                        <tr class="listData tdSale" v-for="(x, i) in warningTypeData.list">
                            <td style="height:120px" class="tx-c">
                                <div class="cell">{{ i + 1 }}</div>
                            </td>
                            <td>
                                <div class="">{{ x.storeName }}</div>
                            </td>
                            <td>
                                <el-image style="width: 50px; height: 50px" :src="x.avatar"
                                    :preview-src-list="[x.avatar]">
                                    <div slot="error" class="image-slot" style="line-height: 50px;text-align: center">
                                        无
                                    </div>
                                </el-image>
                            </td>
                            <td>
                                <div class="overthree" style="width: 450px" v-html="x.content"></div>
                            </td>
                            <td>
                                <div style="width: 109px">{{ x.createDate }}</div>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postWarningType.page" :total="warningTypeData.total"
                            :page-size="postWarningType.count" @pagination="getWarningType" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--三级找药列表-->
        <el-dialog :visible.sync="dialogThreeGrade" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogThreeGrade = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    三级找药
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">累计找药量</div>
                        <div class="item2" style="line-height: 80px">32365665</div>
                    </div>
                    <div class="item">
                        <div class="item1">县域完成量</div>
                        <div class="item2">
                            <div class="item2-1">15%</div>
                            <div class="item2-2">353</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item1">市域完成量</div>
                        <div class="item2">
                            <div class="item2-1">15%</div>
                            <div class="item2-2">353</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item1">省域完成量</div>
                        <div class="item2">
                            <div class="item2-1">15%</div>
                            <div class="item2-2">353</div>
                        </div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>找药人姓名</td>
                            <td>联系电话</td>
                            <td>药品名称</td>
                            <td>规格型号</td>
                            <td>单位</td>
                            <td>数量</td>
                            <td>接单药店名称</td>
                            <td>药店属地</td>
                            <td>配送方式</td>
                        </tr>
                        <!--            <tr class="listData dabiaozi" :class="{ban:i%2==1}" v-for="(x,i) in storeData.list">-->
                        <!--              <td style="height: 116px">{{i}}</td>-->
                        <!--              <td>{{x.code}}</td>-->
                        <!--              <td>{{x.contact}}</td>-->
                        <!--              <td>{{mbl(x.phone)}}</td>-->
                        <!--              <td>{{x.address}}</td>-->
                        <!--              <td>{{x.manageCode}}</td>-->

                        <!--            </tr>-->
                    </table>

                    <div class="pager">
                        <pager :page="postStore.page" :total="storeData.total" :page-size="postStore.count"
                            @pagination="getStore" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--特殊人群服务量-->
        <el-dialog :visible.sync="dialogTsrq" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogTsrq = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    代办购药
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">丰收驿站累计服务量</div>
                        <div class="item2" style="line-height: 80px">
                            <!-- {{ fengshouci }} -->
                            1004

                        </div>
                    </div>
                    <div class="item ">
                        <div class="item1">送药上山累计服务量</div>
                        <div class="item2" style="line-height: 80px">{{ shangshanci }}</div>
                    </div>
                </div>
                <div class="listTsrq flex">
                    <div class="item">
                        <div class="biao">
                            <div style="height: 360px">
                                <div class="chart " style="">
                                    <div id="myChart_tsrq" :style="{ width: '700px', height: '360px' }"></div>
                                </div>

                            </div>
                            <table class="listtable">
                                <tr class="listTitle">
                                    <td style="width: 40px" class="tx-c">序号</td>
                                    <td>站点名称</td>
                                    <td style="width: 110px">负责人</td>
                                    <td class="tx-c" style="width: 110px">服务次数</td>
                                </tr>
                            </table>
                            <div style="height:220px;overflow: hidden;">
                                <vue-seamless-scroll :data="shangshan" :class-option="defaultOption">
                                    <table class="listtable">
                                        <tr class="listData" v-for="(x, i) in fengshou">
                                            <td style="width: 40px" class="tx-c">{{ i + 1 }}</td>
                                            <td>{{ x.a1 }}</td>
                                            <td style="width: 110px">{{ x.a3 }}</td>
                                            <td class="tx-c" style="width: 110px">{{ x.a6 }}</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>

                            <!--                            <div class="pager" style="width: 600px">
                                <pager
                                    :page="postFengshou.page"
                                    :total="106"
                                    :page-size="postFengshou.count"
                                    @pagination="getFengshou"
                                />
                            </div>
                            <div class="pager" style="width: 600px;left: 934px">
                                <pager
                                    :page="postShangshan.page"
                                    :total="10"
                                    :page-size="postShangshan.count"
                                    @pagination="getShangshan"
                                />
                            </div>-->
                        </div>
                    </div>
                    <div class="item">
                        <div class="biao">
                            <div style="height: 360px;position: relative">
                                <div class="chart" style="top:0px;left:15px;position: absolute">
                                    <div id="myChart_gynl" :style="{ width: '350px', height: '300px' }"></div>
                                </div>
                                <div class="chart" style="top:0px;left: 330px;position: absolute">
                                    <div id="myChart_mbnl" :style="{ width: '400px', height: '300px' }"></div>
                                </div>

                            </div>
                            <table class="listtable">
                                <tr class="listTitle">
                                    <td class="tx-c" style="width: 40px">序号</td>
                                    <td>站点名称</td>
                                    <td class="tx-c" style="width: 120px">负责人</td>
                                    <td class="tx-c" style="width: 90px">服务次数</td>
                                    <td class="tx-c" style="width: 125px">服务对象人次</td>
                                </tr>
                            </table>
                            <div style="height:220px;overflow: hidden;">
                                <vue-seamless-scroll :data="shangshan" :class-option="defaultOption">
                                    <table class="listtable">
                                        <tr class="listData" v-for="(x, i) in shangshan">
                                            <td class="tx-c" style="width: 40px">{{ i + 1 }}</td>
                                            <td>{{ x.a1 }}</td>
                                            <td class="tx-c" style="width: 120px">{{ x.a2 }}</td>
                                            <td class="tx-c" style="width: 90px">{{ x.a4 }}</td>
                                            <td class="tx-c" style="width: 125px">{{ x.a5 }}</td>
                                        </tr>
                                    </table>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--未找到-->
        <el-dialog :visible.sync="dialogWeizd" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogWeizd = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    未找到
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">未找到药品数量</div>
                        <div class="item2" style="line-height: 80px">32365665</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>找药人姓名</td>
                            <td>年龄</td>
                            <td>联系电话</td>
                            <td style="width: 200px">药品名称</td>
                            <td>规格型号</td>
                            <td>单位</td>
                            <td>数量</td>
                            <td>找药状态</td>
                            <td>处置情况</td>
                        </tr>
                        <!--            <tr class="listData dabiaozi" :class="{ban:i%2==1}" v-for="(x,i) in storeData.list">-->
                        <!--              <td style="height: 116px">{{i}}</td>-->
                        <!--              <td>{{x.code}}</td>-->
                        <!--              <td>{{x.contact}}</td>-->
                        <!--              <td>{{mbl(x.phone)}}</td>-->
                        <!--              <td>{{x.address}}</td>-->
                        <!--              <td>{{x.manageCode}}</td>-->

                        <!--            </tr>-->
                    </table>

                    <div class="pager">
                        <pager :page="postStore.page" :total="storeData.total" :page-size="postStore.count"
                            @pagination="getStore" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--在线问诊数-->
        <el-dialog :visible.sync="dialogAskOnlie" width="1600px" top="5vh">
            <div class="tanWin online">
                <div class="tanTitle">
                    <div @click="dialogAskOnlie = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    在线问诊数
                </div>
                <div class="online-commont">
                    <!--                       <div id="chart3d_1" ></div>-->
                    <div style="margin-top: 80px;width: 331px"><img src="../assets/yun/dialog/医院图标.png" /></div>
                    <div class="chart3d-2">问诊响应分析</div>
                    <div class="legend3d_1 abs">
                        <!--                        <div class="flex_vc" style="color: white">
                   <div class="yuan" style="background-color: rgba(0,127,244,.9)"></div>
                <div>
                  <div>三大医院 1.01%</div>
                  <div style="font-size: 18px;line-height: 100%;color: #888888">(浦江中医院，浦江人民医院，浦江第二院)</div>
                </div>
               </div>-->
                        <div class="flex_vc" style="color: white;margin-top: 15px">
                            <div class="yuan" style="background-color: rgba(209,126,23,.9)"></div>
                            <div>
                                <div>互联网医院 54.76%</div>
                                <div style="font-size: 18px;line-height: 150%;width: 330px;color: #888888">
                                    (银川复星互联网医院，温州市瓯海区第三人民医院众康互联网医院等39家)</div>
                            </div>
                        </div>
                        <div class="flex_vc" style="color: white;margin-top: 15px">
                            <div class="yuan" style="background-color: rgba(20,101,0,0.9)"></div>
                            <div>签约医生 44.23%</div>
                        </div>
                    </div>
                    <div class="online-table">
                        <div class="dialogBlock">
                            <!--                    <div
                        class="item hand "
                        :class="{ active: askStatus == 2 }"
                        @click="searchPrescription(2)"
                    >
                        <div class="item1">县级三大医院问诊</div>
                    </div>-->
                            <div class="item hand" :class="{ active: askStatus == 1 }" @click="searchPrescription(1)">
                                <div class="item1">互联网医院问诊</div>
                                <!--                        <div class="item2" style="line-height: 80px">3215</div>-->
                                <!--            <div class="item3" style="line-height: 80px">15%</div>-->
                            </div>
                            <div class="item hand" :class="{ active: askStatus == 3 }" @click="changeAsk(3)">
                                <div class="item1">签约医生问诊</div>
                                <!--                        <div class="item2" style="line-height: 80px">287</div>-->
                                <!--            <div class="item3" style="line-height: 80px">15%</div>-->
                            </div>
                        </div>
                        <div class="biao" v-if="askStatus == 1">
                            <table class="listtable">
                                <tr class="listTitle">
                                    <td style="padding-left:20px">序号</td>
                                    <td class="tx-c">咨询人姓名</td>
                                    <td class="tx-c">年龄</td>
                                    <td>联系电话</td>
                                    <td class="tx-c">医生姓名</td>
                                    <td>医院名称</td>
                                    <td>诊断结果</td>
                                </tr>
                                <tr class="listData" v-for="(x, i) in prescriptionData.list" :key="i">
                                    <td style="padding-left:20px">{{ i + 1 }}</td>
                                    <td class="tx-c"><span class="wzname">{{ x.patientName }}</span></td>
                                    <td class="tx-c"><span class="wzname">{{ x.patientAge }}</span></td>
                                    <td class=""><span class="wzname">{{ mbl(x.patientPhone) }}</span></td>
                                    <td class="tx-c">{{ x.doctor }}</td>
                                    <td style="height: 114px">{{ x.hospital }}</td>
                                    <td>{{ x.diagnosis }}</td>
                                </tr>
                            </table>

                            <div class="pager pagecomont">
                                <pager :page="postPrescription.page" :total="prescriptionData.total"
                                    :page-size="postPrescription.count" @pagination="getPrescription" />
                            </div>
                        </div>
                        <div class="biao" v-if="askStatus == 2">
                            <table class="listtable">
                                <tr class="listTitle">
                                    <td style="padding-left:20px">序号</td>
                                    <td class="tx-c">咨询人姓名</td>
                                    <td class="tx-c">年龄</td>
                                    <td>联系电话</td>
                                    <td class="tx-c">医生姓名</td>
                                    <td>医院名称</td>
                                    <td>诊断结果</td>
                                </tr>
                                <tr class="listData" v-for="(x, i) in filterHostpital" :key="i">
                                    <td style="padding-left:20px">{{ x.a1 }}</td>
                                    <td class="tx-c"><span class="wzname">{{ x.a2 }}</span></td>
                                    <td class="tx-c"><span class="wzname">{{ x.a3 }}</span></td>
                                    <td><span class="wzname">{{ mbl(x.a4) }}</span></td>
                                    <td class="tx-c">{{ x.a5 }}</td>
                                    <td style="height: 114px">{{ x.a6 }}</td>
                                    <td>{{ x.a7 }}</td>
                                </tr>
                            </table>

                            <div class="pager pagecomont">
                                <pager :page="hostpitalPage.page" :total="hospitalList.length"
                                    :page-size="hostpitalPage.count" @pagination="getHostpitalPage" />
                            </div>
                        </div>
                        <div class="biao" v-if="askStatus == 3">
                            <table class="listtable">
                                <tr class="listTitle">
                                    <td style="width: 150px">姓名</td>
                                    <td>联系电话</td>
                                    <td>服务村</td>
                                    <td class="tx-c">问诊数</td>
                                </tr>
                                <tr class="listData" v-for="(x, i) in doctorData2">
                                    <td class="txt-c" style="height: 120px">{{ x.name }}</td>
                                    <td>{{ mbl(x.tel) }}</td>
                                    <td>{{ x.cun }}</td>
                                    <td>
                                        <div class="shuzi">{{ x.ren }}</div>
                                    </td>
                                </tr>
                            </table>

                            <div class="pager pagecomont">
                                <pager :page="postDoctor.page" :total="doctorData.total" :page-size="postDoctor.count"
                                    @pagination="getDoctor" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--药师咨询数-->
        <el-dialog :visible.sync="dialogYszx" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogYszx = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    药师咨询
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">药师咨询数</div>
                        <div class="item2" style="line-height: 80px">2739</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="width: 50px" class="tx-c">序号</td>
                            <td class="tx-c">执业药师姓名</td>
                            <td>执业药师身份证</td>
                            <td style="width: 500px">机构／部门</td>
                            <td class="tx-c">次数</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in pharmacistData.list">
                            <td class="tx-c" style="height: 110px">{{ i + 1 }}</td>
                            <td class="tx-c">{{ x.name }}</td>
                            <td>{{ sfz(x.idcard) }}</td>
                            <td>{{ x.storeName }}</td>
                            <td class="tx-c">{{ sjs(5, 20) }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postPharmacist.page" :total="pharmacistData.total"
                            :page-size="postPharmacist.count" @pagination="getPharmacist" />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--今日访客数-->
        <el-dialog :visible.sync="dialogJrfks" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJrfks = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    今日访客数
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">今日访客数</div>
                        <div class="item2" style="line-height: 80px">{{ JrfksData.total * 5 }}</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px;width: 45px">序号</td>
                            <td class="tx-c" style="width: 117px">访问时间</td>
                            <td v-if="!storeId">药店名称</td>
                            <td class="tx-c" style="width:90px">姓名</td>
                            <td style="width: 200px">身份证</td>
                            <td style="width:120px">手机号码</td>
                            <td class="tx-c" style="width:80px">温度</td>
                            <td class="tx-c" style="width:90px">健康码状态</td>
                            <td class="tx-c" style="width: 190px">最近核酸检测结果</td>
                            <td class="tx-c" style="width: 90px">行程码</td>
                            <td class="tx-c" style="width: 90px">疫苗接种</td>
                        </tr>
                    </table>

                    <div style="height:565px;overflow: hidden;">
                        <vue-seamless-scroll :data="JrfksData.list" :class-option="defaultOption">
                            <table class="listtable">
                                <tr class="listData" v-for="(x, i) in JrfksData.list" :key="i">
                                    <td style="padding-left:20px;width: 45px">{{ i + 1 }}</td>
                                    <td class="tx-c" style="width: 117px">{{ x.createDate }}</td>
                                    <td v-if="!storeId">{{ x.storeName }}</td>
                                    <td class="tx-c" style="width:90px">{{ x.name }}</td>
                                    <td style="width: 200px">{{ sfz(x.idcard) }}</td>
                                    <td style="width:120px">{{ mbl(x.phone) }}</td>
                                    <td class="tx-c" style="width:80px">{{ x.tem }}</td>
                                    <td class="tx-c jkm" style="width:90px">
                                        <span class="tagBtn" v-if="x.level == '绿码'"
                                            style="background-color: rgb(105 230 44)">{{
                                                x.level
                                            }}</span>
                                        <span class="tagBtn" v-if="x.level == '黄码'"
                                            style="background-color:  rgb(244 127 3)">{{
                                                x.level
                                            }}</span>
                                        <span class="tagBtn" v-if="x.level == '红码'"
                                            style="background-color: rgb(254 64 24)">{{
                                                x.level
                                            }}</span>
                                    </td>
                                    <td class="tx-c" style="width: 190px">
                                        {{ x.detect.split(" ")[0] }} {{ x.detect.split(" ")[1]
                                        }}<br />
                                        {{ x.detect.split(" ")[2] }}
                                    </td>
                                    <td class="tx-c" style="width: 90px">
                                        {{ ["已核验", "正常", "高风险", "无记录"][x.itinerary] }}
                                    </td>
                                    <td class="tx-c" style="width: 90px">{{ x.vaccine }}</td>
                                </tr>
                            </table>
                        </vue-seamless-scroll>
                    </div>
                    <!--                    <div class="pager">-->
                    <!--                        <pager-->
                    <!--                            :page="postJrfks.page"-->
                    <!--                            :total="JrfksData.total"-->
                    <!--                            :page-size="postJrfks.count"-->
                    <!--                            @pagination="getJrfks"-->
                    <!--                        />-->
                    <!--                    </div>-->
                </div>
            </div>
        </el-dialog>
        <!--从业人员异常-->
        <el-dialog :visible.sync="dialogCyry" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogCyry = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    从业人员异常
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">从业人员异常数</div>
                        <div class="item2" style="line-height: 80px">{{ CyryData.total }}</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td class="tx-c">姓名</td>
                            <td>身份证</td>
                            <td>手机号码</td>
                            <td class="tx-c">温度</td>
                            <td class="tx-c">健康码状态</td>
                            <td class="tx-c">最近核酸检测</td>
                            <td class="tx-c">行程码</td>
                            <td class="tx-c">疫苗接种</td>
                            <td class="tx-c">就职单位</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in CyryData.list">
                            <td class="tx-c">{{ x.name }}</td>
                            <td>{{ sfz(x.idcard) }}</td>
                            <td>{{ x.phone }}</td>
                            <td class="tx-c">{{ x.tem }}</td>
                            <td class="tx-c">{{ x.level }}</td>
                            <td class="tx-c">{{ x.detect }}</td>
                            <td class="tx-c">
                                {{ ["正常", "高风险", "经过高风险", "无记录"][x.itinerary] }}
                            </td>
                            <td class="tx-c">{{ x.vaccine }}</td>
                            <td style="height: 110px">{{ x.storeName }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postCyry.page" :total="CyryData.total" :page-size="postCyry.count"
                            @pagination="getCyry" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--从业人员核酸逾期列表-->
        <el-dialog :visible.sync="dialogCyryyc" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogCyryyc = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    从业人员核酸逾期
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">从业人员核酸逾期</div>
                        <div class="item2" style="line-height: 80px">
                            <soan v-if="!storeId">{{ CyryycData.total }}</soan>
                            <soan v-if="storeId">0</soan>
                        </div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>职员姓名</td>
                            <td class="tx-c">性别</td>
                            <td>手机号码</td>
                            <td>最近核酸检测时间</td>
                            <td>核酸结果</td>
                            <td>就职单位</td>
                            <td class="tx-c">备注</td>
                        </tr>
                        <tr v-if="!storeId" class="listData" v-for="(x, i) in CyryycData.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td style="height: 110px">{{ x.name }}</td>
                            <td class="tx-c">{{ x.sex }}</td>
                            <td>{{ sfz(x.phone) }}</td>
                            <td style="color: #E82A02">{{ x.detectTime }}</td>
                            <td>{{ x.detect }}</td>
                            <td>{{ x.storeName }}</td>
                            <td class="tx-c">休假</td>
                        </tr>
                    </table>

                    <div class="pager" v-if="!storeId">
                        <pager :page="postCyryyc.page" :total="CyryycData.total" :page-size="postCyryyc.count"
                            @pagination="getCyryyc" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--三级找药-->
        <el-dialog :visible.sync="dialogSanji" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogSanji = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    {{ ["未找到", "一级(县域)", "二级(市域)", "三级(省域)"][sanjiZt] }}
                </div>
                <div class="ThreeGradeTop">
                    <div class="item " v-if="sanjiZt == 1">
                        <div class="item1">一级(县域)</div>
                        <div class="item2" style="line-height: 80px">{{ yijiData.total }}</div>
                    </div>
                    <div class="item " v-if="sanjiZt == 2">
                        <div class="item1">二级(市域)</div>
                        <div class="item2" style="line-height: 80px">{{ find2Data2List.length }}</div>
                    </div>
                    <div class="item " v-if="sanjiZt == 3">
                        <div class="item1">三级(省域)</div>
                        <div class="item2" style="line-height: 80px">{{ find3Data.total }}</div>
                    </div>
                    <div class="item " v-if="sanjiZt == 0">
                        <div class="item1">未找到</div>
                        <div class="item2" style="line-height: 80px">{{ find0Data.total }}</div>
                    </div>
                </div>
                <div v-if="sanjiZt == 1" class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>找药人姓名</td>
                            <td>联系电话</td>
                            <td>药品名称</td>
                            <td>规格型号</td>
                            <td>单位</td>
                            <td>数量</td>
                            <td>接单药店名称</td>
                            <td>药店属地</td>
                            <td>配送方式</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in yijiData.list" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td>{{ x.patientName }}</td>
                            <td>{{ mbl(x.patientPhone) }}</td>
                            <td>{{ x.commonName }}</td>
                            <td>{{ x.spec }}</td>
                            <td>{{ x.unit }}</td>
                            <td>{{ x.quantity }}</td>
                            <td>{{ x.storeName }}</td>
                            <td>浦江</td>
                            <td>
                                <span v-if="x.quantity >= 3" style="color:rgb(251, 201, 2)">快递</span>
                                <span v-else style="color: #22f906">自提</span>
                            </td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postYiji.page" :total="yijiData.total" :page-size="postYiji.count"
                            @pagination="getPrescriptionYiji" />
                    </div>
                </div>
                <div v-if="sanjiZt == 2 || sanjiZt == 4" class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>找药人姓名</td>
                            <td>联系电话</td>
                            <td>药品名称</td>
                            <td>规格型号</td>
                            <td>单位</td>
                            <td>数量</td>
                            <td>接单药店名称</td>
                            <td>药店属地</td>
                            <td>配送方式</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in find2Data2" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td>{{ x.a1 }}</td>
                            <td>{{ mbl(x.a2) }}</td>
                            <td>{{ x.a3 }}</td>
                            <td>{{ x.a4 }}</td>
                            <td>{{ x.a5 }}</td>
                            <td>{{ x.a6 }}</td>
                            <td>{{ x.a7 }}</td>
                            <td>{{ x.a8 }}</td>
                            <td style="color: #fbc902">{{ x.a9 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postFind2.page" :total="find2Data2List.length" :page-size="postFind2.count"
                            @pagination="getFind2" />
                    </div>
                </div>
                <div v-if="sanjiZt == 3" class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>找药人姓名</td>
                            <td>联系电话</td>
                            <td>药品名称</td>
                            <td>规格型号</td>
                            <td>单位</td>
                            <td>数量</td>
                            <td>接单药店名称</td>
                            <td>药店属地</td>
                            <td>配送方式</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in find3Data2" :key="i" @click="openSan(i)">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td>{{ x.a1 }}</td>
                            <td>{{ mbl(x.a2) }}</td>
                            <td>{{ x.a3 }}</td>
                            <td>{{ x.a4 }}</td>
                            <td>{{ x.a5 }}</td>
                            <td>{{ x.a6 }}</td>
                            <td>{{ x.a7 }}</td>
                            <td>{{ x.a8 }}</td>
                            <td style="color: #fbc902">{{ x.a9 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postFind3.page" :total="find3Data.total" :page-size="postFind3.count"
                            @pagination="getFind3" />
                    </div>
                </div>
                <div v-if="sanjiZt == 0" class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>找药人姓名</td>
                            <td>联系电话</td>
                            <td>药品名称</td>
                            <td>状态</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in find0Data2" :key="i">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td>{{ x.a1 }}</td>
                            <td>{{ mbl(x.a2) }}</td>
                            <td>{{ x.a3 }}</td>
                            <td>{{ x.a4 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postFind0.page" :total="find0Data.total" :page-size="postFind0.count"
                            @pagination="getFind0" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--未处置-->
        <el-dialog :visible.sync="dialogWeicz" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogWeicz = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    未处置
                </div>
                <div class="dialogBlock">
                    <div class="item ">
                        <div class="item1">药师咨询数</div>
                        <div class="item2" style="line-height: 80px">46</div>
                    </div>
                    <div class="item ">
                        <div class="item1">互联网医院问诊量</div>
                        <div class="item2" style="line-height: 80px">46</div>
                        <div class="item3" style="line-height: 80px">15%</div>
                    </div>
                    <div class="item ">
                        <div class="item1">签约医生问诊量</div>
                        <div class="item2" style="line-height: 80px">46</div>
                        <div class="item3" style="line-height: 80px">15%</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>咨询人姓名</td>
                            <td>年龄</td>
                            <td>联系电话</td>
                            <td>咨询时间</td>
                            <td>药师姓名</td>
                            <td>执业单位</td>
                        </tr>
                        <!--            <tr class="listData dabiaozi" :class="{ban:i%2==1}" v-for="(x,i) in storeData.list">-->
                        <!--              <td style="height: 116px">{{i}}</td>-->
                        <!--              <td>{{x.code}}</td>-->
                        <!--              <td>{{x.contact}}</td>-->
                        <!--              <td>{{mbl(x.phone)}}</td>-->
                        <!--              <td>{{x.address}}</td>-->
                        <!--              <td>{{x.manageCode}}</td>-->

                        <!--            </tr>-->
                    </table>

                    <div class="pager">
                        <pager :page="postStore.page" :total="storeData.total" :page-size="postStore.count"
                            @pagination="getStore" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--重点监管企业-->
        <el-dialog :visible.sync="dialogJgqy" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJgqy = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    重点监管药店
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>药店名称</td>
                            <td>负责人</td>
                            <td>联系电话</td>
                            <td>当前等级</td>
                            <td>当前评分</td>
                            <td>所属辖区</td>
                            <td>当前监管频次</td>
                        </tr>
                        <!--            <tr class="listData dabiaozi" :class="{ban:i%2==1}" v-for="(x,i) in storeData.list">-->
                        <!--              <td style="height: 116px">{{i}}</td>-->
                        <!--              <td>{{x.code}}</td>-->
                        <!--              <td>{{x.contact}}</td>-->
                        <!--              <td>{{mbl(x.phone)}}</td>-->
                        <!--              <td>{{x.address}}</td>-->
                        <!--              <td>{{x.manageCode}}</td>-->

                        <!--            </tr>-->
                    </table>

                    <div class="pager">
                        <pager :page="postStore.page" :total="storeData.total" :page-size="postStore.count"
                            @pagination="getStore" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--访客异常-->
        <el-dialog :visible.sync="dialogFyyc" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogFyyc = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    站点分布
                </div>

                <div class="biao" style="margin-top: 50px">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>药店名称</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in visitorList" :key="i">
                            <td style="height: 110px">{{ x.name }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="visitorpage.page" :total="visitorpage.total" :page-size="visitorpage.count"
                            @pagination="getFyyc" />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--通用型对话框----------->
        <el-dialog :visible.sync="dialogCommon" width="1600px" top="5vh" @close="closed" destroy-on-close>
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogCommon = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    {{ pageTitle }}
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td v-for="x in arrTitle">{{ x.label }}</td>
                            <td v-if="pageTitle == '不合格药品示警'">提醒人次</td>
                            <td v-if="pageTitle == '不合格药品示警'">操作</td>
                        </tr>
                        <tr class="listData" v-for="x in arrList">
                            <td style="height: 125px" v-for="y in arrTitle">
                                <span v-if="pageTitle == '过期药品示警' && y.prop == 'a7'">{{ formatPhone(x[y.prop]) }}</span>
                                <span v-else-if="pageTitle == '服用提醒' && y.prop == 'a3'">{{ formatPhone(x[y.prop]) }}</span>
                                <span v-else-if="pageTitle == '不合格药品示警' && y.prop == 'a7'">{{ formatPhone(x[y.prop]) }}</span>
                                <span v-else-if="pageTitle == '药品近效期提醒' && y.prop == 'a7'">{{ formatPhone(x[y.prop]) }}</span>
                                <span v-else>{{ x[y.prop] }}</span>
                            </td>
                            <td v-if="pageTitle == '不合格药品示警'">
                                <div>{{ x.a9 }}人</div>
                            </td>
                            <td v-if="pageTitle == '不合格药品示警'">
                                <span @click="bhgRenFun(x.a9)" class="btn" style="text-align: center">查看</span>
                            </td>
                        </tr>
                    </table>
                    <div class="pager">
                        <pager :page="postCommon.page" :total="rawList.length" :page-size="postCommon.count"
                            @pagination="getCommon" />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--      查看对象-->
        <el-dialog :visible.sync="dialogDuixiang" width="1600px" top="5vh" @close="closed" destroy-on-close>
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogDuixiang = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="text-align: center">序号</td>
                            <td>提醒对象</td>
                            <td>联系电话</td>
                            <td>内容</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in bhgRen2">
                            <td style="text-align: center">{{ i + 1 }}</td>
                            <td>
                                <div>{{ x.a2 }}</div>
                            </td>
                            <td>{{ mbl(x.a4) }}
                            </td>
                            <td>该药品随机抽样不合格，请勿服用！
                            </td>
                        </tr>
                    </table>
                    <div class="pager">
                        <pager :page="postBhgRen.page" :total="bhgRen.length" :page-size="postBhgRen.count"
                            @pagination="getBhgRen" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--一点一档进销存查询----------->
        <el-dialog :visible.sync="dialogInquire" width="1600px" top="5vh" @close="closed" destroy-on-close
            @open="openInquireDialog">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogInquire = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    进销存查询
                </div>
                <div id="inquire">
                    <div class="inquire-title " @click="checkSelect(1)" :class="{ active: currentIndex == 1 }">
                        <span>库存记录</span>
                    </div>
                    <div class="inquire-title" @click="checkSelect(2)" :class="{ active: currentIndex == 2 }">
                        <span>购进记录</span>
                    </div>
                    <div class="inquire-title" @click="checkSelect(3)" :class="{ active: currentIndex == 3 }">
                        <span>销售记录</span>
                    </div>
                </div>
                <div class="flex saleSearch inquire-store" v-if="currentIndex == 1">
                    <div class="label">药品名称</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.goodsName" clearable></el-input>
                    </div>
                    <div class="label">生产厂家</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.company" clearable></el-input>
                    </div>
                    <div class="label">批准文号</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.approval" clearable></el-input>
                    </div>
                    <div class="item flex" style="align-items: center">
                        <div @click="searchInquire" class="btnSearch">
                            <img src="../assets/smart/home/s.png" />
                        </div>
                    </div>
                </div>
                <div class="flex saleSearch inquire-store" v-if="currentIndex == 2">
                    <div class="label">药品名称</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.goodsName" clearable></el-input>
                    </div>
                    <div class="label">生产厂家</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.company" clearable></el-input>
                    </div>

                    <div class="item">
                        <el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始时间" end-placeholder="结束时间">
                        </el-date-picker>
                    </div>
                    <div class="item flex" style="align-items: center">
                        <div @click="searchbuyning" class="btnSearch">
                            <img src="../assets/smart/home/s.png" />
                        </div>
                    </div>
                </div>
                <div class="flex saleSearch inquire-store" v-if="currentIndex == 3">
                    <div class="label">药品名称</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.goodsName" clearable></el-input>
                    </div>
                    <div class="label">生产厂家</div>
                    <div class="item">
                        <el-input v-model.trim="inquireList.company" clearable></el-input>
                    </div>

                    <div class="item">
                        <el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始时间" end-placeholder="结束时间">
                        </el-date-picker>
                    </div>
                    <div class="item flex" style="align-items: center">
                        <div @click="searchSaleList" class="btnSearch">
                            <img src="../assets/smart/home/s.png" />
                        </div>
                    </div>
                </div>
                <div class="biao" v-if="currentIndex == 1">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>商品编码</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td>单位</td>
                            <td>生产企业</td>
                            <td>批准文号</td>
                            <td>有效期至</td>
                        </tr>
                        <tr class="listData" v-for="(item, index) in serchList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.drugCode }}</td>
                            <td>{{ item.goodsName }}</td>
                            <td>{{ item.defaultName }}</td>
                            <td>{{ item.spec }}</td>
                            <td>{{ item.unit }}</td>
                            <td>{{ item.company }}</td>
                            <td>{{ item.approval }}</td>
                            <td>{{ item.validDate }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="inquirePage.page" :total="inquirePage.total" :page-size="inquirePage.count"
                            @pagination="getInquire" />
                    </div>
                </div>
                <div class="biao" v-if="currentIndex == 2">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td>单位</td>
                            <td>生产企业</td>
                            <td>批准文号</td>
                            <td>批号</td>
                            <td>有效期至</td>
                            <td>到货数量</td>
                            <td>到货日期</td>
                        </tr>
                        <tr class="listData" v-for="(item, index) in serchList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.goodsName }}</td>
                            <td>{{ item.defaultName }}</td>
                            <td>{{ item.spec }}</td>
                            <td>{{ item.unit }}</td>
                            <td>{{ item.company }}</td>
                            <td>{{ item.approval }}</td>
                            <td>{{ item.lot }}</td>
                            <td>{{ item.validDate }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.updateDate }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="inquirePage.page" :total="inquirePage.total" :page-size="inquirePage.count"
                            @pagination="getBuyning" />
                    </div>
                </div>
                <div class="biao" v-if="currentIndex == 3">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>订单号</td>
                            <td>商品名</td>
                            <td>通用名称</td>
                            <td>规格</td>
                            <td>数量</td>
                            <td>单位</td>
                            <td>生产企业</td>
                            <td>批准文号</td>
                            <td>批号</td>
                            <td>有效期至</td>
                            <td>销售日期</td>
                        </tr>
                        <tr class="listData" v-for="(item, index) in serchList" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.orderSn }}</td>
                            <td>{{ item.goodsName }}</td>
                            <td>{{ item.commonName }}</td>
                            <td>{{ item.spec }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.unit }}</td>
                            <td>{{ item.company }}</td>
                            <td>{{ item.approval }}</td>
                            <td>{{ item.lot }}</td>
                            <td>{{ item.validDate }}</td>
                            <td>{{ item.updateDate }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="inquirePage.page" :total="inquirePage.total" :page-size="inquirePage.count"
                            @pagination="salePage" />
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--单点管控调整-->
        <el-dialog :visible.sync="dialogDandian" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogDandian = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    单点管控调整
                </div>
                <div class="dandian">
                    <div class="item">
                        <div class="item1">当前管控状态</div>
                        <div class="item2">药品禁售▶</div>
                    </div>
                    <div class="item">
                        <div class="item1">调整管控状态</div>
                        <div class="item2">添加到白名单▶</div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--药店上报信息-->
        <el-dialog :visible.sync="dialogShangbao" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogShangbao = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    药店上报信息
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>药店名称</td>
                            <td>用户名</td>
                            <td>图片</td>
                            <td>内容</td>
                            <td>状态</td>
                            <td>完成时间</td>
                            <td>完成人</td>
                            <td>上报时间</td>
                        </tr>
                        <tr class="listData">
                            <td style="height: 110px">{{ dataShangbao.storeName }}</td>
                            <td class="tx-c">{{ dataShangbao.memberName }}</td>
                            <td>
                                <div v-if="dataShangbao.pic">
                                    <el-image style="width: 100%; height: 100px" :src="dataShangbao.pic[0]"
                                        :preview-src-list="dataShangbao.pic">
                                        <div slot="error" class="image-slot">

                                        </div>
                                    </el-image>
                                </div>
                            </td>
                            <td>{{ dataShangbao.content }}</td>
                            <td>
                                <span v-if="dataShangbao.status == 10">待处理</span>
                                <span v-if="dataShangbao.status == 20">审核通过</span>
                            </td>
                            <td>
                                <div style="width: 110px">{{ dataShangbao.completeTime }}</div>
                            </td>
                            <td>
                                {{ dataShangbao.completeName }}
                            </td>
                            <td>
                                <div style="width: 110px">{{ dataShangbao.createDate }}</div>
                            </td>
                        </tr>
                    </table>


                </div>
            </div>
        </el-dialog>
        <!--药店分级-->
        <el-dialog :visible.sync="dialoglevel" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialoglevel = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>药店动态评级</span>
                </div>
                <div class="biao">
                    <div class="huizong">
                        <div class="btnHui flex_vc level_vc">汇总数据：{{ dtpjData.total }}</div>
                        <div class="btnHui flex_vc level_vc">占比：{{ perHui }}%</div>
                    </div>
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="">药店名称</td>
                            <td>门头照</td>
                            <td class="tx-c">上年度信用等级</td>
                            <td class="tx-c">当前信用得分</td>
                            <td class="tx-c">当前监管风险等级</td>
                        </tr>
                        <tr class="listData" v-for="x in dtpjData.list">
                            <td style="height: 56px">
                                <div @click="go(x)">{{ x.storeName }}</div>
                            </td>
                            <td><el-image style="width: 50px; height: 50px" :src="x.avatar"
                                    :preview-src-list="[x.avatar]">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline" />
                                    </div>
                                </el-image></td>
                            <td>
                                <div class="tx-c score">{{ x.lastYearScore }}</div>
                            </td>
                            <td>
                                <div class="tx-c score" @click="openKoufen(x.storeName)"><span
                                        :class="{ yuan100: x.score == 100 }">{{ x.score }}</span></div>
                            </td>
                            <td>
                                <div class="tx-c levelA" v-if="x.level == 'A'">{{ x.level }}</div>
                                <div class="tx-c levelB" v-if="x.level == 'B'">{{ x.level }}</div>
                                <div class="tx-c levelC" v-if="x.level == 'C'">{{ x.level }}</div>
                                <div class="tx-c levelD" v-if="x.level == 'D'">{{ x.level }}</div>
                            </td>
                        </tr>
                    </table>

                    <div style="color: white;line-height: 300px;text-align: center" v-if="dtpjData.total == 0">
                        暂无数据
                    </div>
                    <div class="pager" v-if="dtpjData.total > 0">
                        <pager :page="postDtpj.page" :total="dtpjData.total" :page-size="postDtpj.count"
                            @pagination="getDtpj" />
                    </div>
                </div>
                <div class="khpj1" v-if="postDtpj.level == 'A'"><img src="../assets/yun/dialog/yyf.png" /> 上线云药房<img
                        src="../assets/yun/dialog/sfd1.png" />“好商风”贷款<img src="../assets/yun/dialog/sfd2.png" />监管无事不扰
                </div>
                <div class="khpj2" v-if="postDtpj.level == 'D'"><img src="../assets/yun/dialog/qxyb1.png" />重点监管<img
                        src="../assets/yun/dialog/qxyb2.png" />暂停医保<img src="../assets/yun/dialog/qxyb3.png" />下线云药房<img
                        src="../assets/yun/dialog/sfd1-2.png" />暂停好商风贷</div>
            </div>
        </el-dialog>
        <!--加分审核-->
        <el-dialog :visible.sync="dialogAddAudit" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogAddAudit = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    <span>加分审核</span>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="">药店名称</td>
                            <td>申请人</td>
                            <td class="tx-c">加分项</td>
                            <td class="tx-c">分值</td>
                            <td class="tx-c">备注内容</td>
                            <td class="tx-c">附件</td>
                            <td class="tx-c">申请时间</td>
                            <td class="tx-c" style="width: 120px">操作</td>
                        </tr>
                        <tr class="listData" v-for="x in AuditList">
                            <td style="height: 56px">
                                {{ x.storeName }}
                            </td>
                            <td>
                                {{ x.memberName }}
                            </td>
                            <td>
                                {{ x.reason }}
                            </td>
                            <td>
                                {{ x.score }}
                            </td>
                            <td>
                                {{ x.remark }}
                            </td>
                            <td>
                                <el-link type="primary" :href="x.url" target="_blank">查看附件</el-link>
                            </td>
                            <td>
                                <div style="width: 109px">{{ x.createDate }}</div>
                            </td>
                            <td>
                                <div style="display: flex;justify-content: center">
                                    <div @click="AddAudit(x, 20)" class="btnSearch" style="margin-right: 20px">通过</div>
                                    <div @click="AddAudit(x, 30)" class="btnSearch">拒绝</div>
                                </div>

                            </td>
                        </tr>
                    </table>

                    <div style="color: white;line-height: 300px;text-align: center" v-if="postAddAudit.total == 0">
                        暂无数据
                    </div>
                    <div class="pager" v-if="AuditList.length > 0">
                        <pager :page="postAddAudit.page" :total="postAddAudit.total" :page-size="postAddAudit.count"
                            @pagination="getAddAudit" />
                    </div>
                </div>

            </div>
        </el-dialog>
        <!--药店从业人员数量-->
        <el-dialog :visible.sync="dialogcongye" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogcongye = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    药店从业人员数量
                </div>
                <div class="ThreeGradeTop">
                    <div class="item ">
                        <div class="item1">药店从业人员数量</div>
                        <div class="item2" style="line-height: 80px">{{ employeeList.total }}</div>
                    </div>
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px;width: 45px">序号</td>
                            <td class="tx-c" style="width: 117px">访问时间</td>
                            <td v-if="!storeId">药店名称</td>
                            <td class="tx-c" style="width:90px">姓名</td>
                            <td style="width: 200px">身份证</td>
                            <td style="width:120px">手机号码</td>
                            <td class="tx-c" style="width:90px">健康码状态</td>
                            <td class="tx-c" style="width: 90px">行程码</td>
                        </tr>
                    </table>

                    <div style="height:565px;overflow: hidden;">
                        <vue-seamless-scroll :data="employeeList.list" :class-option="defaultOption">
                            <table class="listtable">
                                <tr class="listData" v-for="(x, i) in employeeList.list" :key="i">
                                    <td style="padding-left:20px;width: 45px">{{ i + 1 }}</td>
                                    <td class="tx-c" style="width: 117px">{{ x.createDate }}</td>
                                    <td v-if="!storeId">{{ x.storeName }}</td>
                                    <td class="tx-c" style="width:90px">{{ x.name }}</td>
                                    <td style="width: 200px">{{ sfz(x.idcard) }}</td>
                                    <td style="width:120px">{{ mbl(x.phone) }}</td>
                                    <td class="tx-c jkm" style="width:90px">
                                        <span class="tagBtn" style="background-color: rgb(105 230 44)">绿码</span>
                                    </td>
                                    <td class="tx-c" style="width: 90px">
                                        正常
                                    </td>
                                </tr>
                            </table>
                        </vue-seamless-scroll>
                    </div>
                    <!--                    <div class="pager">-->
                    <!--                        <pager-->
                    <!--                            :page="postJrfks.page"-->
                    <!--                            :total="JrfksData.total"-->
                    <!--                            :page-size="postJrfks.count"-->
                    <!--                            @pagination="getJrfks"-->
                    <!--                        />-->
                    <!--                    </div>-->
                </div>
            </div>
        </el-dialog>
        <!--图表展示-->
        <el-dialog :visible.sync="dialogshowImg" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogshowImg = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>

                <div class="biao" style="position: relative">
                    <div class="abs shijian" style="top:287px;left:63px;">{{ fxcz1 }} 21:57:54</div>
                    <div class="abs shijian" style="top:287px;left:363px;">{{ fxcz1 }} 22:58:09</div>
                    <div class="abs shijian" style="top:287px;left:664px;">{{ fxcz1 }} 23:58:59</div>
                    <div class="abs shijian" style="top:287px;left:961px;">{{ fxcz2 }} 08:35:10</div>
                    <div class="abs shijian" style="top:287px;left:1261px;">{{ fxcz2 }} 08:50:15</div>
                    <div class="abs shijian" style="top:514px;left:1261px;color:#fff">{{ fxcz2 }} 08:52:26</div>
                    <el-image :src="showimgList"></el-image>
                </div>
            </div>
        </el-dialog>


        <!--检查记录-->
        <el-dialog :visible.sync="dialogJiancha" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJiancha = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    检查记录
                </div>
                <div class="" style="">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="">检查编号</td>
                            <td>预警单位</td>
                            <td>负责人</td>
                            <td>电话</td>
                            <td>检查内容</td>
                            <td style="width: 100px">完成时间</td>
                            <td>执法类型</td>
                            <td>本次积分</td>
                            <td>累计积分</td>
                        </tr>
                        <tr class="listData">
                            <td style="height: 116px">1657137600148
                            </td>
                            <td>浙江康佰家大药房有限公司浦江月泉西路店
                            </td>
                            <td>杨怡星
                            </td>
                            <td>{{ mbl('18539917829') }}</td>
                            <td>药品未按规范摆放
                            </td>
                            <td>2022/7/10 14:35
                            </td>
                            <td>现场检查
                            </td>
                            <td>-5
                            </td>
                            <td>95
                            </td>
                        </tr>
                        <tr class="listData">
                            <td style="height: 116px">1657195200259
                            </td>
                            <td>金华市太和堂医药浦江岩头连锁店
                            </td>
                            <td>金东芳
                            </td>
                            <td>{{ mbl('15258952453') }}</td>
                            <td>未按防疫要求执行
                            </td>
                            <td>2022/7/8 17:37

                            </td>
                            <td>现场检查
                            </td>
                            <td>-10
                            </td>
                            <td>90
                            </td>
                        </tr>
                        <tr class="listData">
                            <td style="height: 116px">1656071547797
                            </td>
                            <td>浙江诚心大药房连锁有限公司浦江白马店
                            </td>
                            <td>许书豪
                            </td>
                            <td>{{ mbl('13625857762') }}</td>
                            <td>药品未按分类存储
                            </td>
                            <td>2022/7/6 17:07
                            </td>
                            <td>现场检查
                            </td>
                            <td>-10
                            </td>
                            <td>90
                            </td>
                        </tr>
                    </table>
                    <!--            <div class="pager" >
              <pager
                  :page="postJiancha.page"
                  :total="JianchaData.total"
                  :page-size="postJiancha.count"
                  @pagination="getJiancha"
              />
            </div>-->
                </div>
            </div>
        </el-dialog>
        <!--群众举报-->
        <el-dialog :visible.sync="dialogJubao" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogJubao = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    群众举报
                </div>
                <div class="" style="">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="">序号</td>
                            <td>举报登记时间</td>
                            <td>被举报对象</td>
                            <td>类型</td>
                            <td>内容</td>
                            <td>初审意见</td>
                            <td>初审时间</td>
                            <td>检查部门</td>
                            <td>检查人员</td>
                            <td>检查时间</td>
                            <td>当前状态</td>
                            <td>操作</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in JubaoData2">
                            <td style="height: 120px">{{ i + 1 }}
                            </td>
                            <td>{{ x.a1 }}
                            </td>
                            <td>{{ x.a2 }}
                            </td>
                            <td>{{ x.a3 }}</td>
                            <td>{{ x.a4 }}
                            </td>
                            <td>{{ x.a5 }}
                            </td>
                            <td>{{ x.a6 }}
                            </td>
                            <td>{{ x.a7 }}
                            </td>
                            <td>{{ x.a8 }} </td>
                            <td>{{ x.a9 }} </td>
                            <td>{{ x.a10 }}</td>
                            <td><span @click="openImg(i)" class="btn nowrap">查看</span> </td>
                        </tr>

                    </table>
                    <div class="pager">
                        <pager :page="postJubao.page" :total="JubaoData.total" :page-size="postJubao.count"
                            @pagination="getJubao" />
                    </div>
                </div>
            </div>
        </el-dialog>
        <!--图片展示-->
        <el-dialog :visible.sync="dialogImg" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogImg = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>

                <div class="biao" style="position: relative">
                    <div style="position:absolute;z-index: 2;top:441px;left:661px">
                        <el-image
                            :preview-src-list="[require('@/assets/yun/dialog/xc1.jpg'), require('@/assets/yun/dialog/xc2.jpg'), require('@/assets/yun/dialog/xc3.jpg'), require('@/assets/yun/dialog/xc4.jpg')]"
                            style="width: 100px;height: 50px" :src="require('@/assets/yun/dialog/xc1.jpg')"></el-image>
                    </div>
                    <div style="position:absolute;z-index: 2;top:441px;left:959px">
                        <el-image :preview-src-list="[require('@/assets/yun/dialog/1.jpg')]"
                            style="width: 100px;height: 50px" :src="require('@/assets/yun/dialog/1.jpg')"></el-image>
                    </div>
                    <div style="position:absolute;z-index: 2;top:441px;left:1261px">
                        <el-image :preview-src-list="cfjds" style="width: 100px;height: 50px"
                            :src="cfjds[0]"></el-image>
                    </div>
                    <el-image v-if="qzjb == 1" :src="require('@/assets/yun/dialog/qzjb.png')"></el-image>
                    <el-image v-if="qzjb == 2" :src="require('@/assets/yun/dialog/qzjb2.png')"></el-image>
                </div>
            </div>
        </el-dialog>

        <!--图片展示-->
        <el-dialog :visible.sync="dialogImgCommon" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogImgCommon = false" class="btnBack hand" style="padding-right: 50px">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>

                <div class="biao" style="position: relative">
                    <el-image :src="ImgCommon"></el-image>
                </div>
            </div>
        </el-dialog>
        <!--公安预警-->
        <el-dialog :visible.sync="gayjDialog" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="gayjDialog = false" class="btnBack hand" style="padding-right: 50px">
                        <img :src="closeImg" />
                    </div>
                    公安
                </div>

                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>序号</td>
                            <td>预警编号</td>
                            <td>预警内容</td>
                            <td>预警时间</td>
                            <td>操作</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in gayjData">
                            <td style="padding-left:20px">{{ i + 1 }}</td>
                            <td>{{ x.yjbh }}</td>
                            <td>{{ x.yjnr }}</td>
                            <td>{{ x.yjsj }}</td>
                            <td><span class="btn" @click="openGa(i)">查看</span></td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--公安详情-->
        <el-dialog :visible.sync="gayjxDialog" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div class="drug-control-header-back" @click="$router.push('/ppt?type=12')"
                        style="left:1304px;top:30px">
                        <img src="../assets/smart/home/dialog2.png" alt="" />
                        <span>返回PPT</span>
                    </div>
                    <!-- <div style="position: absolute;left: 0;">返回PPT</div> -->
                    <div @click="gayjxDialog = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>

                <div class="biao" style="position: relative">
                    <template v-if="gaIndex == 1">
                        <div style="position: absolute;left:1299px;top:402px;z-index: 2">
                            <el-image :src="chz" :preview-src-list="[chz]" style="width: 87px;"></el-image>
                        </div>
                        <el-image :src="gaImg1[0]"></el-image>
                    </template>
                    <template v-if="gaIndex == 0">
                        <div class="absTu" style="top:476px;left:1196px">
                            <el-image :preview-src-list="gafkImg" style="width: 100px;height: 50px"
                                :src="gafkImg[0]"></el-image>
                        </div>
                        <el-image :src="gaImg2[0]"></el-image>
                    </template>
                </div>
            </div>
        </el-dialog>
        <!--联动处置查看-->
        <el-dialog :visible.sync="ldczDialog" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div class="home" @click="open('/')">
                        <img src="../assets/yun/home/home.png" alt="" />
                        <span>返回驾驶舱</span>
                    </div>
                    <div @click="ldczDialog = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>

                <div class="biao" style="position: relative">
                    <div class="absTu" style="top:340px;left:543px">
                        <el-image :preview-src-list="ldczImg2" style="width: 100px;height: 40px"
                            :src="ldczImg2[0]"></el-image>
                    </div>
                    <div class="absTu" style="top:408px;left:543px">
                        <el-image :preview-src-list="ldczImg3" style="width: 100px;height: 40px"
                            :src="ldczImg3[0]"></el-image>
                    </div>
                    <div class="absTu" style="top:600px;left:543px">
                        <el-image :preview-src-list="ldczImg4" style="width: 100px;height: 40px"
                            :src="ldczImg4[0]"></el-image>
                    </div>
                    <div class="absTu" style="top:514px;left:796px">
                        <el-image :preview-src-list="ldczImg5" style="width: 100px;height: 40px"
                            :src="ldczImg5[0]"></el-image>
                    </div>
                    <div class="absTu" style="top:514px;left:1048px">
                        <el-image :preview-src-list="cfjdsImg" style="width: 100px;height: 40px"
                            :src="cfjdsImg[0]"></el-image>
                    </div>
                    <div class="absTu" style="top:316px;left:1298px">
                        <el-image :preview-src-list="gafkImg" style="width: 100px;height: 40px"
                            :src="gafkImg[0]"></el-image>
                    </div>
                    <el-image :src="ldczImg[0]"></el-image>
                </div>
            </div>
        </el-dialog>

        <!--药店经营许可证-->
        <el-dialog :visible.sync="jyxkzDialog" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div class="home" @click="open('/')" style="right: 103px">
                        <img src="../assets/yun/home/home.png" alt="" />
                        <span>返回驾驶舱</span>
                    </div>
                    <div @click="jyxkzDialog = false" class="btnBack hand" style="padding-right: 50px">
                        <img :src="closeImg" />
                    </div>
                    药店经营许可证
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>证照类型</td>
                            <td>证件号</td>
                            <td>有效期</td>
                            <td>法人代表</td>
                            <td>注册地址</td>
                            <td>图片</td>
                            <td>过期预警</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in jyxkzData">
                            <td>{{ x.ctype }}</td>
                            <td>{{ x.code }}</td>
                            <td>{{ x.validDate }}</td>
                            <td>{{ x.legalName }}</td>
                            <td>{{ x.address }}</td>
                            <td><el-image style="width: 50px; height: 50px" :src="x.photo"
                                    :preview-src-list="[x.photo]">
                                </el-image></td>
                            <td>正常</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--执业药师/药师许可证-->
        <el-dialog :visible.sync="ysxkzDialog" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div class="home" @click="open('/')" style="right: 103px">
                        <img src="../assets/yun/home/home.png" alt="" />
                        <span>返回驾驶舱</span>
                    </div>
                    <div @click="ysxkzDialog = false" class="btnBack hand" style="padding-right: 50px">
                        <img :src="closeImg" />
                    </div>
                    执业药师证
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>姓名</td>
                            <td>注册证编号</td>
                            <td>资格证书编号</td>
                            <td>执业类型</td>
                            <td>有效期</td>
                            <td>图片</td>
                            <td>过期预警</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in ysxkzData">
                            <td>{{ x.name }}</td>
                            <td>{{ x.registerCode }}</td>
                            <td>{{ x.code }}</td>
                            <td>{{ x.category }}</td>
                            <td>{{ x.validDate.split(" ")[0] }}</td>
                            <td><el-image style="width: 50px; height: 50px" :src="x.image"
                                    :preview-src-list="[x.image]">
                                </el-image></td>
                            <td>正常</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--健康证-->
        <el-dialog :visible.sync="jkzDialog" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div class="home" @click="open('/')" style="right: 103px">
                        <img src="../assets/yun/home/home.png" alt="" />
                        <span>返回驾驶舱</span>
                    </div>
                    <div @click="jkzDialog = false" class="btnBack hand" style="padding-right: 50px">
                        <img :src="closeImg" />
                    </div>
                    从业人员健康证
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td>姓名</td>
                            <td>编号</td>
                            <td>手机号</td>
                            <td>身份照号</td>
                            <td>图片</td>
                            <td>有效期</td>
                            <td>过期预警</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in jkzData">
                            <td>{{ x.name }}</td>
                            <td>{{ x.number }}</td>
                            <td>{{ x.phone }}</td>
                            <td>{{ x.idcard }}</td>
                            <td><el-image style="width: 50px; height: 50px" :src="x.image"
                                    :preview-src-list="[x.image]">
                                </el-image></td>
                            <td>{{ x.validDate.split(" ")[0] }}</td>
                            <td>正常</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
        <!--预警详情图片展示-->
        <el-dialog :visible.sync="dialogYjDetail" width="1600px" top="5vh">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogYjDetail = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    查看
                </div>

                <div class="biao" style="position: relative">
                    <div style="position:absolute;z-index: 2;top:76px;left:147px;font-size: 30px;color: #fff">
                        {{ YjDetail.id }}
                    </div>
                    <div style="position:absolute;z-index: 2;top:71px;left:731px;font-size: 30px;color: #fff">
                        {{ YjDetail.storeName }}
                    </div>
                    <div style="position:absolute;z-index: 2;top:360px;left:286px;font-size: 21px;color:#04d5ff">
                        {{ YjDetail.createDate }}
                    </div>
                    <div style="position:absolute;z-index: 2;top:360px;left:583px;font-size: 21px;color: #04d5ff">
                        {{ YjDetail.createDate }}
                    </div>
                    <div style="position:absolute;z-index: 2;top:360px;left:884px;font-size: 21px;color: #04d5ff">
                        <span v-if="intervalTime(YjDetail.createDate)">
                            {{ wcsj(YjDetail.createDate) }}
                        </span>

                    </div>
                    <!--           <div style="position:absolute;z-index: 2;top:441px;left:661px">
             <el-image :preview-src-list="[require('@/assets/yun/dialog/xc1.jpg'),require('@/assets/yun/dialog/xc2.jpg'),require('@/assets/yun/dialog/xc3.jpg'),require('@/assets/yun/dialog/xc4.jpg')]" style="width: 100px;height: 50px" :src="require('@/assets/yun/dialog/xc1.jpg')"></el-image></div>-->
                    <el-image v-if="intervalTime(YjDetail.createDate)"
                        :src="require('@/assets/yun/dialog/jryj1.jpg')"></el-image>
                    <el-image v-else :src="require('@/assets/yun/dialog/jryj2.gif')"></el-image>
                </div>
            </div>
        </el-dialog>
        <!--管控药品-->
        <el-dialog :visible.sync="dialogGkyp" width="1600px" top="5vh" @close="closed" :destroy-on-close="true">
            <div class="tanWin">
                <div class="tanTitle">
                    <div @click="dialogGkyp = false" class="btnBack hand">
                        <img :src="closeImg" />
                    </div>
                    管控药品
                </div>
                <div class="biao">
                    <table class="listtable">
                        <tr class="listTitle">
                            <td style="padding-left:20px">序号</td>
                            <td>通用名</td>
                            <td>剂型</td>
                        </tr>
                        <tr class="listData" v-for="(x, i) in GkypData2">
                            <td style="padding-left:20px">{{ x.a1 }}</td>
                            <td style="height: 120px">{{ x.a2 }}</td>
                            <td>{{ x.a3 }}</td>
                        </tr>
                    </table>

                    <div class="pager">
                        <pager :page="postGkyp.page" :total="GkypData.total" :page-size="postGkyp.count"
                            @pagination="getGkyp" />
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<style lang="less" scoped>
.home {
    cursor: pointer;
    position: absolute;
    right: 62px;
    top: 29px;
    transform: translateY(-50%);

    span {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00d7fe;
        line-height: 26px;
        margin: 0 30px 0 10px;
    }

    img {
        width: 23px;
        height: 19px;
    }
}

.wzname {
    color: #FFf
}

.wxf {
    color: #FFA500;
}

.shijian {
    z-index: 2;
    color: #03d3fb;
    font-size: 16px;
}

.absTu {
    position: absolute;
    z-index: 2;
}

.ControlSayContent {
    font-size: 30px;
    color: #fff;
    line-height: 150%;
    width: 600px;
    margin: 0 auto;
    margin-top: 20px
}

.score {
    font-size: 30px
}

.levelA {
    text-shadow: 2px 2px 0px #1483ff;
    color: #0635E6 !important;
    font-size: 50px !important;
}

.levelB {
    text-shadow: 2px 2px 0px #dcffd8;
    color: #22F906 !important;
    font-size: 50px !important;
}

.levelC {
    text-shadow: 2px 2px 0px #fff6a5;
    color: #FEE610 !important;
    font-size: 50px !important;
}

.levelD {
    text-shadow: 2px 2px 0px #ff8d8d;
    color: #F90606 !important;
    font-size: 50px !important;
}

.yuan100 {
    border-radius: 50%;
    border: 2px solid #E82A02;
}

.khpj1 {
    img {
        margin-left: 5px;
    }

    background-image: url("../assets/yun/dialog/khpj1.png");
    height: 50px;
    width: 800px;
    line-height: 50px;
    color: #00D3F9;
    position: absolute;
    bottom:74px;
    font-size: 24px;
    color: #00d8ff;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}

.khpj2 {
    img {
        margin-left: 5px;
    }

    background-image: url("../assets/yun/dialog/khpj2.png");
    width: 850px;
    height:50px;
    line-height: 50px;
    color: #00D3F9;
    position: absolute;
    bottom:74px;
    font-size: 24px;
    color: #ff0000;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.green {
    color: #1DCE3A
}

.dandian {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;

    .item {
        text-align: center;
        background-image: url("../assets/yun/dialog/bmd.png");
        width: 735px;
        height: 405px;

        .item1 {
            margin-top: 93px;

            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #EEEFEF;

        }

        .item2 {
            margin-top: 120px;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #00D7FE;
        }
    }
}

#inquire {
    margin-top: 40px;
    width: 1527px;
    height: 60px;
    border: 1px solid #0277fa;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .inquire-title {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        line-height: 25px;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0277fa;
        border-right: 1px solid #0277fa;
    }

    .active {
        color: #fff;
        background: #0094fa;
    }
}

.inquire-store {
    margin-top: 40px;
}

.shxf {
    width: 120px;
    height: 40px;
    background: #0277fa;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
}

.jrfxcz {
    display: flex;
    width: 1545px;
    height: 704px;
    border: 1px solid #0277fa;

    .itemLeft {
        .active {
            background-color: #062753;
        }

        width: 330px;
        background-color: rgba(17, 17, 17, 0.43);

        .item {
            display: flex;
            height: 120px;
            padding-left: 20px;
        }

        .item1 {
            line-height: 120px;
            width: 215px;
            font-size: 26px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #03d7ff;
        }

        .item2 {
            line-height: 120px;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;

            span {
                color: #03d7ff;
            }
        }
    }

    .itemRight {
        width: 1215px;
        height: 704px;
        background: rgba(0, 111, 236, 0.2);
    }
}

.picContent {
    .item {
        border-bottom: 1px dashed #00d7fe;
        display: flex;
        align-items: center;
        height: 200px;

        .item1 {
            width: 250px;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #00d7fe;
            line-height: 35px;
        }

        .item2 {
            flex: 1;
            overflow: auto;
            display: flex;

            .item2-1 {
                margin-right: 30px;
            }
        }

        .item3 {
            width: 100px;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #22f906;
            line-height: 35px;
        }
    }
}

.drug-control-header-back {
    cursor: pointer;
    position: absolute;
    left: 46px;
    top: 60%;
    transform: translateY(-50%);

    span {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00d7fe;
        line-height: 26px;
        margin: 0 30px 0 10px;
    }

    img {
        width: 23px;
        height: 19px;
    }
}
</style>
<style scoped>
.level_vc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tagBtn {
    padding: 5px 20px;
    border-radius: 30px;
    text-align: center
}

.lctBlock {
    padding: 25px 35px;
}

.lctBlock .item {
    padding: 10px 20px;
    width: 260px;
    height: 161px;
    background: rgba(0, 111, 236, 0.1);
    border-radius: 4px;
}

.lctBlock .item1 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #03d7ff;
    line-height: 35px;
}

.lctBlock .item2 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #03d7ff;
    line-height: 35px;
}

.lctBlock .item3 {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 220px;
    height: 1px;
    background: #03d7ff;
}

.lctBlock .item4 {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fff;
    line-height: 25px;
}

.lctBlock .active {
    width: 260px;
    height: 161px;
    background: #bd8101;
    border: 1px solid #ffd93b;
    border-radius: 4px;
}

.lctBlock .active .item1 {
    color: #ffffff;
}

.lctBlock .active .item2 {
    color: #ffffff;
}

.red {
    color: red;
}

.jianfen {
    font-size: 30px;
    font-family: Arial;
}

.lctBlock {
    margin-top: 24px;
    width: 1545px;
    height: 441px;
    background: rgba(0, 111, 236, 0.1);
}

.lctTitle {
    display: flex;
    justify-content: space-between;
}

.lctTitle .item1 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #03d7ff;
}

.lctTitle .item2 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
}

.lctTitle1 {
    background-image: url("../assets/yun/dialog/lct4.png");
    width: 515px;
    height: 140px;
    text-align: center;
    line-height: 140px;
}

.lctTitle2 {
    background-image: url("../assets/yun/dialog/lct5.png");
    width: 1010px;
    height: 140px;
    text-align: center;
    line-height: 140px;
}

.txt-c {
    text-align: center;
}

.jkm>>>.el-tag {
    font-size: 20px;
}

.dialogBlock .active {
    border: 1px solid #fff;
    background: #ee6700 !important;
    /*  background: url("../assets/yun/dialog/1.png") !important;
  background-size: 100% 100% !important;*/
}

.dialogBlock .active .item1 {
    color: #fff !important;
}

.dialogBlock {
    display: flex;
    width: 100%;
    justify-content: left;
}

.dialogBlock .item {
    margin-right: 20px;
    width: 340px;
    padding: 10px;
    display: flex;
    height: 88px;
    line-height: 88px;
    background: #043f91;
}

.dialogBlock .item1 {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-left: 40px;
    font-size: 30px;
    color: #01c0e5;
}

.dialogBlock .item2 {
    font-size: 36px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ceec02;
}

.dialogBlock .item3 {
    font-size: 30px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ec7002;
    margin-left: 20px;
}

.listTsrq {
    justify-content: space-between;
}

.listTsrq .item {
    width: 45%;
}

.ThreeGradeTop .item2-1 {
    font-size: 36px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ec7002;
    line-height: 40px;
}

.ThreeGradeTop .item2-2 {
    line-height: 40px;
}

.ThreeGradeTop {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.ThreeGradeTop .item {
    display: flex;
}

.ThreeGradeTop .item1 {
    font-size: 26px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #01c0e5;
    line-height: 80px;
    margin-right: 10px;
}

.ThreeGradeTop .item2 {
    font-size: 36px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ceec02;
}

.singleField {
    padding-left: 20px;
}

.pici {
    padding: 0px 3px;
    height: 30px;
    margin-right: 5px;
    line-height: 30px;
    text-align: center;
    background: rgba(2, 119, 250, 0.2);
    border: 1px solid #0277fa;
    border-radius: 4px;
}

.overtwo {
    width: 200px;

    word-break: break-all;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2;
    /* 这里是超出几行省略 */

    overflow: hidden;
}

.overthree {
    width: 200px;

    word-break: break-all;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 3;
    /* 这里是超出几行省略 */

    overflow: hidden;
}

.shuzi {
    white-space: nowrap;
    color: #40a0ff;
    font-size: 30px;
    text-align: center;
}

.time {
    width: 109px;
}

.nowrap {
    white-space: nowrap;
}

/*滚动条----------------------------开始*/
.scrbar::-webkit-scrollbar {
    width: 10px;
    height: 1px;
}

.scrbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0 0 0 0.2);
    background: #13c2f8;
}

.scrbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0 0 0 0.2);
    border-radius: 10px;
    background: #010324;
}

/*滚动条----------------------------------结束*/
.tymBox {
    justify-content: flex-start;
    flex-wrap: wrap;
    color: #00b7ff;
    overflow: auto;
    height: 210px;
}

.tym {
    width: 25%;
    line-height: 200%;
    font-size: 18px;
}

.btn {
    cursor: pointer;
    border: 1px solid #00b7ff;
    border-radius: 5px;
    padding: 5px 15px;
}

.chakan {
    height: 160px;
    padding-top: 25px;
}

.listData2>td {
    color: #fff;
    font-size: 20px;
}

.viewContent tr td {
    line-height: 200%;
}

.viewContent {
    top: 90px;
    left: 30px;
    border: 1px solid #026d97;
    width: 1439px;
    padding: 0px 1px;
    position: absolute;
}

.tdBtn {
    height: 136px;
}

.tdContent {}

.pager-post {
    position: absolute;
    bottom: 80px;
    left: 50%;
}

.zgbtn1 {
    background: url("../assets/smart/home/yzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.ztbtn2 {
    background: url("../assets/smart/home/wzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.yzg {
    background: url("../assets/smart/home/yzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.wzg {
    background: url("../assets/smart/home/wzg.png");
    background-size: 100% 100%;
    width: 235px;
    height: 68px;
    line-height: 68px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.pager {
    align-items: center;
    color: #fff;
    justify-content: center;
}

.page {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: rgba(1, 4, 41, 0.8);
    border-radius: 3px;
    border: 1px solid #1ac9ff;
    color: #16a6d9;
    text-align: center;
}

.zzzg {
    justify-content: space-between;
    padding: 0px 20px;
    padding-top: 20px;
}

.zzzg .item {
    width: 150px;
    cursor: pointer;
}

.zzzg .item1 {
    font-size: 24px;
    background: url("../assets/smart/home/zzzg.png");
    background-size: 100% 100%;
    width: 150px;
    height: 56px;
    line-height: 56px;
    text-align: center;
}

.zzzg .item2 {
    color: #fff;
    text-align: center;
    margin-top: 10px;
}

.tdView {
    height: 230px;
}

.btnBack {
    position: absolute;
    right: 20px;
    /*background: url("../assets/smart/home/back.png");*/
    background-size: 100% 100%;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 10px;
}

.btnBack img {
    width: 50px;
    height: 50px;
}

.btnDetail {
    background: url("../assets/smart/home/btn_view.png");
    background-size: 100% 100%;
    width: 108px;
    height: 30px;
    line-height: 30px;
    color: #00b7ff;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}

.btnDetail2 {
    background: url("../assets/smart/home/btn_view2.png");
    background-size: 100% 100%;
    width: 108px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}

.boxcontent {
    border: 2px solid rgba(74, 144, 255, 0.5);
    position: absolute;
    width: 1536px;
    height: 175px;
    margin-top: 46px;
}

.overflow {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.huizong {
    display: flex;
    margin-bottom: 10px;
}

.flex_vc {
    display: flex;
    align-items: center;
}

.yuan {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    display: block;
    /* 宽度和高度需要相等 */
}

.huizong .btnHui {
    margin-right: 10px;
    color: #00b7ff;
    font-size: 20px;
    background-image: url("../assets/smart/home/huibtn.png");
    background-size: 100% 100%;
    width: 151px;
    height: 52px;
}

.pager {
    position: absolute;
    bottom: 30px;
    width: 100%;
}

.tag>>>.el-tag {
    font-size: 20px;
}

.dazi .listData>td {
    font-size: 20px;
}

.tanContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    margin: 0 auto;
}

.tanContent .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 229px;
    height: 194px;
    background-image: url("../assets/smart/home/dialog3.png");
    background-size: 100% 100%;
}

.tanContent .item1 {
    font-family: Arial;
    font-size: 30px;
    color: #febe13;
    line-height: 150%;
}

.tanContent .item2 {
    font-size: 16px;
    color: #fff;
    line-height: 200%;
}

.flex {
    display: flex;
}

.btnSearch {
    background-repeat: no-repeat;
    background-position: center;
    color: #01b7ff;
    cursor: pointer;
    user-select: none;
    font-weight: bolder;
    text-align: center;
    font-size: 16px;
}

.saleSearch {
    margin-bottom: 20px;
}

.saleSearch>>>.el-select__tags {
    background-color: transparent;
    flex-wrap: nowrap;
    overflow: hidden;
}

.saleSearch>>>.el-input__inner {
    background-color: transparent;
    font-size: 20px;
    width: 220px;
    color: #00b7ff;
    border: 1px solid #00b7ff;
}

.saleSearch .item {
    margin-right: 15px;
}

.saleSearch .item>>>.el-range-input {
    background: #07152d;
    color: #fff;
}

.saleSearch .item>>>.el-range-separator {
    color: #fff;
}

.saleSearch .item>>>.el-date-editor--daterange {
    width: 252px;
}

.saleSearch .label {
    padding-right: 5px;
    color: #00b7ff;
    line-height: 44px;
    font-size: 20px;
}

/*药店搜索           start*/
.storeSearch>>>.el-input__inner {
    background-color: transparent;
    font-size: 20px;
    color: #00b7ff;
    border: 1px solid #00b7ff;
}

.storeSearch .label {
    padding-right: 5px;
    color: #00b7ff;
    line-height: 44px;
    font-size: 20px;
}

/*药店搜索           end*/
.biao {
    padding-top: 10px;
}

.server {
    height: 665px;

}

.server-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: space-between;
}

.server-commont {
    box-sizing: border-box;
    width: 500px;
    height: 209px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(0, 111, 236, 0.1);
    border: 1px solid #03D7FF;
}

.server-commont-title {
    text-align: center;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #01C0E5;
    line-height: 19px;
}

.server-time {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-top: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 19px;
    margin-left: 20px
}

.server-year {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-top: 19px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 19px;
}

.jxc td {
    padding-left: 10px;
    height: 120px;
}

.hand {
    cursor: pointer;
}

.listData .name {
    line-height: 80px;
}

.dabiaozi>td {
    padding: 20px 10px;
    line-height: 150%;
}

.tx-c {
    text-align: center;
}

.td-c td {
    text-align: center;
}

.tdSale>td {
    padding-left: 7px;
    padding-right: 7px;
    line-height: 140%;
}

.listData>td {
    height: 100px;
    color: #fff;
    vertical-align: middle;
    font-size: 20px;
    padding: 0 10px;
}

.listData:nth-child(odd) {
    background-color: rgba(2, 119, 250, 0.1);
}

.listtable {
    width: 100%;
    border-collapse: collapse;
}

.listTitle>td {
    padding: 0 7px;
    white-space: nowrap;
}

.listTitle {
    color: #00d8ff;
    background: rgba(2, 119, 250, 0.1);
    height: 46px;
    font-size: 18px;
    line-height: 46px;
}

.biao2 td {
    padding: 0px 7px;
    line-height: 30px;
}

.listTitle2 td {
    padding: 0px 7px;
}

.listTitle2 {
    color: #fff;
    background: rgba(2, 119, 250, 0.5);
    line-height: 26px;
    font-size: 18px;
}

.listData {
    color: #ffffff;
    /*cursor: pointer;*/
}

.btnBack {
    position: absolute;
    right: 20px;
    background-size: 100% 100%;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 10px;
}

.btnBack2 {
    position: absolute;
    right: 20px;
    /*background: url("../assets/smart/home/dialog2.png");*/
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 10px 5px 20px;
}

.tanTitle {
    position: relative;
    /*background: url("../assets/smart/home/titbg.png");*/
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 4px;
    margin-bottom: 20px;
}

.tanTitle2 {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    color: #00b7ff;
    font-size: 26px;
    font-weight: bolder;
    letter-spacing: 7px;
    margin-bottom: 20px;
    padding-top: 3px;
}

.tanWin {
    background: url("../assets/yun/dialog/bg.png");
    background-size: 100% 100%;
    height: 835px;
    padding: 10px 30px;
    position: relative;
}

.tanWinTishi {
    background: url("../assets/yun/dialog/tishi.png");
    background-size: 100% 100%;
    height: 332px;
    padding: 10px 30px;
    position: relative;
}

.online {
    display: flex;
    flex-direction: column;
}

.online-table {
    width: 1145px;
    margin-left: 61px;
}

.pagecomont {
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 150px;
}

.online-commont {
    display: flex;
}

.chart3d-2 {
    color: #00d7fe !important;
    font-size: 24px;
    font-weight: 700;
    top: 392px;
    left: 121px;
    color: white;
    position: absolute;
}

.legend3d_1 {
    position: absolute;
    bottom: 50%;
    left: 48px;
    top: 442px;
    font-size: 22px;
    line-height: 150%;
}

.abs {
    position: absolute;
}

#chart3d_1 {
    width: 340px;
    height: 340px;
}

.tanWin2 {
    background: url("../assets/yun/dialog/bg2.png");
    background-size: 100% 100%;
    height: 332px;
    padding: 8px 30px;
}

.pageTitle {
    font-weight: bolder;
    letter-spacing: 10px;
    text-align: center;
}

/*el-dialog样式------------------------------------start*/
.dialog>>>.el-dialog {
    background-color: transparent;
}

.dialog>>>.el-dialog__header {
    display: none;
}

.dialog>>>.el-dialog__wrapper .el-dialog .el-dialog__body {
    padding: 0;
}

/*el-dialog样式------------------------------------end*/
/*el-table样式------------------------------------start*/
.biao>>>.el-table,
.biao>>>.el-table__expanded-cell {
    background-color: transparent !important;
}

.biao>>>.el-table th,
>>>.el-table tr {
    border: 0 !important;
    background-color: transparent !important;
}

/*鼠标移上去颜色*/
.biao>>>.el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: rgba(0, 85, 255, 0.29);
}

/*行分隔线*/
.biao>>>.el-table__row>td {
    color: #f5fdff;
    border-bottom: 1px solid #004f68;
}

/* 去掉最下面的那一条线 */
.biao>>>.el-table::before {
    height: 0px;
}

/*el-table样式------------------------------------end*/
</style>
<script>
import moment from "moment";
import Base64 from "../utils/base64";
import wenduImg from '../assets/yun/home/wendu.png'
import {
    saleList,
    storeList,
    control,
    messagePage,
    stockList,
    storeLevel,
    disposalList,
    pharmacistData,
    task,
    storeListPage,
    visitor,
    storePersonListYc,
    storePersonList,
    yunStore,
    juniorList,
    certList,
    pharmacistList,
    healthList,
    purchaseList,
    getStore,
    bugNIngList,
    seleList,
    storePhoto,
    licenceList, evaluate, addAuditList, addAudit, DeductionList,
} from "@/api/yaojian";
import sanda from '@/assets/data/sanda.json'
import pager from "./components/pager";
import dtpj from "@/assets/data/dtpj.json";
import ldcz from "@/assets/data/ldcz.json";
import ysfwxyl from "@/assets/data/ysfwxyl.json";
import doctor from "@/assets/data/doctor.json";
import find2 from "@/assets/data/find2.json";
import find3 from "@/assets/data/find3.json";
import find0 from "@/assets/data/find0.json";
import fengshou from "@/assets/data/fengshou.json";
import shangshan from "@/assets/data/shangshan.json";
import yizhan from "@/assets/data/yizhan.json";
import zhiyuan from "@/assets/data/zhiyuan.json";
import xiansu from "@/assets/data/xiansu.json";
import shangfengdai from "@/assets/data/shangfengdai.json";
import jifen from "@/assets/data/jifen.json";

import gqypsj from "@/assets/data/gqypsj.json";
import fytx from "@/assets/data/fytx.json";
import bhgypsj from "@/assets/data/bhgypsj.json";
import jxqtx from "@/assets/data/jxqtx.json";

// import { Base64 } from "js-base64";
import vueSeamlessScroll from "vue-seamless-scroll";
import axios from "axios";
import * as echarts from "echarts";
import 'echarts-liquidfill'//水晶图组件
import 'echarts-gl'  //立体图组件
import afxcz from "@/assets/data/afxcz.json"
import jubao from "@/assets/data/jubao.json"
import gkyp from "@/assets/data/gkyp.json"

import XsSelect from '@/components/xs-select/index.vue'

export default {
    name: "yunDialog",
    components: { pager, vueSeamlessScroll, XsSelect },
    data() {
        return {
            level: "",
            page: 1,
            total: 0,
            size: 5,
            //加分审核----start
            dialogAddAudit: false,
            AuditList: [],
            postAddAudit: {
                total: 0,
                status: "10",
                page: 1,
                count: 5
            },
            //加分审核----end
            zhmyd: { yd: 0, xt: 0, ysfw: 0 },
            dialogZhonggeng: false,
            zgTitle: "销售",
            zgNum: 0,
            sanCity: "",
            isWangxiufang: false,//是否处方列表插入第三条数据
            //网售药店--start

            //网售药店--end
            //管控药品---start
            dialogGkyp: false,
            postGkyp: {
                page: 1,
                count: 5,
            },
            GkypData: { total: 42267, length, list: gkyp },
            //管控药品---end
            YjDetail: {},
            dialogYjDetail: false,
            yizhan2: [],
            yzType: 1,
            //药店证照管理---start
            jyxkzDialog: false,
            jyxkzData: [],
            ysxkzDialog: false,
            ysxkzData: [],
            jkzDialog: false,
            jkzData: [],
            //药店证照管理---end
            fxcz1: moment()
                .add(-1, "day")
                .format("yyyy-MM-DD"),
            fxcz2: moment()
                .format("yyyy-MM-DD"),
            fxsj: moment()
                .add(-1, "day")
                .format("yyyy-MM-DD 21:57:54"),
            czsj: moment()
                .format("yyyy-MM-DD 08:52:26"),
            ldczDialog: false,
            ldczImg: [
                require('@/assets/yun/dialog/qzjb2.jpg'),
            ],
            ldczImg2: [
                require('@/assets/yun/dialog/ldcz/现场检查笔录1.jpg'),
                require('@/assets/yun/dialog/ldcz/现场检查笔录2.jpg'),
                require('@/assets/yun/dialog/ldcz/现场检查笔录3.jpg'),
                require('@/assets/yun/dialog/ldcz/现场检查笔录4.jpg'),
            ],
            ldczImg3: [
                require('@/assets/yun/dialog/ldcz/医保现场笔录.jpg')
            ],
            ldczImg4: [
                require('@/assets/yun/dialog/ldcz/医保局反馈.jpg')
            ],
            ldczImg5: [
                require('@/assets/yun/dialog/ldcz/p1.jpg'),
                require('@/assets/yun/dialog/ldcz/p2.jpg'),
            ],
            gafkImg: [
                require('@/assets/yun/dialog/ldcz/公安反馈.jpg'),
            ],
            cfjdsImg: [
                require('@/assets/yun/dialog/ldcz/康顺处罚决定书1.jpg'),
                require('@/assets/yun/dialog/ldcz/康顺处罚决定书2.jpg'),
                require('@/assets/yun/dialog/ldcz/康顺处罚决定书3.jpg'),
                require('@/assets/yun/dialog/ldcz/康顺处罚决定书4.jpg'),
                require('@/assets/yun/dialog/ldcz/康顺处罚决定书5.jpg'),
                require('@/assets/yun/dialog/ldcz/康顺处罚决定书6.jpg'),
            ],
            gayjData: [
                { yjbh: "20225145266378", yjnr: "涉嫌违规销售易制毒药品", yjsj: "2022/5/14 11:02" },
                { yjbh: "20226201511271", yjnr: "违规购买特殊药品复方制剂", yjsj: "2022/6/20 15:11" }
            ],
            gayjDialog: false,
            gayjxDialog: false,
            gaIndex: 1,
            chz: [
                require('@/assets/yun/dialog/chz.jpg'),
            ],
            gaImg1: [
                require('@/assets/yun/dialog/ga1.jpg'),
            ],
            gaImg2: [
                require('@/assets/yun/dialog/ga2.jpg'),
            ],

            cfjds: [//处罚决定书
                require('@/assets/yun/dialog/ldcz/cfjds1.jpg'),
                require('@/assets/yun/dialog/ldcz/cfjds2.jpg'),
                require('@/assets/yun/dialog/ldcz/cfjds3.jpg'),
                require('@/assets/yun/dialog/ldcz/cfjds4.jpg'),
                require('@/assets/yun/dialog/ldcz/cfjds5.jpg'),
                require('@/assets/yun/dialog/ldcz/cfjds6.jpg'),
            ],
            qzjb: 1,
            liandong: 1,
            dialogImgCommon: false,
            ImgCommon: "",
            dialogImg: false,
            //检查记录
            postJiancha: { page: 1, count: 5, status: 30 },
            dialogJiancha: false,
            JianchaData: { list: [] },
            //检查记录end

            //预警类型列表-----------------start
            dialogWarningType: false,
            yjType: 4,
            WarningType: "",
            warningTypeData: {},
            postWarningType: {
                page: 1,
                count: 5
            },
            //预警类型列表----------------------------------end
            wenduImg: wenduImg,
            end: false,
            bhgRen: [],
            postBhgRen: { page: 1, count: 6 },

            dialogDuixiang: false,
            //县级三大医院数据
            hospitalList: sanda,
            hostpitalPage: {
                page: 1,
                count: 5
            },
            showimgList: '',
            dialogshowImg: false,
            tabalList: afxcz,
            //从业人员数量
            dialogcongye: false,
            employeeList: {},
            dialoglevel: false,
            prescriptionSearchShow: true,
            //上报
            dialogShangbao: false,
            postShangbao: {},
            dataShangbao: {},

            dialogDandian: false,
            //购进记录---start
            dialogBuy: false,
            purchaseData: {},
            buyTime: moment().format("yyyy-MM-DD"),
            postBuy: {
                page: 1,
                count: 5,
            },
            //购进记录---end

            //今日风险处置----start
            fxczList: [],//风险处置列表
            postFxcz: { page: 1, count: 5 },

            fxczOkList: [],//风险处置完成列表
            postFxczOk: { page: 1, count: 5 },
            //今日风险处置----end

            //通用对话框变量------start
            dialogCommon: false,
            postCommon: { page: 1, count: 5 },
            pageTitle: "",
            arrTitle: [],
            rawList: [],//原始数据
            //通用对话框变量------end
            czzt: 1,
            dialogJrfxcz: false,
            storeId: 0,
            //证照开始
            qyzz: { licence: "", business: "" },
            licence: [], //企业照
            zyys: [], //执业药师照
            yszz: [], //药师照
            ygzz: [], //员工照
            //证照end
            dialogZhengzhao: false,
            jifen2: [],
            fabuType: 1, //1发布，2找药
            dialogFabu: false,
            FabuData: {},
            postFabu: {
                page: 1,
                count: 5,
                isPrescription: 1,
                startTime: moment().format("yyyy-MM-DD 00:00:01"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            yunStore: {},
            postYunStore: {
                pageNum: 1,
                pageSize: 5,
            },
            dialogLct: false,
            dialogShangfengdai: false,
            shangfengdai: shangfengdai,
            postShangfengdai: { page: 1, count: 5 },
            jifen: jifen,
            koufen: "",
            dialogKoufen: false,
            dialogBmpj: false,
            bmpjType: 1,
            bmpjData: [
                { a1: "医保", a2: "20", a3: "20", a4: "合格", ratio: "75.8%" },
                { a1: "卫健", a2: "2017", a3: "2017", a4: "优", ratio: "96.7%" },
                { a1: "发改", a2: "181", a3: "181", a4: "优", ratio: "98.3%" },
                { a1: "公安", a2: "1", a3: "1", a4: "良", ratio: "85.6%" },
                { a1: "政法委", a2: "1056", a3: "1056", a4: "优", ratio: "97.8%" },
                { a1: "市监", a2: "1706", a3: "1706", a4: "优", ratio: "98.3%" },
                { a1: "人社", a2: "490", a3: "490", a4: "优", ratio: "96.8%" },
                { a1: "农商行", a2: "91", a3: "91", a4: "合格", ratio: "77.5%" },
            ],
            store: {},
            today: [moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")],
            //今日访客--start
            dialogJrfks: false,
            JrfksData: {},
            postJrfks: { page: 1, count: 200, startTime: "", endTime: "" },
            //今日访客--end
            //今日访客异常--start
            FyycData: {},
            postFyyc: { page: 1, count: 5, startTime: "", endTime: "", level: "黄码" },
            //今日访客异常--end
            //从业人员核酸异常--start
            dialogCyryyc: false,
            CyryycData: {},
            postCyryyc: {
                page: 1,
                count: 5,
                endTime: "",
            },
            //从业人员核酸异常--end
            //从业人员异常--start
            dialogCyry: false,
            CyryData: {},
            postCyry: {
                page: 1,
                count: 5,
                health: "黄码",
                endTime: "",
                startTime: "",
            },
            //从业人员异常--end

            bmczCount: 0,
            storeName: "",
            postYiji: {
                page: 1,
                count: 5,
                isPrescription: 1,
                startTime: moment().format("yyyy-MM-DD 00:00:01"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            yijiData: {},
            postSanda: {
                page: 1,
                count: 5,
                isPrescription: 1,
                type: 1,
                hospital: "1",
            },
            sandaData: {},
            postHlw: {
                page: 1,
                count: 5,
                isPrescription: 1,
                type: 1,
                //hospital:"互联网医院"
            },
            hlwData: {},
            dialogLvzhi: false,
            dialogXiansu: false,
            postXiansu: {
                page: 1,
                count: 5,
            },
            xiansu: xiansu,

            dialogZhiyuan: false,
            postZhiyuan: {
                page: 1,
                count: 5,
            },
            zhiyuan: zhiyuan,

            dialogYizhan: false,
            postYizhan: {
                page: 1,
                count: 5,
            },
            yizhan: yizhan,

            postFengshou: {
                page: 1,
                count: 5,
            },
            fengshou: fengshou,
            postShangshan: {
                page: 1,
                count: 5,
            },
            shangshan: shangshan,
            askStatus: 1, //2三大医院问诊，1互联网，3，签约医生
            dialogSanji: false,
            postFind2: { page: 1, count: 5 },
            find2Data: { total: 55, list: find2 },
            postFind3: { page: 1, count: 5 },
            find3Data: { total: 9, list: find3 },
            postFind0: { page: 1, count: 5 },
            find0Data: { total: 10, list: find0 },
            sanjiZt: 2,
            dialogThreeGrade: false,
            dialogTsrq: false,
            dialogWeizd: false,
            dialogYszx: false,
            dialogAskOnlie: false,
            dialogWeicz: false,
            dialogJgqy: false,
            dialogFyyc: false,
            closeImg: require("@/assets/yun/dialog/c.png"),
            warning: "",
            yjsZt: 1,
            dialogWarning: false,
            warningData: [],
            postWarning: {
                page: 1,
                count: 5,
            },
            //群众举报---s
            dialogJubao: false,
            postJubao: {
                page: 1,
                count: 5,
            },
            JubaoData: { total: jubao.length, list: jubao },
            //签约医生---end
            //签约医生---s
            dialogDoctor: false,
            postDoctor: {
                page: 1,
                count: 5,
            },
            doctorData: { total: doctor.length, list: doctor },
            //签约医生---end
            postStore: {
                //药店
                page: 1,
                count: 5,
                sortName: "sort",
                sortOrder: "asc",
                status: "存续"
            },
            storeData: [],
            dialogStore: false,
            dialogStoreCloud: false,
            pharmacistData: [],
            postPharmacist: {
                //执业药师
                page: 1,
                count: 5,
                status: 20,
            },
            dialogPharmacist: false,
            ysfwxylData: ysfwxyl,
            postYsfwxyl: { page: 1, count: 5 },
            dialogYsfwxyl: false,
            contorl: [],
            contorl2: [],
            dialogControl2: false,
            dialogZlzg: false,
            ldczData: ldcz,
            bmczZt: 1,
            yjczBtn: 1,
            bmczCount1: 0,
            bmczCount2: 0,
            bmczData: [],
            tanType: 1,
            zgzt: 1,
            zzzgData: [],
            yzgData: [],
            wzgData: [],
            post: { page: 1, count: 5, status: 40 },
            post2: { page: 1, count: 5 },
            pageCount: 0,
            dialogVisible: false,
            dtpjDataTotal: 0,
            dialogStock: false,
            stockData: [],
            postStock: { page: 1, count: 5, storeIds: "1" },
            showSaleSearch: true,
            arrDate: [
                moment().format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("week")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("month")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("quarter")
                    .format("yyyy-MM-DD 00:00:00"),
                moment()
                    .startOf("year")
                    .format("yyyy-MM-DD 00:00:00"),
            ],
            saleData: [],
            postSale: {
                page: 1,
                count: 5,
                startTime: moment().format("yyyy-MM-DD 00:00:01"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            dialogSale: false,
            dialogJxc: false,
            postMessage: { page: 1, count: 5 },
            listData: [],
            dialogMessage: false,
            dialogDtpj: false,
            dtpj: "",
            dtpjData2: dtpj,
            dtpjData: {},
            postDtpj: {
                page: 1, count: 5,
                startTime: moment().startOf('year').format("yyyy-MM-DD 00:00:01"),
                endTime: moment().endOf('year').format("yyyy-MM-DD 23:59:59")
            },
            xyjjData: [],
            xyjjData2: [],
            postXyjj: { page: 1, count: 5 },
            dialogCzjg: false,
            czjg: "",
            tan: "satisfaction",
            dialogSatisfaction: false,
            storeIds: "",
            saleTime: moment().format("yyyy-MM-DD"),
            prescriptionTime: "",
            storeList: [],
            prescriptionType: 1, //1所有处方（药师审方），2，三大医院，3.互联网医院，4，一级（县域）
            prescriptionTitle: "处方药",
            dialogPrescription: false,
            prescriptionData: [],
            postPrescription: {
                page: 1,
                count: 5,
                isPrescription: 1,
                hospital: "1",
                startTime: moment().format("yyyy-MM-DD 00:00:01"),
                endTime: moment().format("yyyy-MM-DD 23:59:59"),
                type: 1,
            },
            dialogControl: false,
            dialogControlSay: false,
            ControlSayTitle: "",
            controlData: [],
            postControl: {
                page: 1,
                count: 5,
            },
            goodsData: [],
            dialogGoods: false,
            //一店一档
            dialogInquire: false,
            value1: [
                moment().format("yyyy-MM-DD 00:00:01"), moment().format("yyyy-MM-DD 23:59:59")
            ],
            currentIndex: 1,
            inquireList: {
                goodsName: "",
                company: "",
                approval: "",
            },
            inquirdeID: "",
            inquireName: "",
            serchList: [],
            inquirePage: {
                page: 1,
                count: 5,
                total: 0,
            },
            visitorpage: {
                page: 1,
                count: 5,
                total: 0,
                type: 70
            },
            visitorList: [],
            lctZt: 1,//1温湿度，2，处方
            lctData: {},
            isPlaying: false,
        };// data return end
    },
    computed: {
        excellent() {
            return this.bmpjData.filter(res => res.a4 == '优')


        },
        goodList() {
            return this.bmpjData.filter(res => res.a4 == '良')
        },
        qualified() {
            return this.bmpjData.filter(res => res.a4 == '合格')
        },
        //县级三大医院start
        filterHostpital() {
            const end = this.hostpitalPage.page * this.hostpitalPage.count
            const start = end - this.hostpitalPage.count
            return this.hospitalList.slice(start, end)
        },
        /*    visitor(){
                const end=this.visitorpage.page*this.visitorpage.count
                const start=end-this.visitorpage.count
                const res1=this.visitorList[0]?.list
                const res2=this.visitorList[1]?.list
                let newAarry=[]
                if(res1&&res2){
                    newAarry=  [...res1,...res2]
                }
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.visitorpage.total=newAarry.length
                return  newAarry.slice(start,end)
            },*/
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
        fengshouci() {
            let num = 0;
            this.fengshou.forEach((x) => {
                num += parseInt(x.a6);
            });
            return num;
        },
        shangshanci() {
            let num = 0;
            this.shangshan.forEach((x) => {
                num += parseInt(x.a4);
            });
            return num;
        },
        arrList() {
            let start = (this.postCommon.page - 1) * this.postCommon.count;
            let arr = this.rawList.slice(start, start + this.postCommon.count);
            return arr;
        },
        xiansuData2() {
            let start = (this.postXiansu.page - 1) * this.postXiansu.count;
            let arr = this.xiansu.slice(start, start + this.postXiansu.count);
            return arr;
        },
        bhgRen2() {
            let start = (this.postBhgRen.page - 1) * this.postBhgRen.count;
            let arr = this.bhgRen.slice(start, start + this.postBhgRen.count);
            return arr;
        },
        zhiyuanData2() {
            let start = (this.postZhiyuan.page - 1) * this.postZhiyuan.count;
            let arr = this.zhiyuan.slice(start, start + this.postZhiyuan.count);
            return arr;
        },
        yizhanCi() {
            let num = 0;
            this.yizhan.forEach((x) => {
                num += parseInt(x.a5);
            });
            return num;
        },
        ShangfengdaiData2() {
            let start = (this.postShangfengdai.page - 1) * this.postShangfengdai.count;
            let arr = this.shangfengdai.slice(start, start + this.postShangfengdai.count);
            return arr;
        },
        yizhanData2() {
            let start = (this.postYizhan.page - 1) * this.postYizhan.count;
            let arr = this.yizhan.slice(start, start + this.postYizhan.count);
            return arr;
        },
        fengshouData2() {
            let start = (this.postFengshou.page - 1) * this.postFengshou.count;
            let arr = this.fengshou.slice(start, start + this.postFengshou.count);
            return arr;
        },
        shangshanData2() {
            let start = (this.postShangshan.page - 1) * this.postShangshan.count;
            let arr = this.shangshan.slice(start, start + this.postShangshan.count);
            return arr;
        },
        JubaoData2() {
            let start = (this.postJubao.page - 1) * this.postJubao.count;
            let arr = this.JubaoData.list.slice(start, start + this.postJubao.count);
            return arr;
        },
        doctorData2() {
            let start = (this.postDoctor.page - 1) * this.postDoctor.count;
            let arr = this.doctorData.list.slice(start, start + this.postDoctor.count);
            return arr;
        },
        GkypData2() {
            let start = (this.postGkyp.page - 1) * this.postGkyp.count;
            let arr = this.GkypData.list.slice(start, start + this.postGkyp.count);
            return arr;
        },
        find2Data2() {
            let arr = []
            const city = this.sanjiZt == 4 ? '武义' : '义乌'
            // console.log('city', city)
            let start = (this.postFind2.page - 1) * this.postFind2.count;
            if (this.sanCity) {
                arr = this.find2Data.list.filter(x => x.a8 == this.sanCity).slice(start, start + this.postFind2.count);
            } else {
                arr = this.find2Data.list.slice(start, start + this.postFind2.count);
            }
            return arr;
        },
        find2Data2List() {
            let arr = []
            if (this.sanCity) {
                const city = this.sanjiZt == 4 ? '武义' : '义乌'
                arr = this.find2Data.list.filter(x => x.a8 == this.sanCity)
            } else {
                arr = this.find2Data.list
            }
            return arr;
        },
        find3Data2() {
            if (this.sanCity) {
                arr = this.find3Data.list.filter(x => x.a8 == this.sanCity)
                this.find3Data.total = this.sanCity == '宁波' ? 4 : 5
                arr = arr.slice(0, this.find3Data.total)
                return arr
            }
            this.find3Data.list = this.find3Data.list.slice(0, 9)
            let start = (this.postFind3.page - 1) * this.postFind3.count;
            let arr = this.find3Data.list.slice(start, start + this.postFind3.count);
            return arr;
        },
        find0Data2() {
            let start = (this.postFind0.page - 1) * this.postFind0.count;
            let arr = this.find0Data.list.slice(start, start + this.postFind0.count);
            return arr;
        },
        storeCloudData() {
            let data = JSON.parse(JSON.stringify(this.storeData));
            if (this.postStore.page == 10) {
                data.list = this.storeData.list.slice(0, this.postStore.count);
            }
            return data;
        },
        ysfwxylData2() {
            let start = (this.postYsfwxyl.page - 1) * this.postYsfwxyl.count;
            let arr = this.ysfwxylData.slice(start, start + 5);
            return arr;
        },
        perHui() {
            // let p=Math.floor(this.dtpjData.total*100/this.dtpjDataTotal)
            let p = (this.dtpjData.total * 100) / this.dtpjDataTotal;
            return p.toFixed(1);
        },
    },
    created() {
        // this.getPrescriptionFabu();
    },
    methods: {
        //加分审核---------start
        async AddAudit(x, t) {
            let post = {
                id: x.id,
                status: t,
            }
            let rs = await addAudit(post)
            if (rs.code == 200) {
                this.getAddAudit()
            }
        },
        async getAddAudit() {
            this.postAddAudit.storeId = this.storeId
            console.log(this.postAddAudit)
            let rs = await addAuditList(this.postAddAudit)
            this.AuditList = rs.data.list
            this.AuditList.map(x => {
                x.url = JSON.parse(x.pics)[0].response.data
            })
            this.postAddAudit.total = rs.data.total
        },
        openAddAudit(id) {
            this.storeId = id
            this.dialogAddAudit = true
            this.getAddAudit()
        },
        //加分审核---------end
        async getEvaluate() {
            let rs = await evaluate()
            if (rs.code == 200) {
                this.zhmyd.yd = rs.data.total
            }
        },
        openZhonggeng(tit) {
            this.zgTitle = tit
            this.dialogZhonggeng = true
            this.prescriptionTime = moment().format("yyyy-MM-DD")
            if (tit == "本月销售") {
                this.zgNum = 43
                this.prescriptionType = 1;
            }
            if (tit == "处方流转") {
                this.zgNum = 5
                this.prescriptionType = 2;
            }
            if (tit == "咨询执业药师") {
                this.zgNum = 15
                this.prescriptionType = 1;
            }
            this.getPrescription();

        },
        open(url) {
            // this.$router.push(url)
            window.location.href = url
        },
        isWang(o) {
            let a = this.isWangxiufang && o.patientName == '汪秀芳'
            return a
        },
        openYjDetail(o) {
            this.dialogYjDetail = true
            this.YjDetail = o
        },
        openJyxkz(store) {
            this.jyxkzDialog = true
            let post = { page: 1, count: 10, name: store.name }
            let post2 = { page: 1, count: 10, status: 20, type: 10, keyword: store.name }
            let post3 = { page: 1, count: 10, status: 20, type: 40, keyword: store.name }
            let post4 = { page: 1, count: 10, status: 20, type: 20, keyword: store.name }
            let post5 = { page: 1, count: 10, status: 20, type: 30, keyword: store.name }
            Promise.all([storePhoto(post), licenceList(post2), licenceList(post3), licenceList(post4), licenceList(post5)]).then(rs => {
                //处理营业执照-------------------------start
                this.jyxkzData = rs[0].data.list
                this.jyxkzData.map(x => {
                    if (x.attr) {
                        let attr = JSON.parse(x.attr)
                        x.validDate = attr.create_time
                        x.legalName = attr.person_name
                        x.address = attr.address
                    }
                    x.ctype = "营业执照"
                })
                //处理营业执照-------------------------end

                //药品经营许可证-------------------------start
                let jyxkz = rs[1].data.list
                jyxkz.map(x => {
                    x.ctype = "药品经营许可证"
                    x.photo = x.image
                })
                this.jyxkzData = this.jyxkzData.concat(jyxkz);
                //药品经营许可证-------------------------end
                //医疗器械备案证-------------------------start
                let ylqxba = rs[2].data.list
                ylqxba.map(x => {
                    x.ctype = "医疗器械备案证"
                    x.photo = x.image
                })
                this.jyxkzData = this.jyxkzData.concat(ylqxba);
                //医疗器械备案证-------------------------end
                //食品经营许可证-------------------------start
                let food = rs[3].data.list
                food.map(x => {
                    x.ctype = "食品经营许可证"
                    x.photo = x.image
                })
                this.jyxkzData = this.jyxkzData.concat(food);
                //食品经营许可证-------------------------end
                //食品经营许可证-------------------------start
                let qxxkz = rs[4].data.list
                qxxkz.map(x => {
                    x.ctype = "医疗器械许可证"
                    x.photo = x.image
                })
                this.jyxkzData = this.jyxkzData.concat(qxxkz);
                //食品经营许可证-------------------------end

            })
        },
        openYsxkz(store) {
            this.ysxkzDialog = true
            let post = { page: 1, count: 10, storeName: store.name }
            Promise.all([certList(post)]).then(rs => {
                this.ysxkzData = rs[0].data.list
                /*   this.ysxkzData.map(x=>{
             /*      if(x.attr){
                      let attr=JSON.parse(x.attr)
                      x.validDate=attr.create_time
                      x.legalName=attr.person_name
                      x.address=attr.address
                    }
                })*/
            })
        },
        openJkz(store) {
            this.jkzDialog = true
            let post = { page: 1, count: 10, storeId: store.id }
            Promise.all([healthList(post)]).then(rs => {
                this.jkzData = rs[0].data.list
            })
        },

        openGa(i) {
            this.gaIndex = i
            this.gayjxDialog = true
        },
        ldcz() {
            this.liandong = 1
        },
        openSan(i) {
            if (i == 0) {
                // this.openImgCommon(2)
                this.isWangxiufang = true
                this.openPrescription('药师审方', 1)
            }
        },
        openImgCommon(t) {
            this.dialogImgCommon = true
            if (t == 1) {//公安
                this.ImgCommon = require("../assets/yun/dialog/ga.png")
            }
            if (t == 2) {//
                this.ImgCommon = require("../assets/yun/dialog/sj.jpg")
            }

        },
        openImg(i) {
            if (i > 0) { return }
            this.dialogImg = true
        },
        openJubao() {
            this.dialogJubao = true
        },
        openJiancha(t) {
            /*     if(t=="自动下发"){
                   this.postJiancha.type=10
                 }
                 if(t=="检查发现"){
                   this.postJiancha.type=30
                 }*/
            this.dialogJiancha = true
            this.getJiancha()
        },
        getJiancha(data) {
            if (data) { this.postJiancha.page = data.page }
            let post = {
                ...this.postJiancha,
            }
            task(post).then(rs => {
                this.JianchaData = rs.data
            })
        },
        play() {
            var audio = document.querySelector('#audio');
            audio.play();
        },
        stop() {
            var audio = document.querySelector('#audio');
            if (this.isPlaying) {
                audio.pause();
                audio.currentTime = 0;
            }
        },
        openLct(o) {
            this.lctData = o
            if (o.remark == '没有上传处方销售处方药') {
                this.lctZt = 2
            }
            if (o.remark == '温湿度超标') {
                this.lctZt = 1
            }
            this.dialogLct = true
        },
        openWarningType(t) {
            this.dialogWarningType = true
            this.WarningType = t
            this.getWarningType()
        },
        openControlSay(t) {
            this.ControlSayTitle = t
            this.dialogControlSay = true

        },
        bhgRenFun(num) {
            let start = this.sjs(0, 30)
            let newArr = [];
            let obj = {};
            for (var i = 0; i < sanda.length; i++) {
                if (!obj[sanda[i].a2]) {
                    newArr.push(sanda[i])
                    obj[sanda[i].a2] = true
                }
            }
            num = parseInt(num)
            this.dialogDuixiang = true
            let arr = []
            for (let i = start; i < (start + num); i++) {
                arr.push(newArr[i])
            }
            this.bhgRen = arr
        },
        go(o) {
            this.$router.push(`yunStore?id=${o.storeId}&name=${o.storeName}&url=/`)
        },
        getHostpitalPage(val) {
            this.hostpitalPage.page = val.page
        },
        showImg(res) {
            this.dialogshowImg = true
            this.showimgList = require("../assets/yun/dialog/showimg.jpg")

        },
        //药店从业人员弹窗
        async openEmployee() {
            const params = {
                page: 1,
                count: 10,
                startTime: this.startTime,
                endTime: this.endTime,
                status: 20,
            };
            const { data } = await healthList(params);
            this.dialogcongye = true
            this.employeeList.total = data.total
            this.employeeList.list = data.list
        },

        intervalTime(endTime) {
            var date1 = new Date().getTime(); //开始时间
            var date2 = new Date(endTime).getTime(); //结束时间
            if (date1 - date2 > 7200000) {
                return true
            } else {
                return false
            }
        },

        openlevel(name) {
            this.dtpj = name;
            this.dialoglevel = true;
            this.getDtpjTotal();
            this.getDtpj();
        },
        getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, height) {

            // 计算
            let midRatio = (startRatio + endRatio) / 2;

            let startRadian = startRatio * Math.PI * 2;
            let endRadian = endRatio * Math.PI * 2;
            let midRadian = midRatio * Math.PI * 2;

            // 如果只有一个扇形，则不实现选中效果。
            if (startRatio === 0 && endRatio === 1) {
                isSelected = false;
            }

            // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
            k = typeof k !== 'undefined' ? k : 1 / 3;

            // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
            let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
            let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

            // 计算高亮效果的放大比例（未高亮，则比例为 1）
            let hoverRate = isHovered ? 1.05 : 1;

            // 返回曲面参数方程
            return {

                u: {
                    min: -Math.PI,
                    max: Math.PI * 3,
                    step: Math.PI / 32
                },

                v: {
                    min: 0,
                    max: Math.PI * 2,
                    step: Math.PI / 20
                },

                x: function (u, v) {
                    if (u < startRadian) {
                        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                    }
                    if (u > endRadian) {
                        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                    }
                    return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
                },

                y: function (u, v) {
                    if (u < startRadian) {
                        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                    }
                    if (u > endRadian) {
                        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                    }
                    return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
                },

                z: function (u, v) {
                    if (u < - Math.PI * 0.5) {
                        return Math.sin(u);
                    }
                    if (u > Math.PI * 2.5) {
                        return Math.sin(u);
                    }
                    return Math.sin(v) > 0 ? 1 * height : -1;
                }
            };
        },
        // 生成模拟 3D 饼图的配置项
        getPie3D(pieData, internalDiameterRatio) {

            let series = [];
            let sumValue = 0;
            let startValue = 0;
            let endValue = 0;
            let legendData = [];
            let k = typeof internalDiameterRatio !== 'undefined' ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio) : 1 / 3;

            // 为每一个饼图数据，生成一个 series-surface 配置
            for (let i = 0; i < pieData.length; i++) {

                sumValue += pieData[i].value;

                let seriesItem = {
                    name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
                    type: 'surface',
                    parametric: true,
                    wireframe: {
                        show: false
                    },
                    pieData: pieData[i],
                    pieStatus: {
                        selected: false,
                        hovered: false,
                        k: k
                    }
                };

                if (typeof pieData[i].itemStyle != 'undefined') {

                    let itemStyle = {};

                    typeof pieData[i].itemStyle.color != 'undefined' ? itemStyle.color = pieData[i].itemStyle.color : null;
                    typeof pieData[i].itemStyle.opacity != 'undefined' ? itemStyle.opacity = pieData[i].itemStyle.opacity : null;

                    seriesItem.itemStyle = itemStyle;
                }
                series.push(seriesItem);
            }

            // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
            // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
            for (let i = 0; i < series.length; i++) {
                endValue = startValue + series[i].pieData.value;
                //console.log(series[i]);
                series[i].pieData.startRatio = startValue / sumValue;
                series[i].pieData.endRatio = endValue / sumValue;
                series[i].parametricEquation = this.getParametricEquation(series[i].pieData.startRatio, series[i].pieData.endRatio, false, false, k, series[i].pieData.value);

                startValue = endValue;

                legendData.push(series[i].name);
            }



            // 准备待返回的配置项，把准备好的 legendData、series 传入。
            let option = {
                tooltip: {
                    formatter: params => {
                        if (params.seriesName !== 'mouseoutSeries') {
                            return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}%`;
                        }
                    }
                },
                // legend: {
                //   data: legendData,
                //   textStyle:{
                //     color:'#fff',
                //     fontSize:26
                //   }
                // },
                labelLine: {
                    show: true,
                    lineStyle: {
                        color: '#7BC0CB'
                    }
                },
                label: {
                    show: true,
                    position: 'outside',
                    formatter: '%',

                },
                xAxis3D: {
                    min: -1,
                    max: 1
                },
                yAxis3D: {
                    min: -1,
                    max: 1
                },
                zAxis3D: {
                    min: -1,
                    max: 1
                },
                grid3D: {
                    show: false,
                    boxHeight: 1.5,
                    top: '20%',
                    width: "100%",
                    height: "100%",
                    bottom: '60%',
                    environment: '',
                    viewControl: {
                        distance: 200,
                        alpha: 25,
                        beta: 130,
                    },

                },
                series: series
            };
            return option;
        },
        drawChart3d() {//问诊响应分析
            let myChart = echarts.init(document.getElementById('chart3d_1'))
            let arr = [5, 75, 20]
            let option = this.getPie3D([{
                name: '三大医院',
                value: arr[0],
                left: '100px',
                itemStyle: {
                    opacity: 0.5,
                    color: 'rgba(0,127,244,.9)',
                }
            }
                , {
                name: '互联网医院',
                value: arr[1],
                itemStyle: {
                    opacity: 0.5,
                    color: 'rgba(209,126,23,.9)',
                }
            }
                , {
                name: '签约医生',
                value: arr[2],
                itemStyle: {
                    opacity: 0.5,
                    color: 'rgba(20,101,0,0.9)',
                }
            }

            ], 0.5);

            myChart.setOption(option);
        },
        //访客异常切页
        getVistor(val) {
            this.visitorpage.page = val.page
        },
        //暂停/取消医保
        selectStore(id, name) {
            let url = this.$route.path
            this.$router.push(`/yunStore?id=${id}&name=${name}&new=1&url=${url}`)
        },
        //点击本地药店进入一店一档
        handleSelect(item) {
            let url = this.$route.path
            this.$router.push(`/yunStore?id=${item.id}&name=${item.name}&new=1&url=${url}`)
        },
        formatPhone(val) {
            return val.substring(0, 3) + "****" + val.substring(7);
        },
        openShangbao(data) {
            console.log(data)
            this.dataShangbao = data
            if (this.dataShangbao.pic.indexOf(']') > -1) {
                this.dataShangbao.pic = JSON.parse(this.dataShangbao.pic)
            } else {
                this.dataShangbao.pic = [this.dataShangbao.pic]
            }
            this.dialogShangbao = true
        },
        checkSelect(index) {
            this.currentIndex = index;
            this.serchList = [];
            (this.inquireList = {
                goodsName: "",
                company: "",
                approval: "",
            }),
                this.inquirePage = {
                    page: 1,
                    count: 5,
                    total: 0,
                }
            if (index == 1) {
                this.seachrCommont()
            } else if (index == 2) {
                this.buyningCommon()
            } else {
                this.saleCommont()
            }
        },
        //进销存查询一店一档
        openInquireDialog() {
            if (this.currentIndex == 1) {
                this.seachrCommont()
            } else if (this.currentIndex == 2) {
                this.buyningCommon()
            } else {
                this.saleCommont()
            }

        },
        openinquire(arg) {
            this.inquirdeID = arg.id;
            this.inquireName = arg.name;
            this.dialogInquire = true;
        },
        //搜索
        searchInquire() {
            this.seachrCommont()
        },
        seachrCommont() {
            const params = {
                storeIds: this.inquirdeID,
                page: this.inquirePage.page,
                count: this.inquirePage.count,
                goodsName: this.inquireList.goodsName,
                company: this.inquireList.company,
                approval: this.inquireList.approval,
            };
            getStore(params).then((res) => {
                this.serchList = res.data.list;
                this.inquirePage.total = res.data.total;
            });
        },
        getInquire(val) {
            this.inquirePage.page = val.page;
            this.searchInquire();
        },
        //购进
        searchbuyning() {
            this.buyningCommon()
        },
        buyningCommon() {
            const startTime = `${this.value1[0] + " 00:00:00"}`;
            const endTime = `${this.value1[1] + " 23:59:59"}`;
            const params = {
                storeIds: this.inquirdeID,
                page: this.inquirePage.page,
                count: this.inquirePage.count,
                goodsName: this.inquireList.goodsName,
                company: this.inquireList.company,
                startTime,
                endTime,
            };
            bugNIngList(params).then((res) => {
                this.serchList = res.data.list;
                this.inquirePage.total = res.data.total;
            });
        },
        getBuyning(val) {
            this.inquirePage.page = val.page;
            this.searchbuyning();
        },
        //销售
        searchSaleList() {
            this.saleCommont()
        },
        saleCommont() {
            const startTime = `${this.value1[0] + " 00:00:00"}`;
            const endTime = `${this.value1[1] + " 23:59:59"}`;
            const params = {
                storeIds: this.inquirdeID,
                page: this.inquirePage.page,
                count: this.inquirePage.count,
                goodsName: this.inquireList.goodsName,
                company: this.inquireList.company,
                startTime,
                endTime,
                type: 1,
                scenes: "dp"
            };
            seleList(params).then((res) => {
                this.serchList = res.data.list;
                this.inquirePage.total = res.data.total;
            });
        },
        salePage(val) {
            this.inquirePage.page = val.page;
            this.searchSaleList();
        },

        openTsrq() {
            this.dialogTsrq = true
            this.$nextTick(() => {
                this.drawChart_tsrq()
                let arr = [
                    { value: 507, name: '花桥' },
                    { value: 109, name: '仙华山' },
                    { value: 84, name: '民生' },
                    { value: 123, name: '新光' },
                    { value: 356, name: '蒲阳' },
                    { value: 150, name: '普丰' },
                    { value: 259, name: '杭坪' },
                    { value: 207, name: '殿口' },
                    { value: 188, name: '上河' },
                    { value: 347, name: '罗家' },
                ]
                this.drawChart_shangshan("myChart_gynl", "送药上山", arr);//购药人群年龄结构占比
                arr = [
                    { value: 5.2, name: '0～3岁' },
                    { value: 4.7, name: '4～18岁' },
                    { value: 10.1, name: '19～45岁' },
                    { value: 25.3, name: '45～60岁' },
                    { value: 54.7, name: '60岁以上' }
                ]
                this.drawChart_age("myChart_mbnl", "送药上山", arr);//慢病人群年龄结构占比
            })
        },
        drawChart_tsrq() {//特殊人群购药情况
            let option = {
                color: ['#5B8FF9', '#ffaa00',],
                legend: {
                    icon: "circle",
                    itemHeight: 15, //修改icon图形大小
                    itemWidth: 15, //修改icon图形大小
                    show: true,
                    top: '0%',
                    right: '10%',
                    textStyle: {
                        color: '#fff',
                        fontSize: 20
                    },
                    itemGap: 5,
                    formatter: (name) => {
                        return name;
                    },
                },
                tooltip: {},
                dataset: {
                    source: [
                        ['类别', '代咨询', '代找药',],
                        ['郑家坞', 34, 16],
                        ['黄宅', 0, 0],
                        ['檀溪', 59, 37],
                        ['浦阳', 0, 0],
                        ['郑宅', 21, 10],
                        ['仙华', 0, 0],
                        ['中余', 76, 61],
                        ['大畈', 269, 240],
                        ['前吴', 194, 161],
                        ['花桥', 381, 309],
                        ['白马', 108, 83],
                    ]
                },
                xAxis: {
                    axisLabel: {
                        textStyle: { color: "#fff", fontSize: 12 }
                    },
                    type: 'category',
                    axisLine: {
                        show: true,//不显示坐标轴线
                    },
                },
                yAxis: { splitLine: false },
                series: [{
                    type: 'bar',
                    //barWidth: 20,
                }, { type: 'bar' }]
            };
            let myChart = echarts.init(document.getElementById('myChart_tsrq'))
            myChart.setOption(option);
        },
        drawChart_shangshan(id, title, arr) {//购药/慢病人群年龄分布
            let option = {
                color: ['#5470c6', '#91cc75', '#fac858', '#9400ff', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#b40000'],
                title: {
                    show: false,
                    text: title,
                    // subtext: 'Fake Data',
                    left: 'center',
                    top: 'bottom',
                    textStyle: {
                        color: '#fff',
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        label: {
                            color: "#ffffff",
                            fontSize: 18,
                            formatter: (o) => {
                                return o.name + "\n" + o.value + "";
                            },
                        },
                        labelLine: {
                            length: 5
                        },
                        name: title,
                        type: 'pie',
                        radius: '55%',
                        data: arr,
                        center: ["44%", "50%"]
                    }
                ]
            };
            let myChart = echarts.init(document.getElementById(id))
            myChart.setOption(option);
        },
        drawChart_age(id, title, arr) {//购药/慢病人群年龄分布
            let option = {
                title: {
                    show: false,
                    text: title,
                    // subtext: 'Fake Data',
                    left: 'center',
                    top: 'bottom',
                    textStyle: {
                        color: '#fff',
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        label: {
                            color: "#ffffff",
                            fontSize: 18,
                            formatter: (o) => {
                                return o.name + "\n" + o.value + "%";
                            },
                        },
                        labelLine: {
                            length: 5
                        },
                        name: title,
                        type: 'pie',
                        radius: '55%',
                        data: arr,
                        center: ["48%", "50%"]
                    }
                ]
            };
            let myChart = echarts.init(document.getElementById(id))
            myChart.setOption(option);
        },
        //今日风险处置
        todayChuzhi(data) {
            if (data) { this.postFxcz.page = data.page }
            let post = {
                ...this.postFxcz,
                startTime: this.today[0],
                endTime: this.today[1]
            }
            task(post).then(rs => {
                this.fxczList = rs.data
            })
        },
        //今日风险处置完成
        todayChuzhiOk(data) {
            if (data) { this.postFxczOk.page = data.page }
            let post = {
                ...this.postFxczOk,
                startTime: this.today[0],
                endTime: this.today[1],
                status: 30
            }
            task(post).then(rs => {
                this.fxczOkList = rs.data

            })
        },
        getBhgRen(data) {
            if (data) {
                this.postBhgRen.page = data.page;
            }
        },
        //通用对话框-----------------------------start
        getCommon(data) {
            if (data) {
                this.postCommon.page = data.page;
            }
        },
        openCommon(pageTitle) {
            this.pageTitle = pageTitle
            switch (this.pageTitle) {
                case "过期药品示警":
                    this.arrTitle = [
                        { label: "序号", prop: "a1" },
                        { label: "提醒时间", prop: "a2" },
                        { label: "药品名称", prop: "a3" },
                        { label: "规格", prop: "a4" },
                        { label: "生产厂家", prop: "a5" },
                        { label: "提醒对象", prop: "a6" },
                        { label: "联系电话", prop: "a7" },
                        { label: "内容", prop: "a8" }
                    ]
                    this.rawList = gqypsj
                    break;
                case "服用提醒":
                    this.arrTitle = [
                        { label: "序号", prop: "a1" },
                        { label: "姓名", prop: "a2" },
                        { label: "手机号码", prop: "a3" },
                        { label: "提醒时间", prop: "a4" },
                        { label: "提醒标题", prop: "a5" },
                        { label: "提醒内容", prop: "a6" },
                    ]
                    this.rawList = fytx
                    break;
                case "不合格药品示警":
                    this.arrTitle = [
                        { label: "序号", prop: "a1" },
                        { label: "提醒时间", prop: "a2" },
                        { label: "药品名称", prop: "a3" },
                        { label: "规格", prop: "a4" },
                        { label: "批号", prop: "a5" },
                        { label: "内容", prop: "a8" },
                    ]
                    this.rawList = bhgypsj
                    break;
                case "药品近效期提醒":
                    this.arrTitle = [
                        { label: "序号", prop: "a1" },
                        { label: "提醒时间", prop: "a2" },
                        { label: "药品名称", prop: "a3" },
                        { label: "规格", prop: "a4" },
                        { label: "生产厂家", prop: "a5" },
                        { label: "提醒对象", prop: "a6" },
                        { label: "联系电话", prop: "a7" },
                        { label: "内容", prop: "a8" }
                    ]
                    this.rawList = jxqtx
                    break;
                default:
                    console.log("缺少pageTitle")
            }
            this.dialogCommon = true
        },
        //通用对话框-----------------------------end
        changeCzzt(t) {
            this.czzt = t;
        },
        openJrfxcz() {
            this.dialogJrfxcz = true;
            this.todayChuzhi()
            this.todayChuzhiOk()
        },
        openZhengzhao(o) {
            this.storeId = o.id;
            this.storeName = o.name;
            console.log(o);
            this.dialogZhengzhao = true;
            this.getQyzz();
        },
        async getQyzz() {
            let that = this;
            await axios
                .get("/screen/store/image?storeId=" + this.storeId, {})
                .then(function (response) {
                    that.qyzz = response.data.data;
                    if (that.qyzz.licence) {
                        that.licence = that.qyzz.licence.split(",");
                    }
                    that.licence.push(that.qyzz.business);
                });

            healthList({
                storeId: this.storeId,
                page: 1,
                count: 100,
                status: 20,
            }).then((rs) => {
                this.ygzz = rs.data.list.map((x) => x.image);
            });

            //药师证
            let post = {
                storeName: this.storeName,
                page: 1,
                count: 100,
                status: 20,
            };
            Promise.all([juniorList(post), certList(post), pharmacistList(post)]).then((res) => {
                let list1 = res[0].data.list.map((x) => x.image);
                let list2 = res[1].data.list.map((x) => x.image);
                let list3 = res[2].data.list.map((x) => x.photo);
                this.zyys = [...list1, ...list2, ...list3];
            });
        },

        getYunStore(data) {
            if (data) {
                this.postYunStore.pageNum = data.page;
            }
            yunStore(this.postYunStore).then((rs) => {
                this.yunStore = rs.data;
                console.log(this.yunStore);
            });
        },
        openStoreCloud() {
            this.dialogStoreCloud = true;
            this.getYunStore();
        },
        openBmpj(t) {
            this.dialogBmpj = true;
            this.bmpjType = t;
        },
        changePageKoufen(e) {
            this.page = e
            this.getKoufen()
        },
        async getKoufen() {
            let data = {
                page: this.page,
                count: this.size,
                storeId: this.storeId,
                zero: false
            }
            let rs = await DeductionList(data)
            if (rs.code == 200) {
                this.total = rs.data.total
                this.jifen2 = rs.data.list
                this.jifen2.map(x => {
                    x.name = this.storeName
                    return x
                })
            }
        },
        async openKoufen(storeId, storeName, level) {
            this.storeId = storeId
            this.storeName = storeName
            this.level = level
            this.getKoufen()
            this.dialogKoufen = true;
            this.koufen = name;
        },
        openYizhan(t = 1) {
            this.dialogYizhan = true;
            if (t == 1) { this.yzType = 1 }
            else {
                this.yzType = 2
                let name = t
                this.yizhan2 = this.yizhan.filter(x => x.a1 == name)
            }


        },
        recode(value) {
            return Base64.encode(value);
        },
        recodeBase(value) {
            return Base64.encode(value);
        },
        // 今日访客人数-------------start
        openJrfks(id) {
            this.storeId = id
            this.dialogJrfks = true;
            this.getJrfks();
        },
        getJrfks(data) {
            if (data) {
                this.postJrfks.page = data.page;
            }
            this.postJrfks.startTime = this.today[0];
            this.postJrfks.endTime = this.today[1];
            if (this.storeId) { this.postJrfks.storeId = this.storeId }
            visitor(this.postJrfks).then((rs) => {
                this.JrfksData = rs.data;
            });
        },
        // 今日访客人数-------------end
        // 今日访客人数异常-------------start

        openFyyc(id) {
            this.storeId = id
            this.dialogFyyc = true;
            this.getFyyc();
        },
        wcsj(t) {
            let sj = moment(t).add(34, 'minutes').add(16, 'seconds').format("yyyy-MM-DD HH:mm:ss")
            return sj
        },
        getFyyc(data = { page: 1 }) {
            this.visitorpage.page = data.page
            visitor(this.visitorpage).then((rs) => {
                this.visitorList = rs.data.list
                this.visitorpage.total = rs.data.total

            });
        },
        // 今日访客人数异常-------------end
        // 从业人员核酸异常列表-------------start
        openCyryyc(id) {
            this.storeId = id
            this.dialogCyryyc = true;
            this.getCyryyc();
        },
        getCyryyc(data) {
            if (data) {
                this.postCyryyc.page = data.page;
            }
            this.postCyryyc.endTime = moment()
                .add(-7, "day")
                .format("yyyy-MM-DD 00:00:00");
            storePersonListYc(this.postCyryyc).then((rs) => {
                this.CyryycData = rs.data;
            });
        },
        // 从业人员核酸异常列表-------------end
        // 从业人员异常列表-------------start
        openCyry() {
            this.dialogCyry = true;
            this.getCyry();
        },
        getCyry(data) {
            if (data) {
                this.postCyry.page = data.page;
            }
            this.postCyry.startTime = this.today[0];
            this.postCyry.endTime = this.today[1];
            storePersonList(this.postCyry).then((rs) => {
                this.CyryData = rs.data;
            });
        },
        // 从业人员核酸异常列表-------------end
        getXiansu(data) {
            if (data) {
                this.postXiansu.page = data.page;
            }
        },
        getZhiyuan(data) {
            if (data) {
                this.postZhiyuan.page = data.page;
            }
        },
        getYizhan(data) {
            if (data) {
                this.postYizhan.page = data.page;
            }
        },
        getShangshan(data) {
            if (data) {
                this.postShangshan.page = data.page;
            }
        },
        getFengshou(data) {
            if (data) {
                this.postFengshou.page = data.page;
            }
        },
        getFind2(data) {
            if (data) {
                this.postFind2.page = data.page;
            }
        },
        getFind3(data) {
            if (data) {
                this.postFind3.page = data.page;
            }
        },
        getFind0(data) {
            if (data) {
                this.postFind0.page = data.page;
            }
        },
        openSanji(t, city) {
            this.sanCity = city
            this.dialogSanji = true;
            this.sanjiZt = t;
            if (t == 1) {
                this.getPrescriptionYiji();
            }
        },
        mbl(t) {
            if (t) {
                if (t.length == 11) {
                    t = t.substring(0, 3) + "****" + t.substring(7);
                }
            }
            return t;
        },
        sfz(t) {
            t = t.substring(0, 6) + "********" + t.substring(14);
            return t;
        },
        openWarning(k, t, store = {}) {
            this.store = store;
            this.yjsZt = t;
            this.warning = k;
            this.dialogWarning = true;
            this.getWarning();
        },
        async getWarning(data) {
            //全预警信息
            if (data) {
                this.postWarning.page = data.page;
            }
            let start = "2022-01-01";
            if (this.yjsZt == 1) {
                start = this.arrDate[0];
            }
            if (this.yjsZt == 2) {
                start = this.arrDate[1];
            }
            if (this.yjsZt == 3) {
                start = this.arrDate[2];
            }
            if (this.yjsZt == 4) {
                start = this.arrDate[3];
            }
            if (this.yjsZt == 5) {
                start = this.arrDate[4];
            }
            if (this.warning == "all") {
                start = "2022-01-01";
            }
            start += " 00:00:00";
            let end = moment().format("yyyy-MM-DD 23:59:59");
            if (!this.postWarning.page) {
                this.postWarning.page = 1;
            }
            let post = {
                ...this.postWarning,
                start: start,
                end: end,
            };
            if (this.store) {
                post.keyword = this.store.name;
            }
            let rs = await messagePage(post);
            if (rs.code == 200) {
                this.warningData = rs.data;
                this.warningData.list.map((x) => {
                    x.typeName = x.title.split("-")[0];
                    x.content = x.content.replace(x.storeName, "").replace("，", "<br>");
                });
            }
        },
        async getWarningType(data) {
            if (data) {
                this.postWarningType.page = data.page;
            }
            if (this.WarningType == '温湿度超标') {
                this.yjType = 8
            }
            if (this.WarningType == '未上传处方') {
                this.yjType = 4
            }
            if (!this.postWarningType.page) {
                this.postWarningType.page = 1;
            }
            let start = "2022-01-01";
            start += " 00:00:00";
            let end = moment().format("yyyy-MM-DD 23:59:59");
            let post = {
                ...this.postWarningType,
                start: start,
                end: end,
                type: this.yjType
            };
            let rs = await messagePage(post);
            if (rs.code == 200) {
                this.warningTypeData = rs.data;
                this.warningTypeData.list.map((x) => {
                    x.typeName = x.title.split("-")[0];
                    x.content = x.content.replace(x.storeName, "").replace("，", "<br>");
                });
            }
        },
        getJubao(data) {
            if (data) {
                this.postJubao.page = data.page;
            }
        },
        getDoctor(data) {
            if (data) {
                this.postDoctor.page = data.page;
            }
        },
        getGkyp(data) {
            if (data) {
                this.postGkyp.page = data.page;
            }
        },
        getShangfengdai(data) {
            if (data) {
                this.postShangfengdai.page = data.page;
            }
        },
        openGoods(o) {
            this.goodsData = [];
            this.goodsData.push(o);
            console.log(this.goodsData);
            this.dialogGoods = true;
        },
        searchStore() {
            this.postStore.page = 1;
            this.getStore();
        },
        getStore(data) {
            if (data) {
                this.postStore.page = data.page;
            }
            this.postStore.name = this.storeName;
            this.postStore.type = 10;
            this.postStore.isMain = 0;
            this.postStore.smartPharmacy = 1;

            storeListPage(this.postStore).then((rs) => {
                this.storeData = rs.data;
                console.log(this.storeData, 5690)
            });
        },
        openStore(t) {
            if (t == 2) {
                this.dialogStoreCloud = true;
            } else {
                this.dialogStore = true;
            }
            this.getStore();
        },
        sjs(min, max) {
            let a = Math.floor(Math.random() * (max - min + 1)) + min;
            return a;
        },
        openPharmacistAsk() {
            //药师咨询数
            this.dialogYszx = true;
            this.getPharmacist();
        },
        openPharmacist() {
            this.dialogPharmacist = true;
            this.getPharmacist();
        },
        getPharmacist(data) {
            if (data) {
                this.postPharmacist.page = data.page;
            }
            pharmacistData(this.postPharmacist).then((rs) => {
                this.pharmacistData = rs.data;
                this.pharmacistData.total = parseInt(rs.data.total);
            });
        },
        getYsfwxyl(data) {
            if (data) {
                this.postYsfwxyl.page = data.page;
            }
        },
        openYsfwxyl() {
            this.dialogYsfwxyl = true;
        },
        openZlzg(t) {
            this.tanType = t;
            this.dialogZlzg = true;
        },
        //部门处置---------------------start
        status(id) {
            let lxm = [];
            lxm[10] = "待下发";
            lxm[20] = "待检查";
            lxm[30] = "已完成";
            return lxm[id];
        },
        zflx(id) {
            let lxm = [];
            lxm[10] = "有因检查";
            lxm[20] = "专项检查";
            lxm[30] = "飞行检查";
            return lxm[id];
        },
        search2(status = "") {
            this.liandong = 0
            this.bmczZt = status;
            this.post2.page = 1;
            this.getBmcz();
        },
        closeYjcz() {
            this.yjczBtn = 0;
        },
        getBmcz() {
            if (this.bmczZt == 0) {
                this.bmczData = [];
                this.pageCount2 = 0;
                return;
            }
            // let startTime="2022-04-29 00:00:00"
            // let endTime="2030-04-29 00:00:00"
            // this.post2.startTime=startTime
            // this.post2.endTime=endTime
            this.post2.status = 30;
            task(this.post2).then((rs) => {
                this.bmczData = rs.data;
                this.pageCount2 = Math.ceil(rs.data.total / this.post2.count);
            });
            this.getBmczCount();
        },
        getBmczCount() {
            let startTime = "2022-04-29 00:00:00";
            let endTime = "2030-04-29 00:00:00";
            // task({page:1,count:10,status:30,startTime:startTime,endTime:endTime}).then(rs=>{
            //   this.bmczCount1=rs.data.total
            // })
            // task({page:1,count:10,status:20,startTime:startTime,endTime:endTime}).then(rs=>{
            //   this.bmczCount2=rs.data.total
            // })
            task({ page: 1, count: 10, statusText: "30" }).then((rs) => {
                this.bmczCount = rs.data.total;
            });
        },
        changePage2(data) {
            this.post2.page = data.page;
            this.getBmcz();
        },
        //部门处置---------------------end
        shou(o) {
            this.ldczData.map((x) => {
                if (o.a1 == x.a1) {
                    this.$set(o, "isView", !o.isView);
                } else {
                    x.isView = false;
                }
            });
        },
        changeYjcz(t) {
            this.yjczBtn = t;
            if (t == 2) {
                this.search2(1);
            }
        },
        changePage(data) {
            this.post.page = data.page;
            this.getZzzg();
        },
        openTan(t = 1, btnZt = 1) {
            //t:1自主整改，2，部门处置 3，联动处置，0所有
            this.getBmczCount();
            if (t == 0) {
                this.yjczBtn = 1;
                this.search();
            }
            if (t == 1) {
                this.search();
            }
            if (t == 2) {
                let status = btnZt;
                this.bmczZt = status;
                // this.search2(status);
            }
            this.tanType = t;
            this.dialogVisible = true;
        },
        search(t = 1) {
            if (t == 1) {
                this.post.status = 40;
            }
            if (t == 2) {
                this.post.status = 10;
            }
            if (this.tanType == 0) {
                this.post.status = null;
            }
            this.post.page = 1;
            this.zgzt = t;
            this.getZzzg();
        },
        getZzzg() {
            this.post.status = 40;
            disposalList(this.post).then((rs) => {
                let data = rs.data;
                data.list.map((x) => {
                    if (x.repayPic) {
                        if (x.repayPic.indexOf("]") != -1) {
                            x.repayPic = JSON.parse(x.repayPic);
                        } else {
                            x.repayPic = [x.repayPic];
                        }
                    }
                    if (x.completeName) {
                        x.completeName = x.completeName.split(":")[1];
                    }
                });
                this.zzzgData = data;
                this.pageCount = Math.ceil(this.zzzgData.total / this.post.count);
            });
        },
        getYzg() {
            let post = { page: 1, count: 10, status: 40 };
            disposalList(post).then((rs) => {
                this.yzgData = rs.data;
            });
        },
        getWzg() {
            let post = { page: 1, count: 10, status: 10 };
            disposalList(post).then((rs) => {
                this.wzgData = rs.data;
            });
        },
        openStock() {
            this.dialogStock = true;
            this.getStoreList();
            this.getStock();
        },
        //今日购进---------------start
        openBuy() {
            this.dialogBuy = true;
            this.getStoreList();
            this.searchBuy();
        },
        searchBuy() {
            if (!this.buyTime) {
                this.buyTime = moment().format("YYYY-MM-DD");
            }
            this.postBuy.startTime = this.buyTime + " 00:00:00";
            this.postBuy.endTime = this.buyTime + " 23:59:59";
            this.postBuy.page = 1;
            this.getBuy();
        },
        getBuy(data) {
            if (data) {
                this.postBuy.page = data.page;
            }
            purchaseList(this.postBuy).then(rs => {
                this.purchaseData = rs.data;
            });
        },
        //今日购进---------------end
        openSale() {
            this.dialogSale = true;
            this.getStoreList();
            this.getSale();
        },
        searchStock() {
            this.postStock.page = 1;
            this.getStock();
        },
        getStock(data) {
            if (data) {
                this.postStock.page = data.page;
            }
            stockList(this.postStock).then((rs) => {
                this.stockData = rs.data;
            });
        },
        searchSale() {
            if (!this.saleTime) {
                this.saleTime = moment().format("YYYY-MM-DD");
            }
            this.postSale.startTime = this.saleTime + " 00:00:00";
            this.postSale.endTime = this.saleTime + " 23:59:59";
            this.postSale.page = 1;
            this.getSale();
        },
        getSale(data) {
            if (data) {
                this.postSale.page = data.page;
            }
            saleList(this.postSale).then((rs) => {
                this.saleData = rs.data;
            });
        },
        async getMessagePage(data) {
            //预警信息
            if (data) {
                this.postMessage.page = data.page;
            }
            let rs = await messagePage(this.postMessage);
            if (rs.code == 200) {
                this.listData = rs.data;
                this.listData.list.map((x) => {
                    x.typeName = x.title.split("-")[0];
                });
            }
        },
        openMessage() {
            //今日预警
            this.getMessagePage();
            this.dialogMessage = true;
        },
        openJxc() {
            this.dialogJxc = true;
        },
        getDtpj(data) {
            if (data) {
                this.postDtpj.page = data.page;
            }
            this.postDtpj.level = this.dtpj.replace("级", "");
            storeLevel(this.postDtpj).then((rs) => {
                this.dtpjData = rs.data;
            });
        },
        getDtpjTotal() {
            let data = {
                page: 1, count: 1,
                startTime: moment().startOf("year").format("yyyy-MM-DD 00:00:01"),
                endTime: moment().endOf("year").format("yyyy-MM-DD 23:59:59"),
            }
            storeLevel(data).then((rs) => {
                this.dtpjDataTotal = rs.data.total;
            });
        },
        openDtpj(name) {
            this.dtpj = name;
            this.dialogDtpj = true;
            this.getDtpjTotal();
            this.getDtpj();
        },
        getXyjj(data) {
            if (data) {
                this.postXyjj.page = data.page;
            }
            let start = (this.postXyjj.page - 1) * this.postXyjj.count;
            this.xyjjData = this.xyjjData2.slice(start, start + this.postXyjj.count);
        },
        openCzjg(name) {
            this.getStoreList();
            this.czjg = name;
            this.dialogCzjg = true;
        },
        formatTime(t) {
            let s = t.split(" ")[0];
            return s;
        },
        openSatisfaction(t) {
            this.tan = t;
            this.dialogSatisfaction = true;
            this.getEvaluate()
        },
        getStoreList() {
            storeList().then((rs) => {
                this.storeList = rs.data;

                //信用降级数据
                this.xyjjData2 = rs.data;
                this.xyjjData2.sort(function () {
                    return Math.random() - 0.5;
                });
                this.xyjjData2 = this.xyjjData2.slice(0, 58);
                this.getXyjj();
                //信用降级数据
            });
        },
        closed() {
            //关闭初始化
            this.isWangxiufang = false
            this.prescriptionData = {};
            this.postDtpj.page = 1;
            this.postWarningType.page = 1;
            this.postBhgRen.page = 1;
            this.postPrescription.page = 1;
            this.postXyjj.page = 1;
            this.inquirePage.page = 1
            this.postCommon.page = 1
            this.postGkyp.page = 1

        },
        openControl2(o) {
            this.dialogControl2 = true;
            this.contorl = o.names ? JSON.parse(o.names) : [];
            this.contorl2 = o.ids ? JSON.parse(o.ids) : [];
        },
        openControl() {
            this.dialogControl = true;
            this.getControl();
        },
        getControl(data) {
            if (data) {
                this.postControl.page = data.page;
            }
            control(this.postControl).then((rs) => {
                this.controlData = rs.data;
            });
        },
        openPrescription(tit, type, showSearch = true, id) {
            this.storeId = id
            this.prescriptionSearchShow = showSearch
            this.prescriptionType = type;
            this.prescriptionTitle = tit;
            if (tit == "药师审方" || tit == "本月销售服务") {
                this.prescriptionTime = moment().format("yyyy-MM-DD")
            } else {
                this.prescriptionTime = ""
            }
            this.dialogPrescription = true;
            this.getStoreList();
            this.getPrescription();
        },
        openAskOnlie() {
            this.dialogAskOnlie = true;
            this.prescriptionType = 2;
            this.getPrescription();
            this.$nextTick(() => {
                this.drawChart3d()
            })
        },
        changeAsk(t) {
            this.askStatus = t;
        },
        searchPrescription(type = 1) {
            this.changeAsk(type);
            this.prescriptionType = type;
            this.postPrescription.page = 1;
            this.getPrescription();
        },
        getPrescription(data) {
            if (data) {
                this.postPrescription.page = data.page;
            }
            if (this.storeId) {
                this.postPrescription.storeIds = this.storeId
                this.prescriptionTime = moment().format("yyyy-MM-DD")
            }
            if (this.prescriptionTime) {
                this.postPrescription.startTime = this.prescriptionTime + " 00:00:00";
                this.postPrescription.endTime = this.prescriptionTime + " 23:59:59";
            } else {
                this.postPrescription.startTime = moment()
                    .add(-7, "day")
                    .format("yyyy-MM-DD 00:00:00");
                this.postPrescription.endTime = moment().format("yyyy-MM-DD 23:59:59");
            }
            if (this.prescriptionType == 1) {
                this.postPrescription.hospital = "";
            }
            if (this.prescriptionType == 2) {
                this.postPrescription.hospital = "1";
            }

            saleList(this.postPrescription).then((rs) => {
                this.prescriptionData = rs.data;
                if (this.isWangxiufang && this.postPrescription.page == 1) {
                    let obj = {
                        "id": "1572015219042918402",
                        "prescriptionOrderSn": null,
                        "preOrderSn": null,
                        "recipeid": null,
                        "orderId": "1572015219009363970",
                        "storeId": "1377334900729843713",
                        "storeName": "浦江县平安大药房有限公司",
                        "goodsId": "1434371236299735041",
                        "commonName": "乳酸左氧氟沙星滴眼液",
                        "type": 1,
                        "spec": "5ml:15mg",
                        "unit": "支",
                        "company": "杭州国光药业股份有限公司",
                        "approval": "国药准字H20030604",
                        "goodsName": "乳酸左氧氟沙星滴眼液(佐凯)",
                        "isPrescription": 1,
                        "isControl": 0,
                        "lot": "220602",
                        "validDate": "2024-05-31",
                        "retailPrice": null,
                        "price": 10,
                        "discount": 1,
                        "quantity": 1,
                        "cheapMoney": null,
                        "patientName": "汪秀芳",
                        "patientSex": 2,
                        "patientAge": 34,
                        "patientIdcard": "330726198804153323",
                        "patientPhone": "13002658928",
                        "hospital": "温州市瓯海区第三人民医院众康互联网医院",
                        "doctor": "陈建胜",
                        "pharmacist": "邵炳章",
                        "patientAddress": "巧溪",
                        "prescriptionImage": "http://pic.yaojian.xusos.com/yaojian/file/2022-09-21/2022_9_21_1216244322.png",
                        "deleted": null,
                        "updateDate": "2022-09-20 08:10:10",
                        "createDate": "2022-09-20 08:10:10",
                        "storeNames": "浦江县平安大药房有限公司",
                        "image": null,
                        "orderSn": "XS2022092008100003",
                        "scenePhoto": "http://pic.prescription.xusos.com/uploads/pharmacist/20220706/20220706091110_62c4e12e2ae14.jpg",
                        "diagnosis": "沙眼"
                    }
                    this.prescriptionData.list.splice(0, 1);
                    this.prescriptionData.list.splice(2, 0, obj);
                }

            });
        },
        getPrescriptionYiji(data) {
            if (data) {
                this.postYiji.page = data.page;
            }
            this.postYiji.startTime = "2024-09-11 01:00:00"
            this.postYiji.endTime = "2024-09-11 16:50:00"
            saleList(this.postYiji).then((rs) => {
                this.yijiData = rs.data;
                this.yijiData.total = 3629 // rs.data.total;
            });
        },
        getPrescriptionSanda(data) {
            if (data) {
                this.postYiji.page = data.page;
            }
            this.postYiji.startTime = moment()
                .add(-4, "day")
                .format("yyyy-MM-DD 00:00:00");
            this.postYiji.endTime = moment().format("yyyy-MM-DD 23:59:59");
            saleList(this.postYiji).then((rs) => {
                this.yijiData = rs.data;
                this.yijiData.total = 2453;
            });
        },
        openFabu(t = 1) {
            this.fabuType = t;
            this.dialogFabu = true;
            this.getPrescriptionFabu();
        },
        getPrescriptionFabu(data) {
            if (data) {
                this.postFabu.page = data.page;
            }
            saleList(this.postFabu).then((rs) => {
                this.FabuData = rs.data;
                this.FabuData.total = this.fabuType == 1 ? 65 : 65;
            });
        },
    },
};
</script>

<style scoped></style>
